import React, { useState } from 'react'
import { Box, Button, Menu, MenuItem, Typography   } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useStyles } from '../../UI/styles/themes';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import AuthState from '../../authStates';
import { useUserStore } from '../../assets/state';
import { ReactComponent as HomeIcon } from '../../assets/svg/homeIcon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/svg/profileIcon.svg';
import { ReactComponent as LogOutIcon } from '../../assets/svg/logOutIcon.svg';
import { ReactComponent as PadlockIcon } from '../../assets/svg/padlock.svg';
import logo from '../../assets/svg/SFIndexLogo.svg';

const TopNavbarAccount = () => {
    // allow use of customer styles/classes from themes.jsx
    const classes = useStyles();
    const setAuthState = useUserStore((state) => state.setAuthState);
    const setUserBrand = useUserStore((state) => state.setUserBrand);
    const setUser = useUserStore((state) => state.setUser);
    const user = useUserStore((state) => state.user);
    const [anchorEl, setAnchorEl] = useState(null);
    const location = useLocation();
    const pathName = location.pathname;
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // sign out auth function for sign out button
    async function signOut() {
        try {
            await Auth.signOut();
            setUser(null);
            setUserBrand(null);
            setAuthState(AuthState.SignedOut);
            history.push('/');
        } catch (error) {
            console.error("error signing out: ", error);
        }
    }

    return (
        <Box height="72px" bgcolor="#FFFFFF " display='flex' flexDirection='row' justifyContent="space-between" alignItems="center" px="30px" borderBottom="1px solid #E2E5ED">
            <Box display='flex' flexDirection='row' alignItems='center' height={1}>
                <Box mr="20px" display='flex' alignItems='center'>
                    <a href="/" rel="noreferrer">
                        <img src={logo} alt='SilkFred Sustainability Insight Index' height='25px' />
                    </a>
                </Box>
                <Button
                    className={classes.buttonTopNav}
                    component={NavLink}
                    to="/home"
                    disableRipple='false'
                >
                    <HomeIcon fill={pathName==='/home' ? '#5155F8' : '#838791'} />
          Home
                </Button>
            </Box>

            <Button
                className={classes.profileButton}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleClick}
                disableRipple='false'
            >
                <ProfileIcon fill="#BEC0C5"/>
                <Typography
                    className={classes.H200}
                    style={{marginLeft: '9px', color: '#202532'}}
                >
                    {user ? user.name : 'YOUR ACCOUNT'}
                </Typography>
                <ArrowDropDownIcon className={anchorEl && classes.up} />
            </Button>
            <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.profileMenuList}
            >
                <MenuItem disabled='true' style={{
                    width: '174px',
                    opacity: "1"
                }}>
                    <Box py="4px" width={1}>
                        <Typography className={classes.H100}>YOUR ACCOUNT</Typography>
                    </Box>
                </MenuItem>
                <MenuItem
                    className={classes.profileButtonMenuItem}
                    disableRipple='false'
                    onClick={() => history.push('/resetpassword')}>
                    <PadlockIcon />
                    <Typography style={{marginLeft: '13px', padding: '2px 0px'}} className={classes.bodyLarge}>Reset password</Typography>
                </MenuItem>
                <MenuItem
                    className={classes.profileButtonMenuItem}
                    disableRipple='false'
                    onClick={signOut}>
                    <LogOutIcon />
                    <Typography style={{marginLeft: '10px', padding: '2px 0px'}} className={classes.bodyLarge}>Logout</Typography>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default TopNavbarAccount