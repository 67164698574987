import React from 'react'
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import { Box } from '@material-ui/core';
import SurveyPage from '../../../../UI/templates/SurveyPage';
import { assessmentsJSON } from '../../../../assets/surveyJSON/people/assessments';
import { communicationJSON } from '../../../../assets/surveyJSON/people/communication';
import { communityJSON } from '../../../../assets/surveyJSON/people/community';
import { companyJSON } from '../../../../assets/surveyJSON/people/company';
import { procJSON } from '../../../../assets/surveyJSON/people/processes';

const PeopleSection = () => {
    const { path } = useRouteMatch();
    return (
        <Box width={1} height={1}>
            <Switch>
                <Route path={`${path}/processes`}>
                    <SurveyPage
                        section="People"
                        subsection="Processes"
                        description={procJSON.pages[0].descriptionEdit}
                        jsonFile={procJSON}
                        nextSection={`${path}/assessments`}
                    />
                </Route>

                <Route path={`${path}/assessments`}>
                    <SurveyPage
                        section="People"
                        subsection="Assessments"
                        description={assessmentsJSON.pages[0].descriptionEdit}
                        jsonFile={assessmentsJSON}
                        nextSection={`${path}/community`}
                    />
                </Route>

                <Route path={`${path}/community`}>
                    <SurveyPage
                        section="People"
                        subsection="Community"
                        description={communityJSON.pages[0].descriptionEdit}
                        jsonFile={communityJSON}
                        nextSection={`${path}/company`}
                    />
                </Route>

                <Route path={`${path}/company`}>
                    <SurveyPage
                        section="People"
                        subsection="Company"
                        description={companyJSON.pages[0].descriptionEdit}
                        jsonFile={companyJSON}
                        nextSection={`${path}/communication`}
                    />
                </Route>

                <Route path={`${path}/communication`}>
                    <SurveyPage
                        section="People"
                        subsection="Communication"
                        description={communicationJSON.pages[0].descriptionEdit}
                        jsonFile={communicationJSON}
                        nextSection={`/survey/sourcing/transparency&traceability`}
                    />
                </Route>

            </Switch>
        </Box>
    )
}

export default PeopleSection
