export const jewelleryJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "Sourcing_Jewellery_Sell_1",
                    title: "Do you sell Jewellery?",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Sourcing_Jewellery_Track_2",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1",
                    title: "What percentage of your jewellery supply chain are you able to track? ",
                    description: "This question seeks to understand how much visibility you have into your jewellery supply chain. If you know your direct suppliers of your jewellery products. Please enter the number as a percentage from 0 - 100. If you are not sure please enter 0.",
                    isRequired: true,
                    inputType: "number",
                    min: "0",
                    max: "100"
                },
                {
                    type: "file",
                    name: "Sourcing_Jewellery_Track_file",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Track_2} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Sourcing_Jewellery_Track_desc",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Track_2} notempty",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Sourcing_Jewellery_Track_file} empty"
                },
                {
                    type: "text",
                    name: "Sourcing_Jewellery_Assessments_3",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1",
                    title: "What percentage of your jewellery supply chain have had ethical trade assessments conducted? ",
                    description: "This could include any type of assessment or audit you undertake or outsource to a third party relating to the working conditions of the people who make the jewellery you sell. Please enter the number as a percentage from 0 - 100. If you are not sure please enter 0.",
                    isRequired: true,
                    inputType: "number",
                    min: "0",
                    max: "100"
                },
                {
                    type: "file",
                    name: "Sourcing_Jewellery_Assessments_file",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Assessments_3} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Sourcing_Jewellery_Assessments_desc",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Assessments_3} notempty",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Sourcing_Jewellery_Assessments_file} empty"
                },
                {
                    type: "text",
                    name: "Sourcing_Jewellery_Chemicals_4",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1",
                    title: "How do you ensure your jewellery is compliant with chemical regulations in the jurisdictions in which you sell? ",
                    description: "This refers to chemical regulations for the jurisdictions in which your company sells its jewellery products. Please describe your process in the box below.",
                    isRequired: true
                },
                {
                    type: "file",
                    name: "Sourcing_Jewellery_Chemicals_file",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Chemicals_4} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Sourcing_Jewellery_Chemicals_desc",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Chemicals_4} notempty",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Sourcing_Jewellery_Chemicals_file} empty"
                },
                {
                    type: "text",
                    name: "Sourcing_Jewellery_Sourcing_5",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1",
                    title: "How do you ensure the responsible sourcing of the materials in your jewellery? ",
                    description: "This refers to any sourcing practices you may have for purchasing the materials that comprise your jewellery products - for example base metals and alloys. If you purchase the jewellery you sell through a model such as wholesale please state this. Please describe your process in the box below.",
                    isRequired: true
                },
                {
                    type: "file",
                    name: "Sourcing_Jewellery_Sourcing_file",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Sourcing_5} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Sourcing_Jewellery_Sourcing_desc",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Sourcing_5} notempty",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Sourcing_Jewellery_Sourcing_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "Sourcing_Jewellery_Memberships_6",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1",
                    title: "Are you or any of your suppliers members of an independently accredited sustainable jewellery organisation, initiative or body?",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        },
                        {
                            value: "3",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Sourcing_Jewellery_Memberships_file",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Memberships_6} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Sourcing_Jewellery_Memberships_desc",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Memberships_6} = 1",
                    indent: 3,
                    title: "Please enter the organisation below.",
                    hideNumber: true,
                    requiredIf: "{Sourcing_Jewellery_Sell_1} = 1 and {Sourcing_Jewellery_Memberships_6} = 1 and {Sourcing_Jewellery_Memberships_file} empty"
                },
                {
                    type: "comment",
                    name: "Sourcing_Jewellery_Other",
                    visibleIf: "{Sourcing_Jewellery_Sell_1} = 1",
                    title: " Is there anything else that you would like to share about Jewellery?",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on any policies and practices you have if you sell jewellery and how much you know about those supply chains."
        }
    ],
    showCompletedPage: false
}