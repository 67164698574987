import React from 'react'
import { Button } from '@material-ui/core';
import { CSVLink } from "react-csv";
import PropTypes from 'prop-types';

const TableCSVExport = (props) => {
    const { headers = [], data = []} = props;

    const CSVHeaders = headers.map((header) => {
        let key;
        header === "Brand" ? key = "brandname" :
            header === "Overall score" ? key = "total" :
                header === "People score" ? key = "people" :
                    header === "Planet score" ? key = "planet" :
                        header === "Sourcing score" ? key = "sourcing" : key = "lastCompleted";

        return {
            label: header,
            key: key
        }
    });
    const CSVData = data.map((brand) => {
        return {
            brandname: brand.name,
            total: brand.overallScore,
            people: brand.surveyPeopleScore,
            planet: brand.surveyPlanetScore,
            sourcing: brand.surveySourcingScore,
            lastCompleted: brand.surveyLastCompleted ? new Date(brand.surveyLastCompleted).toLocaleDateString() : ""
        }
    })

    return (
        <CSVLink data={CSVData} headers={CSVHeaders} filename="insight-index-brand-scores.csv" style={{textDecoration: "none"}}>
            <Button
                style={{
                    background: "#F6F2FC",
                    borderRadius: "4px",
                    padding: "8px 36px",
                    height: "38px",
                    fontWeight: "600",
                    fontSize: "14px",
                    textAlign: "center",
                    color: "#706CEC",
                    textTransform: "none",
                    margin: "0"
                }}
                disableRipple='false'
            >
        Download CSV
            </Button>
        </CSVLink>
    )
}

TableCSVExport.propTypes = {
    headers: PropTypes.array,
    data: PropTypes.array
}

export default TableCSVExport