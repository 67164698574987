import React from 'react'
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const ResourcesReferences = ({references}) => {
    return (
        <Box p="30px 15%">
            <Typography style={{
                fontFamily: 'syne',
                color: "#5556E9",
                fontSize: "18px",
                fontWeight: "500"
            }}>References</Typography>
            <ol style={{
                margin: "20px 0px",
                paddingLeft: "10px",
                fontSize: "12px"
            }}>
                {
                    references.map((ref, idx) => {
                        return (
                            <li key={idx} style={{margin: "8px 0"}}>
                                {ref}
                            </li>
                        )
                    })
                }
            </ol>
        </Box>
    )
}

ResourcesReferences.propTypes = {
    references: PropTypes.array
}

export default ResourcesReferences