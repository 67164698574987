export const assessmentsJSON = {
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "People_Assessments_Assess_1",
                    "title": " Do you assess your direct suppliers on social and labour standards? ",
                    "description": "This question is asking if you have any formal or informal methods of assessing your direct suppliers on social and labour standards. If your company has an Ethical Trade Code of Conduct, this will likely include the social and labour standards against which you assess your suppliers.  These assessments could be self-assessments by the supplier, assessments carried out by your company, or assessments conducted by a third party (such as an audit company). If you have another assessment method, please explain it in the text box.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "We have carried out an informal self-assessment"
                        },
                        {
                            "value": "2",
                            "text": "We have received previously conducted audits"
                        },
                        {
                            "value": "3",
                            "text": "We have initiated audits ourselves"
                        },
                        {
                            "value": "5",
                            "text": "No"
                        }
                    ],
                    "showOtherItem": true,
                    "otherText": "Other - please describe"
                },
                {
                    "type": "text",
                    "name": "People_Assessments_Percentassess_1.1",
                    "visibleIf": "{People_Assessments_Assess_1} anyof [1, 2, 3]",
                    "indent": 3,
                    "title": "1.1 What percentage of your direct suppliers have had social and labour assessments? ",
                    "description": "Please enter the number as a percentage from 0 - 100.",
                    "hideNumber": true,
                    "isRequired": true,
                    "inputType": "number",
                    "min": "0",
                    "max": "100"
                },
                {
                    "type": "text",
                    "name": "People_Assessments_Whenassess_1.2",
                    "visibleIf": "{People_Assessments_Assess_1} anyof [1, 2, 3]",
                    "indent": 3,
                    "title": "1.2 What percentage of suppliers have had an assessment in the last 2 years?",
                    "description": "Please enter the number as a percentage from 0 - 100.",
                    "hideNumber": true,
                    "isRequired": true,
                    "inputType": "number",
                    "min": "0",
                    "max": "100"
                },
                {
                    "type": "radiogroup",
                    "name": "People_Assessments_Methodology_1.3",
                    "visibleIf": "{People_Assessments_Assess_1} anyof [3, 2, 1]",
                    "indent": 3,
                    "title": "1.3 Which ethical trade assessment methodology do you use?  ",
                    "description": "There are various methodologies used for assessing ethical trade. If you are using one of the following methodologies, even in an unofficial way, please select it from below or explain your assessment methodology by selecting \"Other\".",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Amfori Business Social Compliance Initiative (BSCI)"
                        },
                        {
                            "value": "2",
                            "text": "Ethical Trading Initiative (ETI) Base Code"
                        },
                        {
                            "value": "3",
                            "text": "Fair Wear Foundation (FWF) Code of Labour Practices"
                        },
                        {
                            "value": "4",
                            "text": "International Labour Organisation (ILO)"
                        },
                        {
                            "value": "5",
                            "text": "Sedex Members Ethical Trade Audit (SMETA)"
                        },
                        {
                            "value": "6",
                            "text": "Social Accountability International Certification (SA 8000)"
                        },
                        {
                            "value": "8",
                            "text": "I don’t know"
                        }
                    ],
                    "showOtherItem": true,
                    "otherText": "Other"
                },
                {
                    "type": "radiogroup",
                    "name": "People_Assessments_ApproveSCHW_2",
                    "title": "Have you approved your direct suppliers to use sub-contractors and/or homeworkers? ",
                    "description": "This question seeks to understand whether you have given consent to your direct, tier one suppliers to employ sub-contractors or homeworkers in the completion of your orders.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes - homeworkers"
                        },
                        {
                            "value": "2",
                            "text": "Yes - subcontractors"
                        },
                        {
                            "value": "3",
                            "text": "Yes - homeworkers and subcontractors"
                        },
                        {
                            "value": "4",
                            "text": "No"
                        },
                        {
                            "value": "5",
                            "text": "I don't know"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "People_Assessments_AssessSCHW_2.1",
                    "visibleIf": "{People_Assessments_ApproveSCHW_2} anyof [1, 2, 3]",
                    "indent": 3,
                    "title": "2.1 What type of assessment has been carried out of the conditions of these workers? ",
                    "description": "Please enter your process in the box below.",
                    "hideNumber": true,
                    "isRequired": true
                },
                {
                    "type": "text",
                    "name": "People_Assessments_Corrective Actions_3",
                    "title": "What type of corrective actions have you taken for any non-compliances?",
                    "description": "This question is asking the actions you have taken if your assessments have made you aware of any ethical trade or labour rights non-compliances in the factories you source from. Please enter your process and the actions taken in the box below. If you are not sure, please write none.",
                    "isRequired": true
                },
                {
                    "type": "checkbox",
                    "name": "People_Assessments_Supplychain_4",
                    "title": "Beyond your direct suppliers, have you carried out audits or assessments of worker conditions anywhere else in your supply chain? ",
                    "description": "This question is asking whether you know anything about worker conditions in your supply chain beyond your direct (tier one) suppliers. These suppliers could include: (i) fabric development - for example, embroidering or printing; (ii) fibre and material processing - for example, ginning, spinning, knitting, weaving, dyeing, wet processing or tanning; and (iii) raw material production - for example, a cotton farm or a polyester manufacturer.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "We have carried out an informal self-assessment"
                        },
                        {
                            "value": "2",
                            "text": "We have received previously conducted audits"
                        },
                        {
                            "value": "3",
                            "text": "We have initiated audits ourselves"
                        },
                        {
                            "value": "4",
                            "text": "Other"
                        },
                        {
                            "value": "5",
                            "text": "No"
                        }
                    ],
                    "otherText": "No"
                },
                {
                    "type": "file",
                    "name": "People_Assessments_Supplychain_file",
                    "visibleIf": "{People_Assessments_Supplychain_4} anyof [1, 2, 3, 4]",
                    "indent": 3,
                    "title": "Please upload supporting evidence",
                    "description": "Examples of evidence include completed audit reports for each supplier.",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "People_Assessments_Supplychain_desc",
                    "visibleIf": "{People_Assessments_Supplychain_4} anyof [1, 2, 3, 4]",
                    "indent": 3,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{People_Assessments_Supplychain_file} empty"
                },
                {
                    "type": "comment",
                    "name": "People_Assessments_Other",
                    "title": " Is there anything else that you would like to share about People in your supply chain: Assessments?",
                    "hideNumber": true
                }
            ],
            "descriptionEdit": "This section focuses on how much you know about your suppliers and asks whether you have ever visited or undertaken any assessments or audits about worker conditions."
        }
    ],
    "showCompletedPage": false
}
