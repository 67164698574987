import React from 'react'
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

// width & height must be a number
const ProgressBar = (props) => {
    const { average, current, height, width, averageName, currentName } = props;

    return (
        <Box
            display="flex"
            flexDirection="column"
        >
            <Box
                width={`${width}px`}
                height={`${height}px`}
                bgcolor="#F4F4FB"
                borderRadius={`${height/2}px`}
                overflow="hidden"
            >
                {/* average brand */}
                <Box
                    position="absolute"
                    width={`${(average/100)*width}px`}
                    height={`${height}px`}
                    bgcolor="#B5AAEB"
                    borderRadius={`${height/2}px 0px 0px ${height/2}px`}
                    zIndex={average >= current ? "1" : "5"}
                ></Box>

                {/* current brand */}
                <Box
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    width={`${(current/100)*width}px`}
                    height={`${height}px`}
                    bgcolor="#7B61FF"
                    borderRadius={`${height/2}px 0px 0px ${height/2}px`}
                    zIndex={average >= current ? "5" : "1"}
                >
                    <div style={{
                        height: "150%",
                        borderRight: "2px solid black"
                    }}></div>
                </Box>
            </Box>

            {/* key */}
            <Box
                width="100%"
                display="flex"
                flexDirection="row"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    mr="20px"
                >
                    <div style={{
                        background: "#B5AAEB",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "10px"
                    }} />
                    <p style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#6B6C6F",
                    }}>{averageName}: <span style={{fontWeight: "600"}}>{average}%</span></p>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                >
                    <div style={{
                        background: "#7B61FF",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "10px"
                    }} />
                    <p style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#6B6C6F",
                    }}>{currentName}: <span style={{fontWeight: "600"}}>{current}%</span></p>
                </Box>
            </Box>
        </Box>
    )
}

ProgressBar.propTypes = {
    average: PropTypes.number,
    current: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    averageName: PropTypes.string,
    currentName: PropTypes.string
}

export default ProgressBar