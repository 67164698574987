import React, { useState, useEffect, useContext } from 'react'
import {
    Box,
    ListItem,
    Typography,
    List
} from "@material-ui/core";
import Panel from './Panel';
import { useStyles } from '../../UI/styles/themes';
import { useHistory, useLocation } from 'react-router-dom';
import { checkSubsectionComplete} from '../../assets/helpers';
import { ReactComponent as CompanyIcon } from '../../assets/svg/surveyBar/companyIcon.svg';
import { ReactComponent as PeopleIcon } from '../../assets/svg/surveyBar/peopleIcon.svg';
import { ReactComponent as PlanetIcon } from '../../assets/svg/surveyBar/planetIcon.svg';
import { ReactComponent as SourcingIcon } from '../../assets/svg/surveyBar/sourcingIcon.svg';
import { ReactComponent as YourGoalsIcon } from '../../assets/svg/surveyBar/yourGoalsIcon.svg';
import { SurveyDataContext } from '../../assets/contexts/SurveyData';

const NavbarSurvey = () => {
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const pathName = location.pathname;
    const [expandedPanel, setExpandedPanel] = useState(true);
    const {surveyData} = useContext(SurveyDataContext);

    // Company ---
    const [companyCompleted, setCompanyCompleted] = useState(false);

    // People ---
    const [peopleProcessesCompleted,setPeopleProcessesCompleted] = useState(false);
    const [peopleAssessmentsCompleted, setPeopleAssessmentsCompleted] = useState(false);
    const [peopleCommunityCompleted, setPeopleCommunityCompleted] = useState(false);
    const [peopleCommunicationCompleted, setPeopleCommunicationCompleted] = useState(false);
    const [peopleCompanyCompleted, setPeopleCompanyCompleted] = useState(false);

    // Planet ---
    const [planetEnvironmentalManagementCompleted, setPlanetEnvironmentalManagementCompleted] = useState(false);
    const [planetCompanyEmissionsCompleted, setPlanetCompanyEmissionsCompleted] = useState(false);
    const [planetProductionEmissionsCompleted, setPlanetProductionEmissionsCompleted] = useState(false);
    const [planetLogisticsEmissionsCompleted, setPlanetLogisticsEmissionsCompleted] = useState(false);
    const [planetWaterManagementCompleted, setPlanetWaterManagementCompleted] = useState(false);
    const [planetChemicalManagementCompleted, setPlanetChemicalManagementCompleted] = useState(false);
    const [planetWasteCircularityCompleted, setPlanetWasteCircularityCompleted] = useState(false);
    const [planetBiodiversityCompleted, setPlanetBiodiversityCompleted] = useState(false);

    // Sourcing ---
    const [sourcingTransparencyTraceabilityCompleted, setSourcingTransparencyTraceabilityCompleted] = useState(false);
    const [sourcingMaterialsCompleted, setSourcingMaterialsCompleted] = useState(false);
    const [sourcingAnimalWelfareCompleted, setSourcingAnimalWelfareCompleted] = useState(false);
    const [sourcingJewelleryCompleted, setSourcingJewelleryCompleted] = useState(false);

    // your goals
    const [yourGoalsCompleted, setYourgoalsCompleted] = useState(false);

    // Check subsections complete on component mount
    useEffect(() => {
    // Company details
        setCompanyCompleted(checkSubsectionComplete('Company', 'Companydetails', surveyData));

        // Your Goals
        setYourgoalsCompleted(checkSubsectionComplete('Yourgoals', '', surveyData));

        // People
        setPeopleProcessesCompleted(checkSubsectionComplete('People', 'Processes', surveyData));
        setPeopleAssessmentsCompleted(checkSubsectionComplete('People', 'Assessments', surveyData));
        setPeopleCommunityCompleted(checkSubsectionComplete('People', 'Community', surveyData));
        setPeopleCommunicationCompleted(checkSubsectionComplete('People', 'Communication', surveyData));
        setPeopleCompanyCompleted(checkSubsectionComplete('People', 'Company', surveyData));

        // Planet
        setPlanetEnvironmentalManagementCompleted(checkSubsectionComplete('Planet', 'Environmentalmanagement', surveyData));
        setPlanetCompanyEmissionsCompleted(checkSubsectionComplete('Planet', 'Companyemissions', surveyData));
        setPlanetProductionEmissionsCompleted(checkSubsectionComplete('Planet', 'Productionemissions', surveyData));
        setPlanetLogisticsEmissionsCompleted(checkSubsectionComplete('Planet', 'Logisticsemissions', surveyData));
        setPlanetWaterManagementCompleted(checkSubsectionComplete('Planet', 'Watermanagement', surveyData));
        setPlanetChemicalManagementCompleted(checkSubsectionComplete('Planet', 'Chemicalmanagement', surveyData));
        setPlanetWasteCircularityCompleted(checkSubsectionComplete('Planet', 'Waste&circularity', surveyData));
        setPlanetBiodiversityCompleted(checkSubsectionComplete('Planet', 'Biodiversity', surveyData));

        // Sourcing
        setSourcingTransparencyTraceabilityCompleted(checkSubsectionComplete('Sourcing', 'Transparency&traceability', surveyData));
        setSourcingMaterialsCompleted(checkSubsectionComplete('Sourcing', 'Materials', surveyData));
        setSourcingAnimalWelfareCompleted(checkSubsectionComplete('Sourcing', 'Animalwelfare', surveyData));
        setSourcingJewelleryCompleted(checkSubsectionComplete('Sourcing', 'Jewellery', surveyData));
    }, [surveyData]);

    // complete sections
    const peopleCompleted = [peopleProcessesCompleted, peopleAssessmentsCompleted, peopleCommunityCompleted, peopleCommunicationCompleted, peopleCompanyCompleted].every(state => state === true);

    const planetCompleted = [planetEnvironmentalManagementCompleted, planetCompanyEmissionsCompleted, planetProductionEmissionsCompleted, planetLogisticsEmissionsCompleted, planetWaterManagementCompleted, planetChemicalManagementCompleted, planetWasteCircularityCompleted, planetBiodiversityCompleted].every(state => state === true);

    const sourcingCompleted = [ sourcingTransparencyTraceabilityCompleted, sourcingMaterialsCompleted, sourcingAnimalWelfareCompleted, sourcingJewelleryCompleted].every(state => state === true);

    return (
        <Box width={1} height={1}>
            <List className={classes.panel}>
                <ListItem
                    button
                    disableRipple='false'
                    selected={pathName === '/survey/company'}
                    onClick={() => {
                        history.push('/survey/company')
                    }}
                >
                    <CompanyIcon fill="#50545E"/>
                    <Box
                        ml="16px"
                        width={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Box>
                            <Typography className={classes.H300}>Company</Typography>
                        </Box>
                        {
                            companyCompleted ?
                                <Box color="success.main">
                                    <Typography variant='subtitle2'>Completed</Typography>
                                </Box>
                                :
                                <Box color="grey.main">
                                    <Typography variant="subtitle2">Incomplete</Typography>
                                </Box>
                        }
                    </Box>
                </ListItem>

                <Panel
                    heading="Planet"
                    status={planetCompleted}
                    sections={['Environmental management', 'Company emissions', 'Production emissions', 'Logistics emissions', 'Water management', 'Chemical management', 'Waste & circularity', 'Biodiversity']}
                    url="planet"
                    subSectionStatus={[planetEnvironmentalManagementCompleted, planetCompanyEmissionsCompleted, planetProductionEmissionsCompleted, planetLogisticsEmissionsCompleted, planetWaterManagementCompleted, planetChemicalManagementCompleted, planetWasteCircularityCompleted, planetBiodiversityCompleted]}
                    pathName={pathName}
                    expandedPanel={expandedPanel}
                    setExpandedPanel={setExpandedPanel}
                >
                    <PlanetIcon fill="#50545E"/>
                </Panel>

                <Panel
                    heading="People"
                    url="people"
                    status={peopleCompleted}
                    sections={['Processes', 'Assessments', 'Community ', 'Company', 'Communication']}
                    subSectionStatus={[peopleProcessesCompleted, peopleAssessmentsCompleted, peopleCommunityCompleted, peopleCompanyCompleted, peopleCommunicationCompleted]}
                    pathName={pathName}
                    expandedPanel={expandedPanel}
                    setExpandedPanel={setExpandedPanel}
                >
                    <PeopleIcon fill="#50545E"/>
                </Panel>

                <Panel
                    heading="Sourcing"
                    status={sourcingCompleted}
                    sections={['Transparency & Traceability', 'Materials', 'Animal welfare', 'Jewellery']}
                    url="sourcing"
                    subSectionStatus={[sourcingTransparencyTraceabilityCompleted, sourcingMaterialsCompleted, sourcingAnimalWelfareCompleted, sourcingJewelleryCompleted]}
                    pathName={pathName}
                    expandedPanel={expandedPanel}
                    setExpandedPanel={setExpandedPanel}
                >
                    <SourcingIcon fill="#50545E"/>
                </Panel>

                <ListItem
                    button
                    disableRipple='false'
                    selected={pathName === '/survey/yourgoals'}
                    onClick={() => {
                        history.push('/survey/yourgoals')
                    }}
                >
                    <YourGoalsIcon fill="#50545E" />
                    <Box
                        ml="16px"
                        width={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Box >
                            <Typography className={classes.H300}>Your goals</Typography>
                        </Box>
                        {
                            yourGoalsCompleted ?
                                <Box color="success.main">
                                    <Typography variant='subtitle2'>Completed</Typography>
                                </Box>
                                :
                                <Box color="grey.main">
                                    <Typography variant="subtitle2">Incomplete</Typography>
                                </Box>
                        }
                    </Box>
                </ListItem>
            </List>
        </Box>
    )
}

export default NavbarSurvey