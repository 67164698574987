import React from 'react';
import { useQuery } from 'react-query';
import { Storage } from 'aws-amplify';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types'

const AdminFileList = (props) => {
    const { surveyData, currentBrand, surveyName} = props;
    const { isLoading, isError, data, error } = useQuery(`fileLink_${currentBrand?.brandCode}_${surveyName}`, async () => {
        const currentSurvey = surveyData.find((survey) => survey.surveyName === surveyName)
        if(currentSurvey){
            const {surveyData} = currentSurvey;
            const survey = JSON.parse(surveyData) || {};
            const questionKeys = Object.keys(survey);
            const files = [];
            try {
                questionKeys.forEach((question) => {
                    // check wether a '_file' and that file exists
                    if (question.includes('_file') && survey[question] !== undefined) {
                        // for each file, run getFiles function
                        survey[question].forEach( async (fileLoc) => {
                            const file = await getFile(fileLoc);
                            files.push(file);
                        })
                    }
                });
            } catch (error) {
                console.error(error.message, { questionKeys, surveyData, currentSurvey, surveyName });
            }
            return files
        } else {
            console.warn('Could not find key', { surveyData, currentSurvey, surveyName })
        }
    });

    // function to get/download uploaded files from S3 bucket: key = S3Loc
    async function getFile (file) {
        const S3Loc = file.S3Loc;    /* get file path */

        // create new object to pass to survey js
        let newFile = Object.create(null);
        newFile.name = file.name;
        newFile.type=  file.type;

        // get the file data from S3bucket
        const downloadUrl = await Storage.get(S3Loc, {
            level: 'public',
            download: false, // false -> can then use to create url string for download
        });
        newFile.url = downloadUrl;

        return newFile
    }

    if(isError) {
        console.error(error.message);
    }

    return (
        <Box mt='10px'>
            {
                isLoading ? (
                    <span>Loading...</span>
                ) : isError ? <p>It looks like no files was uploaded</p>: (
                    <>
                        {
                            data && data.length === 0 ? <p>No Files Uploaded</p> :
                                <>
                                    <Typography>Download link for files:</Typography>
                                    <ul>
                                        {
                                            data.map((file, index) => (
                                                <li key={`${file.name}_${index}`}><a href={file.url} target="_blank" rel="noreferrer">{file.name}</a></li>
                                            ))
                                        }
                                    </ul>
                                </>
                        }
                    </>
                )
            }
        </Box>
    )
}

AdminFileList.propTypes = {
    surveyData: PropTypes.array,
    currentBrand: PropTypes.object,
    surveyName: PropTypes.string,
}

export default AdminFileList
