import React, { useEffect, useContext, useState } from 'react';
import { Auth, API } from 'aws-amplify';
import Survey from '../../Brands/Survey';
import { useHistory } from 'react-router-dom';
import { CurrentBrandContext } from '../../../assets/contexts/CurrentBrand';
import { SurveyDataContext } from '../../../assets/contexts/SurveyData';
import { Snackbar } from '@mui/material';
import { Box, Typography } from '@material-ui/core';

const SurveyPages = () => {
    const { setSurveyData } = useContext(SurveyDataContext);
    const {currentBrand} = useContext(CurrentBrandContext);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [isSessionTimeOut, setIsSessionTimeOut] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
        setIsSessionTimeOut(true);
    };

    useEffect(() => {
        const getData = async() => {
            const tokens = await Auth.currentSession();
            // user -> receive brand specific data
            const token = tokens.idToken.jwtToken

            if(!currentBrand.brandCode) {
                console.error('No brand code associated with the current user')
                // return;
            }

            const payload = {
                brandCode : currentBrand.brandCode,
                surveyName: ''    /* all surveys for user */
            }

            // get data from survey API on component initialization
            try {
                const { data = [] } = await API.get('surveyapi', '/survey-results', {
                    headers: {
                        Authorization : token,
                    },
                    response: true,
                    queryStringParameters : payload
                })
                setSurveyData(data);
                setLoadingComplete(true);
            } catch (err) {
                console.error('error fetching data', err)
            }
        }
        if (currentBrand === null) {
            setIsOpen(true);
        } else {
            getData();
        }
    }, [currentBrand, setSurveyData]);

    if (isSessionTimeOut) {
        history.push('/home');
    }

    return (
        <>
            <Snackbar
                open={isOpen}
                onClose={handleClose}
            >
                <Box width={1} height={1} bgcolor='#706CEC' p="13px 15px" borderRadius="5px">
                    <Typography style={{fontSize: '14px', color:"#FFFFFF"}}>Session timed out. Please select a brand again.</Typography>
                </Box>
            </Snackbar>
            {
                loadingComplete && <Survey />
            }
        </>
    )
}

export default SurveyPages
