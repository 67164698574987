import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types'

const MyToast = (props) => {
    const { message, primaryAction, closeAction, state, isAction } = props;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(state);
    }, [state]);

    const handleClose = () => {
        closeAction && closeAction();
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={primaryAction}>
        Sign in
            </Button>
        </React.Fragment>
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                open={open}
                onClose={handleClose}
                message={message}
                action={isAction && action}
            />
        </>
    );
}

MyToast.propTypes = {
    message: PropTypes.string.isRequired,
    primaryAction: PropTypes.func,
    closeAction: PropTypes.func,
    state: PropTypes.bool.isRequired,
    isAction: PropTypes.bool,
}

export default MyToast