import React from 'react';
import SurveyPage from '../../../../UI/templates/SurveyPage';
import { compDetJSON } from '../../../../assets/surveyJSON/company/companyDetails';

export const Company = () => {
    return (
        <SurveyPage
            section="Company"
            subsection={"Company details"}
            description={compDetJSON.pages[0].descriptionEdit}
            jsonFile={compDetJSON}
            nextSection="/survey/planet/environmentalmanagement"
        />
    );
};
