export const bioJSON = {
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "html",
                    "name": "question1-html",
                    "html": "<h4>1.  Do you have any practices or initiatives to reduce the impact your production and/or operations have on biodiversity?</h4>\n\n<ul>\n <li>Sustainably managed forests - deforestation occurs in both product and packaging supply chains, for example in the production of cellulose materials such as viscose. Initiatives such as Canopy and the Forest Stewardship Council exist to limit deforestation.</li>\n\n <li>Promote ocean conservation - the fashion industry harms ocean health through factors such as chemical runoff and plastic pollution. This question refers to any initiatives you are part of working to preserve the biodiversity of our oceans.</li>\n\n <li>Prevent release of microfibres and/or microplastics - microfibres are less than 5mm in size and are released in the processing and washing of textiles. Microfibres come from both natural materials such as wool and cotton, and plastic-based materials made from oil such as polyester and nylon. This second group is called microplastics. Both microfibres and microplastics are the cause of extensive waterway pollution.</li>\n\n <li>Promote regenerative agriculture in your supply chain - regenerative agriculture refers to farming practices which focus on building soil health in order to promote biodiversity as well as sequester carbon from the atmosphere.</li>\n\n</ul>"
                },
                {
                    "type": "checkbox",
                    "name": "Planet_Biodiversity_Initiatives_1",
                    "indent": 1,
                    "titleLocation": "hidden",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "item1",
                            "text": "Sustainably managed forests"
                        },
                        {
                            "value": "item2",
                            "text": "Promote ocean conservation"
                        },
                        {
                            "value": "item3",
                            "text": "Prevent release of microfibres and/or microplastics"
                        },
                        {
                            "value": "item4",
                            "text": "Promote regenerative agriculture in your supply chain "
                        },
                        {
                            "value": "item6",
                            "text": "None"
                        }
                    ],
                    "showOtherItem": true,
                    "commentPlaceholder": "dsd"
                },
                {
                    "type": "comment",
                    "name": "Planet_Biodiversity_Initiatives_other",
                    "visibleIf": "{Planet_Biodiversity_Initiatives_1} = ['item5']",
                    "indent": 3,
                    "title": "If other, please describe the initiative below and explain its focus on promoting and protecting biodiversity",
                    "hideNumber": true,
                    "requiredIf": "{Planet_Biodiversity_Initiatives_1} = ['item5']"
                },
                {
                    "type": "file",
                    "name": "Planet_Biodiversity_Initiatives_file",
                    "visibleIf": "{Planet_Biodiversity_Initiatives_1} anyof ['item1', 'item2', 'item3', 'item4', 'item5']",
                    "indent": 3,
                    "title": "Please upload supporting evidence",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "Planet_Biodiversity_Initiatives_desc",
                    "visibleIf": "{Planet_Biodiversity_Initiatives_1} anyof ['item1', 'item2', 'item3', 'item4']",
                    "indent": 3,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{Planet_Biodiversity_Initiatives_file} empty"
                },
                {
                    "type": "comment",
                    "name": "Planet_Biodiversity_Other",
                    "title": " Is there anything else that you would like to share about Biodiversity?",
                    "hideNumber": true
                }
            ],
            "descriptionEdit": "This section focuses on any initiatives you have in place to promote and protect biodiversity and support healthy ecosystems."
        }
    ],
    "showCompletedPage": false
}