export const companyJSON = {
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "People_Company_DEI_1",
                    "title": "Does your company have any diversity, equality and inclusion commitments? ",
                    "description": "This can be a document that your company uses to promote diversity, inclusion and equality within your business, operations and hiring practices. This question seeks to understand the extent to which you communicate topics relating to diversity, inclusion and equality to your employees and embed these practices within your business - for example, you may have held an informal training about revised recruitment practices, or include a commitment in your job postings.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "People_Company_DEIpublic_1.1",
                    "visibleIf": "{People_Company_DEI_1} = 1",
                    "indent": 3,
                    "title": "1.1. Is it public?",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "People_Company_DEIpublic_link",
                    "visibleIf": "{People_Company_DEI_1} = 1 and {People_Company_DEIpublic_1.1} = 1",
                    "indent": 4,
                    "title": "Please provide the link",
                    "hideNumber": true,
                    "isRequired": true
                },
                {
                    "type": "file",
                    "name": "People_Company_DEIpublic_file",
                    "visibleIf": "{People_Company_DEI_1} = 1 and {People_Company_DEIpublic_1.1} = 2",
                    "indent": 4,
                    "title": "Please upload to share internally",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "People_Company_DEIpublic_desc",
                    "visibleIf": "{People_Company_DEI_1} = 1 and {People_Company_DEIpublic_1.1} = 2",
                    "indent": 4,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{People_Company_DEI_1} = 1 and {People_Company_DEIpublic_1.1} = 2 and {People_Company_DEIpublic_file} empty"
                },
                {
                    "type": "checkbox",
                    "name": "People_Company_DEIcharacteristics_2",
                    "title": "If you have direct employees, do you track any diversity characteristics? ",
                    "description": "This question relates to your company's direct employees and includes both full-time and part-time employees.",
                    "choices": [
                        {
                            "value": "1",
                            "text": "Disability"
                        },
                        {
                            "value": "2",
                            "text": "Ethnicity"
                        },
                        {
                            "value": "3",
                            "text": "Gender identity"
                        },
                        {
                            "value": "4",
                            "text": "Sexuality"
                        },
                        {
                            "value": "5",
                            "text": "Social mobility"
                        },
                        {
                            "value": "6",
                            "text": "Other"
                        },
                        {
                            "value": "7",
                            "text": "No"
                        }
                    ],
                    "showOtherItem": true,
                    "otherText": "Not applicable"
                },
                {
                    "type": "radiogroup",
                    "name": "People_Company_DEIwageanalysis_2.1",
                    "visibleIf": "{People_Company_DEIcharacteristics_2} anyof [1, 2, 3, 4, 5, 6]",
                    "indent": 3,
                    "title": "2.1 Have you ever carried out a wage analysis relating to any of these characteristics? ",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        },
                        {
                            "value": "3",
                            "text": "Not applicable"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "People_Company_Directlivingwage_3",
                    "title": "If you have direct employees, do you pay at least living wage? ",
                    "description": "This question refers to a living wage, which is generally different to the minimum wage. The UK's living wage is defined by the UK government. If you have direct employees outside of the UK, this question also covers them.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        }
                    ],
                    "showOtherItem": true,
                    "otherText": "Not applicable"
                },
                {
                    "type": "file",
                    "name": "People_Company_Directlivingwage_file",
                    "visibleIf": "{People_Company_Directlivingwage_3} = 1",
                    "indent": 3,
                    "title": "Please upload supporting evidence (Please do not share sensitive or personal information)",
                    "description": "Examples of evidence include anonymised wage information such as anonymised payslips for your team or other evidence of being a living wage employer.",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "People_Company_Directlivingwage_desc",
                    "visibleIf": "{People_Company_Directlivingwage_3} = 1",
                    "indent": 3,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{People_Company_Directlivingwage_3} = 1 and {People_Company_Directlivingwage_file} empty"
                },
                {
                    "type": "text",
                    "name": "People_Company_benefits_4",
                    "title": "If you have direct employees, what employee benefits or wellbeing initiatives do you offer?",
                    "description": "This question refers to any initiatives your company has taken to support staff and their wellbeing. This might include mentoring between teams, staff discounts, health and gym subscriptions or employee development trainings. Please enter in the box below.",
                    "isRequired": true
                },
                {
                    "type": "radiogroup",
                    "name": "People_Company_Charity_5",
                    "title": "Do you support any charitable activities?",
                    "description": "Charitable activities include any philanthropic contributions that your company undertakes to provide support for any person, community, or organisation in need.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "comment",
                    "name": "People_Company_Charity_desc",
                    "visibleIf": "{People_Company_Charity_5} = 1",
                    "indent": 3,
                    "title": "Please describe or link to external website",
                    "hideNumber": true,
                    "isRequired": true,
                    "rows": 2
                },
                {
                    "type": "comment",
                    "name": "People_Company_Other",
                    "title": " Is there anything else that you would like to share about People in your company? ",
                    "hideNumber": true
                }
            ],
            "descriptionEdit": "This section focuses on the conditions of those you directly employ, whether full-time or part-time. "
        }
    ],
    "showCompletedPage": false
}
