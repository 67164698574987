import React from 'react'
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import { Box } from '@material-ui/core';
import SurveyPage from '../../../../UI/templates/SurveyPage';
import { bioJSON } from '../../../../assets/surveyJSON/planet/biodiversity';
import { chemManJSON } from '../../../../assets/surveyJSON/planet/chemicalManagement';
import { compEmissJSON } from '../../../../assets/surveyJSON/planet/companyEmissions';
import { environmentalMgtJSON } from '../../../../assets/surveyJSON/planet/environmentalManagement';
import { logEmissJSON } from '../../../../assets/surveyJSON/planet/logisticsEmissions';
import { prodEmissJSON } from '../../../../assets/surveyJSON/planet/productionEmissions';
import { wasCircJSON } from '../../../../assets/surveyJSON/planet/wasteCircularity';
import { waterMgtJSON } from '../../../../assets/surveyJSON/planet/waterManagement';

const PlanetSection = () => {
    const { path } = useRouteMatch();
    return (
        <Box width={1} height={1}>
            <Switch>
                <Route path={`${path}/environmentalmanagement`}>
                    <SurveyPage
                        section="Planet"
                        subsection="Environmental management"
                        description={environmentalMgtJSON.pages[0].descriptionEdit}
                        jsonFile={environmentalMgtJSON}
                        nextSection={`${path}/companyemissions`}
                    />
                </Route>
                <Route path={`${path}/companyemissions`}>
                    <SurveyPage
                        section="Planet"
                        subsection={"Company emissions"}
                        description={compEmissJSON.pages[0].descriptionEdit}
                        jsonFile={compEmissJSON}
                        nextSection={`${path}/productionemissions`}
                    />
                </Route>

                <Route path={`${path}/productionemissions`}>
                    <SurveyPage
                        section="Planet"
                        subsection={"Production emissions"}
                        description={prodEmissJSON.pages[0].descriptionEdit}
                        jsonFile={prodEmissJSON}
                        nextSection={`${path}/logisticsemissions`}
                    />
                </Route>

                <Route path={`${path}/logisticsemissions`}>
                    <SurveyPage
                        section="Planet"
                        subsection={"Logistics emissions"}
                        description={logEmissJSON.pages[0].descriptionEdit}
                        jsonFile={logEmissJSON}
                        nextSection={`${path}/watermanagement`}
                    />
                </Route>

                <Route path={`${path}/watermanagement`}>
                    <SurveyPage
                        section="Planet"
                        subsection={"Water management"}
                        description={waterMgtJSON.pages[0].descriptionEdit}
                        jsonFile={waterMgtJSON}
                        nextSection={`${path}/chemicalmanagement`}
                    />
                </Route>

                <Route path={`${path}/chemicalmanagement`}>
                    <SurveyPage
                        section="Planet"
                        subsection={"Chemical management"}
                        description={chemManJSON.pages[0].descriptionEdit}
                        jsonFile={chemManJSON}
                        nextSection={`${path}/waste&circularity`}
                    />
                </Route>

                <Route path={`${path}/waste&circularity`}>
                    <SurveyPage
                        section="Planet"
                        subsection={`Waste & circularity`}
                        description={wasCircJSON.pages[0].descriptionEdit}
                        jsonFile={wasCircJSON}
                        nextSection={`${path}/biodiversity`}
                    />
                </Route>

                <Route path={`${path}/biodiversity`}>
                    <SurveyPage
                        section="Planet"
                        subsection="Biodiversity"
                        description={bioJSON.pages[0].descriptionEdit}
                        jsonFile={bioJSON}
                        nextSection='/survey/people/processes'
                    />
                </Route>
            </Switch>
        </Box>
    )
}

export default PlanetSection
