export const procJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "checkbox",
                    name: "People_Processes_Policy_1",
                    title: "Do you have any policies or processes covering labour conditions and ethical trade? ",
                    description: "We are asking whether you have anything that outlines the standards you expect your suppliers to follow regarding ethical trade and worker conditions. As a minimum, this should ensure your suppliers are compliant with local regulations. You might call this something else - such as a handbook or a policy. Please select all that are covered.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Worker hours/excessive overtime"
                        },
                        {
                            value: "2",
                            text: "Nondiscrimination"
                        },
                        {
                            value: "3",
                            text: "Minimum age requirements"
                        },
                        {
                            value: "4",
                            text: "Compensation practices"
                        },
                        {
                            value: "5",
                            text: "Worker treatment and development (anti-harassment and anti-abuse policies) "
                        },
                        {
                            value: "6",
                            text: "Termination and retrenchment policies"
                        },
                        {
                            value: "7",
                            text: "Freedom of association (worker involvement and communication) "
                        },
                        {
                            value: "8",
                            text: "Occupational health and safety"
                        }
                    ],
                    hasOther: true,
                    hasNone: true,
                    noneText: "No"
                },
                {
                    type: "radiogroup",
                    name: "People_Processes_Subcont-homeworker_1.1",
                    visibleIf: "{People_Processes_Policy_1} notempty",
                    indent: 3,
                    title: "1.1 Does it cover sub-contractors and homeworkers?",
                    description: "Sub-contractors refers to workers or a facility that a supplier might contract out portions of work in order to complete an order. Homeworkers refers to informal workers who are typically not directly employed by a factory, but still needed to complete an order.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes - homeworkers"
                        },
                        {
                            value: "2",
                            text: "Yes - subcontractors"
                        },
                        {
                            value: "3",
                            text: "Yes - homeworkers and subcontractors"
                        },
                        {
                            value: "4",
                            text: "No"
                        },
                        {
                            value: "5",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "People_Processes_Subcont-homeworker_file",
                    visibleIf: "{People_Processes_Policy_1} notempty and {People_Processes_Subcont-homeworker_1.1} anyof [1, 2, 3]",
                    indent: 5,
                    title: "Please upload to share internally",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "People_Processes_Subcont-homeworker_desc",
                    visibleIf: "{People_Processes_Policy_1} notempty and {People_Processes_Subcont-homeworker_1.1} anyof [1, 2, 3]",
                    indent: 5,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{People_Processes_Subcont-homeworker_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "People_Processes_Anti-slavery_1.2",
                    visibleIf: "{People_Processes_Policy_1} notempty",
                    indent: 3,
                    title: "1.2 Do you have any statement or commitment on prohibiting modern slavery in your supply chain? ",
                    description: "This is a commitment that outlines steps your company takes to prevent modern slavery within your own company and within your supply chains. In the UK, understanding these risks is mandatory for large companies (those with an annual turnover of over £36m). However, the risk of modern slavery can happen in a company of any size.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "People_Processes_Anti-slavery_file",
                    visibleIf: "{People_Processes_Policy_1} notempty and {People_Processes_Anti-slavery_1.2} = 1",
                    indent: 5,
                    title: "Please upload to share internally",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "People_Processes_Anti-slavery_desc",
                    visibleIf: "{People_Processes_Policy_1} notempty and {People_Processes_Anti-slavery_1.2} = 1",
                    indent: 5,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{People_Processes_Anti-slavery_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "People_Processes_Locallanguage_1.3",
                    visibleIf: "{People_Processes_Policy_1} notempty",
                    indent: 3,
                    title: "1.3 Do you communicate to workers in their local language?",
                    description: "This question is asking if workers are provided access to and made aware of their rights in the places they work in a language they can understand.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        },
                        {
                            value: "3",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "People_Processes_Locallanguage_file",
                    visibleIf: "{People_Processes_Policy_1} notempty and {People_Processes_Locallanguage_1.3} = 1",
                    indent: 5,
                    title: "Please upload to share internally",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "People_Processes_Locallanguage_desc",
                    visibleIf: "{People_Processes_Policy_1} notempty and {People_Processes_Locallanguage_1.3} = 1",
                    indent: 5,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{People_Processes_Locallanguage_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "People_Processes_Public_1.4",
                    visibleIf: "{People_Processes_Policy_1} notempty",
                    indent: 3,
                    title: "1.4 Is it public?",
                    description: "We would like to know if you communicate your Ethical Trade Code of Conduct publicly -for example, online, as this increases accountability.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "People_Processes_Public_link",
                    visibleIf: "{People_Processes_Policy_1} notempty and {People_Processes_Public_1.4} = 1",
                    indent: 5,
                    title: "Please provide the link",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "text",
                    name: "People_Processes_Noncompliance_2",
                    title: "What processes do you have in place if you identify any non-compliance issues relating to ethical trade in your supply chain? ",
                    description: "This question is asking whether you have any standard procedures in place if, for example, you were to uncover or be made aware of any ethical trade or labour rights violations in your supply chain. Please describe your process in the box below or upload supporting evidence."
                },
                {
                    type: "file",
                    name: "People_Processes_Noncompliance_file",
                    visibleIf: "{People_Processes_Noncompliance_2} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "People_Processes_Noncompliance_desc",
                    visibleIf: "{People_Processes_Noncompliance_2} notempty",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{People_Processes_Noncompliance_file} empty"
                },
                {
                    type: "checkbox",
                    name: "People_Processes_Risks_3",
                    title: "What potential risks have you mapped in any of your supplier locations?  ",
                    description: "This question is asking whether you have done any analysis to uncover potential regional or local risks in your supplier locations - for example, to increase your understanding of local issues relating to human rights or labour standards. Please select all that are relevant.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Excessive overtime"
                        },
                        {
                            value: "2",
                            text: "Discrimination"
                        },
                        {
                            value: "3",
                            text: "Minimum age requirements"
                        },
                        {
                            value: "4",
                            text: "Compensation practices"
                        },
                        {
                            value: "5",
                            text: "Freedom of association (worker involvement and communication) "
                        },
                        {
                            value: "6",
                            text: "Worker treatment and development (anti-harassment and anti-abuse policies) "
                        },
                        {
                            value: "7",
                            text: "Termination and retrenchment policies"
                        },
                        {
                            value: "8",
                            text: "Unsafe building and occupational safety hazards"
                        },
                        {
                            value: "9",
                            text: "Other"
                        }
                    ],
                    hasOther: true,
                    otherText: "I don't know",
                    selectAllText: "Excessive overtime"
                },
                {
                    type: "file",
                    name: "People_Processes_Risks_file",
                    visibleIf: "{People_Processes_Risks_3} anyof [1, 2, 3, 4, 5, 6, 7, 8, 9]",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "People_Processes_Risks_desc",
                    visibleIf: "{People_Processes_Risks_3} anyof [1, 2, 3, 4, 5, 6, 7, 8, 9]",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{People_Processes_Risks_file} empty"
                },
                {
                    type: "comment",
                    name: "People_Processes_Other",
                    title: "Is there anything else that you would like to share about People in your supply chain: Processes?",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on the policies or processes you may have in place regarding the well-being and conditions of workers in your supply chain.   "
        }
    ],
    showCompletedPage: false
}