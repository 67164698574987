import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/peopleProAssess.jpeg';

const PeopleProAssess = () => {
    const refs = [
        "Building Resilience In Supply Chains, World Economic Forum, 2013: http://www3.weforum.org/docs/WEF_RRN_MO_BuildingResilienceSupplyChains_ExecutiveSummary_2013.pdf",
        "Purchasing Practices And Wow Wages In Global Supply Chains: Empirical Cases From The Garment Industry, Mark Starmanns (University of Zurich), 2017",
        "German Parliament Passes Mandatory Human Rights Due Diligence Law, Business and Human RIghts Resource Centre, no date: https://www.business-humanrights.org/en/latest-news/german-due-diligence-law/",
        "80% Exhibition, Fashion Revolution, no date: https://www.fashionrevolution.org/asia-vietnam-80-percent-exhibition/",
        "Act Labour Cost Protocol, Act on Living Wages, 2021: https://actonlivingwages.com/app/uploads/2021/04/ACT-Labour-Costing-Protocol.pdf",
        "Clean Clothes Campaign, no date: https://cleanclothes.org/",
        " A Guide to Risk Assessment in Supply Chains, Sedex, 2020: https://www.sedex.com/wp-content/uploads/2020/03/Sedex-Risk-assessment-in-supply-chains.pdf",
        " Human Rights Due Diligence Framework,  Ethical Trading Initiative, no date: https://www.ethicaltrade.org/sites/default/files/shared_resources/eti_human_rights_due_diligence_framework.pdf"
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title="People Processes and Assessments" isMain={true}/>
            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" refs="1 2 3" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>

                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>

                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>
              When talking about people in your supply chain, we are generally talking about the workers at all levels of the supply chain who contribute to the production of the garments you sell.
                            <br /><br />
              The majority of fashion brands do not own the factories and facilities where their products are produced. Calls to address transparency and accountability and make brands responsible for worker welfare is described as “ethical trade”.
                        </Typography>
                    </Box>
                </Box>

                <Typography style={{fontSize: "1.1rem"}}>
          Ethical trade is a bit of an ambiguous term, but essentially means the responsibility that you have, and that your suppliers take, for the labour and human rights of those people who make the products you sell.
                    <br /><br />
          Ethical trade encompases both the farmer in India growing the cotton used for a T-shirt and the garment worker in China who sews it together.
                </Typography>

                <ResourceTitle title={`Why is it important?`} isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>There are numerous issues facing workers in the garment industry. Let&lsquo;s unpack some of them.</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "20px 0"}}>
                        <span style={{fontWeight: '600'}}>Low-pay and weak employment terms</span><br />
            A worker may not have a formal contract that is necessary to ensure they receive fair wages, basic employment rights, and adequate working conditions.
                    </li>
                    <li style={{margin: "20px 0"}}>
                        <span style={{fontWeight: '600'}}>Gender discrimination and sexual harassment</span><br />
            Around 80 percent of garment workers globally are women and girls<sup>4</sup>. In general, women work in the most labour intensive roles (e.g. sewing), whereas the majority of men are managers and supervisors which can create power imbalances.
                    </li>
                    <li style={{margin: "20px 0"}}>
                        <span style={{fontWeight: '600'}}>Migrant exploitation</span><br />
            Migrant workers are at higher risk of exploitation by opportunistic employers. Workers on temporary visas may lose their ‘legal’ status if dismissed from employment. Such workers may have added financial pressure to send money to families or pay recruitment agencies, labour brokers or traffickers.
                    </li>
                    <li style={{margin: "20px 0"}}>
                        <span style={{fontWeight: '600'}}>Unsafe workplaces</span><br />
            Workers can be exposed to dangerous buildings, harmful chemicals, electrical issues, inhumanely hot worksites and experiences of physical and sexual violence.
                    </li>
                </ul>

                <ResourceTitle title={`Are audits good?`} isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>The main approach for a company to be ‘doing’ ethical trade has been through audits or other social compliance checks. This essentially means a company will expect all of its suppliers to comply with a code of conduct and will check this through auditing a factory. There are numerous types of audit organisations. Examples include:</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}><a href="https://www.ethicaltrade.org/" target="_blank" rel="noopener noreferrer">Ethical Trading Initiative</a></li>
                    <li style={{margin: "10px 0"}}><a href="https://www.amfori.org/content/amfori-bsci" target="_blank" rel="noopener noreferrer">Amfori BSCI</a></li>
                    <li style={{margin: "10px 0"}}><a href="https://www.fairwear.org/" target="_blank" rel="noopener noreferrer">Fairwear Foundation</a></li>
                    <li style={{margin: "10px 0"}}><a href="https://www.sedex.com/" target="_blank" rel="noopener noreferrer">Sedex</a></li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>All of these organisation base their audit methodologies on conventions of the International Labour Organisation, which include:</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>No forced or compulsory labour</li>
                    <li style={{margin: "10px 0"}}>No child labour</li>
                    <li style={{margin: "10px 0"}}>Freedom of association</li>
                    <li style={{margin: "10px 0"}}>Recognition of the right to collective bargaining</li>
                    <li style={{margin: "10px 0"}}>No discrimination</li>
                    <li style={{margin: "10px 0"}}>A safe and healthy working environment</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
          Audits may be able to provide an overview of some of the conditions in factories you source from. However, an audit is only a snapshot in time and (especially with an announced audit) can be a tick box exercise that doesn’t address the root causes of labour and human rights violations.
                    <br/><br/>
          Key criticism of social compliance audits is that the management of risk is effectively outsourced to suppliers, rather than assessing how a brand’s business model and operations may be driving some of the key issues they want to tackle.
                </Typography>

                <Typography style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Potential next steps</Typography>

                <Typography style={{fontSize: "1.1rem"}}>Below are some suggestions for possible steps you can take to embed processes and assessments relating to labour rights in your supply chain.<br/><br /></Typography>

                <Typography style={{fontSize: "1.1rem", fontWeight: "600"}}>Questions to ask your suppliers</Typography>

                <Typography style={{fontSize: "1.1rem"}}>
          If you are at the beginning of your ethical trade journey, the very first step to take is to map your known supply chain.
                    <br/><br/>
          Once you have a sense of this, and if you haven’t done so already, begin by having dialogue with your suppliers.
                    <br/><br/>
          Ask them questions and if you haven’t done so already, ask if they have ever had a social compliance audit. If yes, follow up by asking:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>How long ago did the audit take place?</li>
                    <li style={{margin: "10px 0"}}>What was the result of the audit (i.e. did the audit identify the need for any corrective actions?)</li>
                    <li style={{margin: "10px 0"}}>Can they share the audit report with you?</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>Additional questions you could ask include:</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Do they ever use subcontractors or homeworkers?</li>
                    <li style={{margin: "10px 0"}}>What happens if a worker has a complaint?</li>
                    <li style={{margin: "10px 0"}}>re employment rights communicated to workers in their local language?</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Code of conduct</span><br />
          Develop a commitment or code of conduct to ethical trade and supply chain labour rights. Ask your supplier to sign it as part of your sourcing and procurement negotiation. Ongoing monitoring and dialogue with your suppliers will be necessary to ensure the labour rights code of conduct is being implemented and adhered to as you intend.
                    <br /><br />
                    <span style={{fontWeight: "600"}}>Supplier visits</span><br />
          If you are visiting a supplier in person, ask if you can speak to one of the garment workers without a manager present. If you are able to do this, ask them directly about their experience at working at the factory. For example, what are they happy with and what would they like to change? Do they feel they have adequate representation to negotiate with management (for example, are they a member of a local trade union)?
                    <br /><br />
                    <span style={{fontWeight: "600"}}>Consider your own purchasing practices<sup>5 6</sup></span><br />
          Provide flexible delivery dates if possible or could you consider reorganising an order to accommodate any capacity issues your supplier might be facing? When it comes to your purchasing practices and negotiations with suppliers, it is suggested to avoid the following:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Commercial</span>
                        <ul>
                            <li>Unreasonable price negotiations</li>
                            <li>Poor payment terms</li>
                            <li>Late payments</li>
                            <li>Labour costs not itemised in costs</li>
                            <li>Overtime not calculated into labour costs</li>
                        </ul>
                    </li>

                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Technical</span>
                        <ul>
                            <li>Changes to orders after confirmation</li>
                            <li>Orders placed too late in-peak season</li>
                            <li>Delays in product sign off</li>
                            <li>Lack of forecasting and information sharing</li>
                            <li>Incorrect product specifications</li>
                            <li>Holding off decisions to better gauge consumer demands</li>
                        </ul>
                    </li>

                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Contractual</span>
                        <ul>
                            <li>Changes to orders after confirmation</li>
                            <li>Short term contracts</li>
                            <li>Unfair terms and conditions for suppliers</li>
                            <li>Sourcing strategies based on price, quality and/or quantity rather than rewarding ethical trading compliance and practice</li>
                        </ul>
                    </li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Explore a different approach to ethical trade - Human Rights Due Diligence<sup>7 8</sup></span><br />
          This approach is about companies actively working to understand, manage and identify risks.
                    <br /><br />
          Risk assessments and due diligence have always been used to assure quality, profitability and reputation of a business. However respect for human rights is a relatively new feature of corporate due diligence processes. This shift has been driven by the UN Guiding Principles on Human Rights and relatively recent introduction of legislation on modern slavery.
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Human rights in this context refers to ‘labour rights’ and ‘workers’ as it is about how workers&lsquo; human rights are upheld throughout business supply chains.</li>
                    <li style={{margin: "10px 0"}}>Key risk factors include:
                        <ul>
                            <li>Lack of government protection</li>
                            <li>Sub-contractors and agencies</li>
                            <li>Lack of government labour inspections</li>
                            <li>Reliance on audits</li>
                            <li>Complex production processes</li>
                            <li>Labour market dynamics such as local poverty rates</li>
                            <li>Unscrupulous recruitment practices</li>
                        </ul>
                    </li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>How can a company do this?</span><br />
          Mapping your supply chain to understand the location of your production facilities is the first step as this requires an understanding of country or region-specific risks. A human rights due diligence approach might have four steps:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Assess actual & potential human rights risk</li>
                    <li style={{margin: "10px 0"}}>Identify leverage, responsibility & actions</li>
                    <li style={{margin: "10px 0"}}>Mitigate risk & remediate workers</li>
                    <li style={{margin: "10px 0"}}>Monitor, review, report & improve</li>
                </ul>

                <Typography  style={{fontSize: "1.1rem"}}>
          There are a number of organisations working on developing methodologies in this space, such as Textile Exchange as well as Danish Fashion and Textile, who recently released their guide for the fashion and textiles industry on Human Rights Due Diligence. Check out these resources for more comprehensive details.
                    <br/><br/>
          Make sure to also consult the accompanying guide “People Community”.
                </Typography>
            </Box>
            <ResourcesReferences references={refs} />
        </Box>
    )
}

export default PeopleProAssess