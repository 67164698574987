// Functions for exporting survey results

export const flattenArray = (array) => {
    return array.reduce((a, b) => {
        return a.concat(Array.isArray(b) ? flattenArray(b) : b);
    }, []);
}

// formats survey questions based of input type
export const structureQuestionsFromJSON = (questionsJSON) => {
    return questionsJSON.map((question) => {
        switch (question.type) {
        case 'radiogroup':
            return {
                name: question.name,
                title: question.title,
                choices: question.choices
            }
        case 'checkbox':
            return {
                name: question.name,
                title: question.title,
                checkbox: true,
                choices: question.choices
            }
        case 'panel':
            return {
                name: question.name,
                title: question.title,
                questions: question.elements,
                panel: true
            }
        case 'file':
            return {
                name: question.name,
                title: question.title,
                file: true
            }
        case 'multipletext':
            return {
                name: question.name,
                title: question.title,
                items: question.items,
                multiText: true
            }
        case 'text':
        case 'comment':
            return {
                name: question.name,
                title: question.title
            }
        default:
            return null
        }
    })
};

// bigIndex: CSV files are indexed from 1
const createFnWrapper = (survey, bigIndex, index) => (createFunc) => {
    return createFunc(survey, bigIndex, index)
}

// type panel contains multiple questions
const createPanelRow = (survey, bigIndex, index) => (formattedQuestion, i) => {
    const wrapFunc = createFnWrapper(survey, bigIndex, index);
    if (!survey.answers[formattedQuestion.name]) {
        return (wrapFunc(processTypeUndefined))(formattedQuestion, i);
    }
    else if (formattedQuestion.choices) {
        return (wrapFunc(processTypeChoices))(formattedQuestion, i);
    }
    else if (formattedQuestion.file) {
        return (wrapFunc(processTypeFile))(formattedQuestion, i);
    }
    else {
        return {
            id: `${bigIndex}.${index+1}.${i}`,
            name: formattedQuestion.name,
            title: formattedQuestion.title,
            answer: survey.answers[formattedQuestion.name].toString()
        }
    }
}

export const createRows = (allSurveys) => {
    return flattenArray(allSurveys.map((survey, index) => {
        const questions = structureQuestionsFromJSON(survey.questions);
        const bigIndex = index+1;
        const wrapFunc = createFnWrapper(survey, bigIndex, index);
        const formattedQuestions = questions.map((question, i) => {
            let answers = {}
            try {
                answers = JSON.parse(survey.answers)
            } catch (e) {
                console.warn('Error parsing answers', e, survey.answers)
            }
            if (question.panel) {
                return structureQuestionsFromJSON(question.questions).map(wrapFunc(createPanelRow))
            }
            else if (question.multiText) {
                return (wrapFunc(processTypeMultiText))(question);
            }
            else if (answers[question.name] === undefined) {
                return (wrapFunc(processTypeUndefined))(question, i);
            }
            else if (question.choices) {
                return (wrapFunc(processTypeChoices))(question, i);
            }
            else if (question.file) {
                return (wrapFunc(processTypeFile))(question, i);
            }
            else {
                return {
                    id: `${bigIndex}.${i+1}`,
                    name: question.name,
                    title: question.title,
                    answer: answers[question.name]
                }
            }
        });
        formattedQuestions.unshift({
            id: survey.surveyTitle,
            name: '-',
            title: '-',
            answer:'-'
        });
        return formattedQuestions
    }))
}

// handle files
const processTypeFile = (survey, bigIndex, index) => (question, i) => {
    return  {
        id: i+1 ? `${bigIndex}.${index+1}.${i}` : `${bigIndex}.${index+1}`,
        name: question.name,
        title: question.title,
        answer: survey.answers[question.name].map(file => file.name).join(' ')
    }
};

// handle multiple choice questions
const processTypeChoices = (survey, bigIndex, index) => (question, i) => {
    let chosen = '';
    const answers = JSON.parse(survey.answers)
    question.choices.forEach((choice) => {
        if (choice.value === answers[question.name]) {
            chosen = choice.text;
        }
        else if ((typeof answers[question.name] === 'string' || Array.isArray(answers[question.name])) && answers[question.name].includes(choice.value)) {
            chosen = `${chosen} ${choice.text},`;
        }
    })
    return {
        id: i+1 ? `${bigIndex}.${index+1}.${i}` : `${bigIndex}.${index+1}`,
        name: question.name,
        title: question.title,
        answer: chosen
    }
};

// handle questions with no answer
const processTypeUndefined = (_, bigIndex, index) => (question, i) => {
    return {
        id: i+1 ? `${bigIndex}.${index+1}.${i}` : `${bigIndex}.${index+1}`,
        name: question.name,
        title: question.title,
        answer: 'no answer'
    }
};

// handle questions with multiple text inputs
const processTypeMultiText = (survey, bigIndex, index) => (question) => {
    return question.items.map((item, i) => {
        return {
            id: `${bigIndex}.${index+1}.${i}`,
            name: question.name,
            title: item.title || question.title,
            answer: survey.answers[question.name][item.name]
        }
    })
};