import React, { useContext } from 'react';
import { Box, TableContainer, TableHead, TableRow, TableBody, TableCell, Table } from '@material-ui/core';
import { CurrentBrandContext } from '../../../assets/contexts/CurrentBrand';

import { useHistory } from 'react-router-dom'
import '../../../App.css';
import PropTypes from 'prop-types';

const BrandTable = ({brandData}) => {
    const {setCurrentBrand} = useContext(CurrentBrandContext);
    const history = useHistory();

    const showBrandSurvey = (brand) => {
        let selected = brandData.filter((item) => item.brandCode === brand.brandCode);
        setCurrentBrand(selected[0]);
        history.push(`/survey`);
    }

    return (
        <Box style={{ maxHeight: "500px" }}>
            <TableContainer style={{maxHeight: '100%'}}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow style={{fontWeight: "500"}}>
                            <TableCell align="left" style={{
                                paddingLeft: "43px",
                                fontWeight: "700"
                            }}>Brand</TableCell>
                            <TableCell align="left">Overall score</TableCell>
                            <TableCell align="left">People score</TableCell>
                            <TableCell align="left">Planet score</TableCell>
                            <TableCell align="left">Sourcing score</TableCell>
                            <TableCell align="right" style={{paddingRight: "43px"}}>Last completed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {brandData.map((brand) => {
                            const {
                                surveyPeopleScore: people = 0,
                                surveyPlanetScore: planet = 0,
                                surveySourcingScore: sourcing = 0
                            } = brand
                            return (
                                <TableRow
                                    key={brand.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    className="linkHover"
                                    onClick={() => showBrandSurvey(brand)}
                                >
                                    <TableCell component="th" scope="row" style={{paddingLeft: "43px"}}>{brand.name}</TableCell>
                                    <TableCell align="left">{brand.overallScore}</TableCell>
                                    <TableCell align="left">{people}</TableCell>
                                    <TableCell align="left">{planet}</TableCell>
                                    <TableCell align="left">{sourcing}</TableCell>

                                    <TableCell align="right" style={{paddingRight: "43px"}}>{ brand.surveyLastCompleted ? (new Date(brand.surveyLastCompleted)).toLocaleDateString(): ""}</TableCell>
                                </TableRow>
                            )})}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

BrandTable.propTypes = {
    brandData: PropTypes.array.isRequired,
}

export default BrandTable