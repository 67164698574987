export const prodEmissJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "Planet_Supplierghg_Record_1",
                    title: "Does your company monitor, record, or report on the emission of greenhouse gas from the manufacturing of your products?  ",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "Planet_Supplierghg_Efficiency_2",
                    visibleIf: "{Planet_Supplierghg_Record_1} = 1",
                    title: "Are there any renewable energy or energy efficiency practices being used in your supply chain?  ",
                    description: "This question is asking if you know of any practices that promote renewable and/or efficient energy usage by any of your supply chain partners. For example, a supplier whose electricity is powered by solar panels rather than coal.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Supplierghg_Efficiency_file",
                    visibleIf: "{Planet_Supplierghg_Efficiency_2} = 1 and {Planet_Supplierghg_Record_1} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include onboarding documents or emails/attachments from suppliers referencing the use of renewable energy sources or efficient machinery and processes. You will need to show this ",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Supplierghg_Efficiency_desc",
                    visibleIf: "{Planet_Supplierghg_Efficiency_2} = 1 and {Planet_Supplierghg_Efficiency_file} empty",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Planet_Supplierghg_Efficiency_2} = 1 and {Planet_Supplierghg_Efficiency_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Supplierghg_Scope3_3",
                    visibleIf: "{Planet_Supplierghg_Record_1} = 1",
                    title: "\nHave you ever calculated your Scope 3 emissions? ",
                    description: "Scope 3 emissions are indirect greenhouse gas emissions from your company's supply chain, from sources that you do not own or control. Sources could include extracting the raw materials used in your products (e.g. cotton farming), spinning yarns, dyeing fabrics, ginning fibres, transportation of products, etc. Scope 3 also includes the end-of-life of your products. Most of your brand's GHG emissions will likely be scope 3 emissions. ",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Supplierghg_Scope3_file",
                    visibleIf: "{Planet_Supplierghg_Scope3_3} = 1 and {Planet_Supplierghg_Record_1} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    isRequired: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "text",
                    name: "Planet_Supplierghg_Scope3_num",
                    visibleIf: "{Planet_Supplierghg_Scope3_3} = 1 and {Planet_Supplierghg_Record_1} = 1",
                    indent: 3,
                    title: "Please provide the value in metric tonnes of carbon dioxide equivalent - CO2e",
                    hideNumber: true,
                    isRequired: true,
                    inputType: "number"
                },
                {
                    type: "text",
                    name: "Planet_Supplierghg_Scope3_year",
                    visibleIf: "{Planet_Supplierghg_Scope3_3} = 1",
                    indent: 3,
                    title: "When was this calculated?",
                    hideNumber: true,
                    isRequired: true,
                    inputType: "number"
                },
                {
                    type: "comment",
                    name: "Planet_Supplierghg_Scope3_desc",
                    visibleIf: "{Planet_Supplierghg_Efficiency_2} = 1 and {Planet_Supplierghg_Record_1} = 1",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Planet_Supplierghg_Efficiency_2} = 1 and {Planet_Supplierghg_Record_1} = 1 and {Planet_Supplierghg_Efficiency_file} empty"
                },
                {
                    type: "comment",
                    name: "Planet_Supplierghg_Other",
                    visibleIf: "{Planet_Supplierghg_Record_1} = 1",
                    title: "Is there anything else that you would like to share about GHG emissions from production?",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on the greenhouse gases emitted by the suppliers who manufacture the products you sell.  "
        }
    ],
    showCompletedPage: false
}