import AuthState from '../../authStates';
import create from "zustand";
import roles from '../../userTypes';

// store for sections state
export const useSectionStore = create((set) => ({
    companyCompleted: false,
    setCompanyCompleted: (input) => set(() => ({ companyCompleted: input})),

    peopleCompleted: false,
    setPeopleCompleted: (input) => set(() => ({peopleCompleted: input})),

    planetCompleted: false,
    setPlanetCompleted: (input) => set(() => ({planetCompleted: input})),

    sourcingCompleted: false,
    setSourcingCompleted: (input) => set(() => ({sourcingCompleted: input})),

    yourGoalsCompleted: false,
    setYourGoalsCompleted: (input) => set(() => ({yourGoalsCompleted: input})),
}));

export const usePeopleStore = create((set) => ({
    peoplePoliciesCompleted: false,
    setPeoplePoliciesCompleted: (input) => set(() => ({ peoplePoliciesCompleted: input })),

    peopleAssessmentsCompleted: false,
    setPeopleAssessmentsCompleted: (input) => set(() => ({ peopleAssessmentsCompleted: input })),

    peopleCommunityCompleted: false,
    setPeopleCommunityCompleted: (input) => set(() => ({ peopleCommunityCompleted: input })),

    peopleCommunicationCompleted: false,
    setPeopleCommunicationCompleted: (input) => set(() => ({ peopleCommunicationCompleted: input })),

    peopleCompanyCompleted: false,
    setPeopleCompanyCompleted: (input) => set(() => ({ peopleCompanyCompleted: input })),
}));

export const usePlanetStore = create((set) => ({
    planetEvironmentalCompleted: false,
    setPlanetEnvironmentalCompleted: (input) => set(() => ({ planetEnvironmentalCompleted: input })),

    planetCompanyEmissionsCompleted: false,
    setPlanetCompanyEmissionsCompleted: (input) => set(() => ({ planetCompanyEmissionsCompleted: input })),

    planetProductionEmissionsCompleted: false,
    setPlanetProductionEmissionsCompleted: (input) => set(() => ({ planetProductionEmissionsCompleted: input })),

    planetLogisticsEmissionsCompleted: false,
    setPlanetLogisticsEmissionsCompleted: (input) => set(() => ({ planetLogisticsEmissionsCompleted: input })),

    planetWaterManagementCompleted: false,
    setPlanetWaterManagementCompleted: (input) => set(() => ({ planetWaterManagementCompleted: input })),

    planetChemicalManagementCompleted: false,
    setPlanetChemicalManagementCompleted: (input) => set(() => ({ planetChemicalManagementCompleted: input })),

    planetWasteManagementCompleted: false,
    setPlanetWasteManagementCompleted: (input) => set(() => ({ planetWasteManagementCompleted: input })),

    planetCircularityCompleted: false,
    setPlanetCircularityCompleted: (input) => set(() => ({ planetCircularityCompleted: input })),

    planetBiodiversityCompleted: false,
    setPlanetBiodiversityCompleted: (input) => set(() => ({ planetBiodiversityCompleted: input })),
}));

export const useSourcingStore = create((set) => ({
    sourcingPracticesCompleted: false,
    setSourcingPracticesCompleted: (input) => set(() => ({ sourcingPracticesCompleted: input })),

    sourcingTransparencyCompleted: false,
    setSourcingTransparencyCompleted: (input) => set(() => ({ sourcingTransparencyCompleted: input })),

    sourcingMaterialsCompleted: false,
    setSourcingMaterialsCompleted: (input) => set(() => ({ sourcingMaterialsCompleted: input })),

    sourcingAnimalWelfareCompleted: false,
    setSourcingAnimalWelfareCompleted: (input) => set(() => ({ sourcingAnimalWelfareCompleted: input })),

    sourcingJewelleryCompleted: false,
    setSourcingJewelleryCompleted: (input) => set(() => ({ sourcingJewelleryCompleted: input })),
}));

export const useSurveyStore = create(() => ({
}));

export const useUserStore = create((set) => ({
    userType: roles.Brand,
    setUserType: (input) => set(() => ({userType: input})),

    user: null,
    setUser: (input) => set(() => ({user: input})),

    authState: AuthState.SignedOut,
    setAuthState: (input) => set(() => ({authState: input})),

    userBrand: null,
    setUserBrand: (input) => set(() => ({userBrand: input})),

}))