import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    dashboardSection: {
        display: "flex",
        flexDirection: "column",
        background: "#FFFFFF",
        border: "1px solid #EAEDF3",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
        borderRadius: "4px"
    },
    mainContainer: {
        "&&": {
            border: '1px solid #EAEDF3',
            boxSizing: 'border-box',
            borderRadius: '12px',
            padding: '30px 52px',
            background: '#FFFFFF'
        }
    },
    /* Boxshadow of card */
    paperShadow: {
        "&&": {
            boxShadow:' 0px 3px 3px -2px rgb(0 0 0 / 0%), 0px 3px 4px 0px rgb(0 0 0 / 0%), 0px 1px 8px 0px rgb(0 0 0 / 2%)',
            background: 'none'
        }
    },
    // home page section buttons
    buttonSection: {
        "&&": {
            background: '#FFFFFF',
            width: '556px',
            height: '120px',
            border: '1px solid #DDDDE1',
            borderLeft: '8px solid #706CEC',
            borderRadius: '10px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#FFFFFF',
                cursor: 'pointer',
                boxShadow: '0px 4px 16px 0px #0000001A'
            }
        }
    },
  
    // button/link
    buttonText: {
        "&&": {
            textTransform: 'none',
            textDecoration: 'underline',
            padding: "0px",
            fontSize: '13px',
            fontWeight: '500',
            color: '#5155F8',
            '&:hover': {
                background: 'none'
            }
        }
    },

    // purple button
    button: {
        "&&": {
            textTransform: 'none',
            width: "100%",
            background: '#5155F8',
            fontSize: '16px',
            fontWeight: '600',
            radius: "4px"
        }
    },
    panel: {
        "&&": {
            background: '#FFFFFF',
            border: 'none',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
                background: 'none',
                backgroundColor: 'none',
                margin: '22px 0px'
            },
            "& .Mui-selected": {
                color: "#5155F8 !important", /* changes section text color when selected */
                fontWeight: "700"
            },
            "& .Mui-selected:hover": {
                color: "#5155F8", /* changes selected sections color on hover */
                fontWeight: "700",
                backgroundColor: '#F7F0FF'
            },
            "& .MuiListItem-root": {
                color: '#1A2259', /* section text color unselected */
                "&:hover": {
                    background: '#F7F0FF'
                }
            },
            "& .MuiListItem-gutters": {
                paddingLeft: '16px',
                paddingRight: '16px',
                background: '#F8F9FA',
                margin: '22px 0px',
                borderRadius: '10px',
                height: '64px',
                width: '256px'      
            },
            "& .MuiListItemIcon-root": {
                minWidth: "auto"
            }
        }
    },
    accordianPanel: {
        "&&": {
            background: '#FFFFFF',
            border: 'none',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
                background: 'none',
                backgroundColor: 'none',
                margin: '22px 0px'
            },
            '&:before': {
                display: 'none',
                background: 'none',
                backgroundColor: 'none'
            },
            '&$expanded': {
                margin: 'auto',
                background: 'none',
                backgroundColor: 'none'
            },
            "& .Mui-selected": {
                color: "#5155F8 !important",
                fontWeight: "700 !important",
                textDecoration: 'underline'
            },
            "& .Mui-selected:hover": {
                color: "#5155F8",
                fontWeight: "700",
                backgroundColor: '#F7F0FF',
            },
            "& .MuiAccordionSummary-root": {
                padding: '0px 16px',
                background: '#F8F9FA',
                borderRadius: '10px',
                height: '64px',
                width: '256px',
                "&:hover": {
                    background: '#F7F0FF'
                }
            },
            "& .MuiAccordionSummary-root.Mui-selected": {
                color: '#5155F8 !important'
            },
            "& .MuiListItem-gutters": {
                paddingLeft: '16px',
                paddingRight: '16px',
                background: '#FFFFFF',
                height: 'auto',
                width: 'auto',
                margin: '0px',
                "&:hover": {
                    background: '#FFFFFF'
                }
            },
            "& .MuiListItem-root": {
                color: '#838791', /* section text color unselected */
                // fontWeight: '400',
            },
            "& .MuiListItemText-primary": {
                fontSize: '14px',
                "&:hover": {
                    background: '#FFFFFF',
                    textDecoration: 'underline'
                }
            },
            "& .MuiListItemIcon-root": {
                minWidth: "auto"
            },
            "& .MuiAccordionDetails-root": {
                padding: '8px 0px 16px'    /* for the ticks inline with arrows */
            },
            "& .MuiAccordionSummary-content": {
                alignItems: 'center'
            }
        }
    },
    up: {
        transform: 'rotate(180deg)'
    },
    buttonTopNav: {
        "&&": {
            color: '#606060',
            fontSize: '14px',
            fontWeight: '600',
            margin: '0px 0px 0px 40px',
            textTransform: 'none',
            borderRadius: '0px',
            boxSizing: 'border-box',
            height: '72px',
            "&..MuiButton-text": {
                padding: '6px 0px'
            },
            "&.active": {
                color: '#5155F8',
                borderBottom: '4px solid #5155F8',
                borderTop: '4px solid #FFFFFF'
            },
            "&:hover": {
                backgroundColor: '#FFFFFF',
                borderBottom: '4px solid #5155F8',
                borderTop: '4px solid #FFFFFF'    }
        }
    },
    profileButton: {
        "&&": {
            textTransform: "none",
            minWidth: "174px",
            maxWidth: "215px",
            height: "48px",
            backgroundColor: '#F4F6F8',
            borderRadius: "29px",
            boxShadow: 'none',
            "&:hover": {
                backgroundColor: '#F4F6F8',
                boxShadow: 'none'
            }
        }
    },
    profileMenuList: {
        "&&": {
            "& .MuiList-padding": {
                padding: '0px',
                border: '1px solid #D9DADD',
                boxSizing: 'border-box',
                boxShadow: '0px 6px 20px -2px rgba(26, 26, 26, 0.14), 0px 4px 6px rgba(26, 26, 26, 0.06)',
                borderRadius: '6px',
            }
        }
    },
    disableLink: {
        "&&" : {
            pointerEvents: 'none !important'
        }
    },
    profileButtonMenuItem: {
        "&&": {
            borderTop: "1px solid #E6E6E6",
            "&:hover": {
                background: '#EEF3FF'
            }
        }
    },
    overScroll: {
        overflowY: "scroll"
    },
    formControl: {
        minWidth: 187,
    },
    surveySectionTitle: {
        "&&": {
            fontFamily: 'Syne',
            fontSize: '32px',
            fontWeight: '600',
            color: '#1A2259'
        }
    },
    errorText: {
        "&&": {
            fontWeight: '500',
            color: '#d82a2a',
            fontSize: '13px'
        }
    },
    H600: {
        "&&": {
            fontSize: '32px',
            fontWeight: '600',
            textAlign: 'center'
        }
    },
    H400: {
        "&&": {
            fontSize: '18px',
            fontWeight: '600',
            textAlign: 'left'
        }
    },
    H300: {
        "&&": {
            fontSize: '16px',
            fontWeight: '600',
            textAlign: 'left'
        }
    },
    H200: {
        "&&": {
            fontSize: '14px',
            fontWeight: '600',
        }
    },
    H100: {
        "&&": {
            fontSize: '12px',
            fontWeight: '600',
            color: '#838791'
        }
    },
    bodyLarge: {
        "&&": {
            fontSize: '16px',
            fontWeight: '400'
        }
    },
    bodyMedium: {
        "&&": {
            fontSize: '14px',
            fontWeight: '500',
            textAlign: 'left'
        }
    }
});

export const theme = createTheme({
    typography: {
        fontFamily: ['Inter', 'sans-serif'].join(','),

        h3: {
            fontSize: '20px',
            fontFamily: 'syne',
            fontWeight: '500',
            letterSpacing: '0.5px',
            lineHeight: '1.167'
        },
        // h4 for survey nav text
        h4: {
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '22px'
        },
        h5: {
            fontWeight: '300',
            fontSize: '22px'
        },
        // caption -> input box labels
        caption: {
            fontWeight: '600',
            textAlign: 'left',
            color: '#69828e',
            fontSize: '13px',
            lineHeight: '1.66',
            letterSpacing: '0.2px'
        },
        subtitle1: {
            fontSize: '14px',
            fontWeight: '500',
            textAlign: 'center'
        },
        // for survey nav 'incomplete' text
        subtitle2: {
            fontSize: '12px',
            fontWeight: '600'
        },
    },
    palette: {
        primary: { 
            main: '#706CEC'
        },
        secondary: {
            main: '#706CEC'
        },
        success: {
            main: '#6FCF97'
        },
        white: {
            main: '#FFFFFF'
        },
        offBlack: {
            main: '#1A2259'
        },
        grey: {
            main: '#838791',
            light: '#F8F9FA'
        }
    }
});

export const font2Theme = createTheme({
    typography: {
        fontFamily: ['Syne', 'sans-serif'].join(','),
    }
});