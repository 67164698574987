import React from 'react'
import { Box, Typography, TableContainer, TableHead, TableRow, TableBody, TableCell, Table } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/transTrace.jpeg';

const TransTrace = () => {
    const refs = [
        "The United Nations Economic and Social Council (ECOSOC). Project brochure Enhancing Traceability and Transparency of Sustainable Value Chains (2018).",
        "The United Nations Economic and Social Council (ECOSOC). Project brochure Enhancing Traceability and Transparency of Sustainable Value Chains (2018).",
        "Fashion Revolution.The Fashion Transparency Index (2021). https://www.fashionrevolution.org/about/transparency/",
        "The United Nations Economic and Social Council (ECOSOC) Project brochure Enhancing Traceability and Transparency of Sustainable Value Chains (2018) https://unece.org/trade/traceability-sustainable-garment-and-footwear",
        "Batement and Boanni. Harvard Business Review (2019). https://hbr.org/2019/08/what-supply-chain-transparency-really-means",
        " Kraft, T., Valdés, L. and Zheng, Y., Supply chain visibility and social responsibility: Investigating consumers’ behaviors and motives (2018). Manufacturing & Service Operations Management, 20(4), pp.617-636.",
        "Fashions Next Trend: Accelerating Supply Chain Transparency in the Apparel and Footwear Industry. Human Rights Watch (2019). https://www.hrw.org/report/2019/12/18/fashions-next-trend/accelerating-supply-chain-transparency-apparel-and-footwear"
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title="Transparency & Traceability" isMain={true}/>
            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>
                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>
                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>These two terms are often grouped together but have slightly different meanings. Firstly let’s define them:</Typography>
                        <ul>
                            <li style={{margin: "10px 0"}}><span style={{fontWeight: "600"}}>Traceability</span> is defined as “the ability to trace the history, application or location of an object” through a supply chain<sup>1</sup>. This is about gaining visibility into how, where and by whom items are produced, and being able to see those items as they move through different stages of production.</li>
                            <li style={{margin: "10px 0"}}><span style={{fontWeight: "600"}}>Transparency</span> is defined as “relevant information being made available for all elements of the supply chain in a harmonised way to allow common understanding, clarity and comparison”<sup>2</sup>. This essentially means how much information is known about different stages of production, who that information is made available to, and to what extent.</li>
                        </ul>
                    </Box>
                </Box>
                <Typography style={{fontSize: "1.1rem"}}>
          Both concepts are central to ensuring the reliability of sustainability claims made by fashion brands. 
                    <br/><br/>
          A single supply chain turning raw material (e.g. cotton) into a finished product (e.g. a T-shirt) might include production facilities across the world. This makes it difficult to gain accurate information about the impacts producing that item have had on people in the supply chain and the environment.
                    <br/><br/>
          Whilst there has been an increase in brands disclosing (i.e. making public) information about their sustainability policies and commitments, many have been unable to or simply avoided publishing more concrete supply chain information about where and how items are produced.
                    <br/><br/>
          What kind of supply chain information are we talking about here? This could include any information about where, how and by whom items are produced - including but not limited to the wages garment workers are paid and their working conditions, purchasing practices, production and waste volumes, links to pollution and carbon emissions from manufacturing.<sup>3</sup>
                </Typography>
                <ResourceTitle title="Why is this important?" isMain={false} />
                <Typography style={{fontSize: "1.1rem"}}>
          Transparency and traceability are often linked to the word visibility. 
                    <br /><br />
          Understanding and mapping a supply chain (traceability) is not quick or easy, however speaking about how and where you produce publicly (transparency) has numerous benefits.<sup>4</sup>
                </Typography>
                <ul>
                    <li style={{margin: "10px 0"}}>It can help you <span style={{fontWeight: "600"}}>identify and prevent risks</span> - these could relate to human rights, the environment or health and safety.</li>
                    <li style={{margin: "10px 0"}}>It can help you ensure you <span style={{fontWeight: "600"}}>conform with relevant laws and regulations.</span></li>
                    <li style={{margin: "10px 0"}}>It can help you <span style={{fontWeight: "600"}}>understand</span> how your production requirements, policies and codes of conduct are being met and ensure product quality.</li>
                    <li style={{margin: "10px 0"}}>It can help you <span style={{fontWeight: "600"}}>enhance consumer perception</span> in your brand as trustworthy.</li>
                    <li style={{margin: "10px 0"}}>It can help consumers <span style={{fontWeight: "600"}}>make more informed decisions</span> as they have more reliable information on the sustainability claims you make</li>
                </ul>
                <ResourceTitle title="What does good look like?" isMain={false} />
                <Typography style={{fontSize: "1.1rem"}}>
          You can see what best in class looks like according to transparency campaign group Fashion Revolution <a href="https://www.fashionrevolution.org/about/transparency/" target="_blank" rel="noopener noreferrer">here</a>.
                    <br/><br/>
          Two examples of fashion brands showcasing raw material processing facilities, fabric processing mills and factories throughout its supply chains are Alinge and Baukjen. These are both smaller brands but are both leaders in supply chain traceability and transparency.  
                </Typography>

                <ResourceTitle title="Potential next steps" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>Below are some suggestions for possible steps you can take to gain and communicate greater supply chain visibility.</Typography>

                <Typography style={{
                    fontSize: "1.2rem",
                    textDecoration: "underline",
                    fontWeight: "600",
                    margin: "20px 0px"
                }}>Traceability</Typography>

                <Typography style={{fontSize: "1.1rem"}}><span style={{fontWeight: "600"}}>
          Just getting started?</span> The beginning of your traceability journey will likely start with communicating and engaging with your supply chain partners in order to better visualise and understand your supply chains<sup>5</sup>. The aim is to build a map of the production flows and suppliers involved at different stages of manufacturing. 
                </Typography>
        
                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>How to map your supply chain</Typography>
        
                <TableContainer style={{maxHeight: '100%', margin: "20px 0"}}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow style={{fontWeight: "500"}}>
                                <TableCell align="left">Step 1: Speak to your Tier 1 Suppliers</TableCell>
                                <TableCell align="left">Step 2: Beyond your Tier 1 Suppliers</TableCell>
                                <TableCell align="left">Step 3: Mapping</TableCell>
                                <TableCell align="left">Step 4: Layering data to identify risks and build resilience</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                Invite your Tier 1 (direct suppliers) to participate in the mapping process. 
                                    <br/>
                Send out a supplier assessment covering essential criteria. This could include: 
                                    <br/>
                                    <ol>
                                        <li>Who are you?</li>
                                        <li>Where are you?</li>
                                        <li>What do you sell?</li>
                                        <li>Who are your own suppliers?</li>
                                    </ol>          
                                </TableCell>
                                <TableCell align="left">
                Once a good line of communication is established with your Tier 1 suppliers, look beyond to your Tier 2 and Tier 3 suppliers. 
                                    <br />
                Ask your tier 1 supplier to send the same basic assessment to their tier 2 suppliers. In turn, ask them what information they can provide on their own suppliers. This will help you start to see how different facilities are connected.
                                </TableCell>
                                <TableCell align="left">
              As you learn new information in the discovery phase, fill out a map. 
                                    <br />
              There are numerous ways this could be built out, such as inputting information into a database, using Excel to manually create linkages between suppliers, or using something more visual like a Miro board. There are also companies that provide specialised supply chain mapping software.
                                </TableCell>
                                <TableCell align="left">
                Now you have a map you can update and amend when necessary. 
                                    <br />
                You can better identify risks - e.g. a factory that has been fined for environmental non-compliance, such as polluting a local river.
                                    <br />
                Look for third-party information on potential risks too.
                                    <br />
                Adding more data and learning about your risks means you can build resilience against future shocks. 
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography style={{fontSize: "1.1rem"}}>
        Once you’ve started mapping out how items are moving through your supply chain at different stages, you can begin to collect information on the processes at different stages of production. This will likely involve a lot of dialogue with your suppliers - who is doing what? Where are they based? Have your suppliers ever visited their suppliers? How do your suppliers track and verify products as they move through the supply chain? What information can they provide to you?
                    <br /><br />
        The aim of doing such a mapping exercise is to gain insight. Have you found out some more information about your supply chain which you weren’t expecting? It is only with greater visibility into the supply chain that you can understand how issues around labour, the environment or unclear product origins relate to the items you sell.
                </Typography>

                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Already done those suggestions?</Typography>

                <ul>
                    <li style={{margin: "10px 0"}}>Explore traceability and transparency data sharing platforms. For example, the business membership organisation, <a href="https://www.sedex.com/" target="_blank" rel="noopener noreferrer">Sedex</a>, has an <a href="https://www.sedex.com/our-services/sedex-advance/" target="_blank" rel="noopener noreferrer">ethical data exchange platform</a> that is one of the largest collaborative platforms in the world. It allows buyers, suppliers and auditors to store, share and report on supply chain information quickly and easily.</li>
                    <li style={{margin: "10px 0"}}>Consider production processes which are certified by third-parties. Some of these offer a type of verification called chain of custody which allows items to be traced through supply chains. There are a number of different certifications verified by third parties which afford a high degree of traceability, from <a href="https://textileexchange.org/standards/" target="_blank" rel="noopener noreferrer">materials</a> to <a href="https://www.bluesign.com/en" target="_blank" rel="noopener noreferrer">chemicals</a>.</li>
                </ul>

                <Typography style={{
                    fontSize: "1.2rem",
                    textDecoration: "underline",
                    fontWeight: "600",
                    margin: "20px 0px"
                }}>Transparency</Typography>

                <Typography style={{fontSize: "1.1rem"}}><span style={{fontWeight: "600"}}>
        Just getting started?</span>
                </Typography>

                <ul>
                    <li style={{margin: "10px 0"}}>If up until now you haven’t shared any information publicly about your production processes, think about what information you have, what information you would like to disclose, and how.</li>
                    <li style={{margin: "10px 0"}}>If you are in the early stages of your sustainability journey, this may look like publishing basic sourcing details such as where you produce and the year a supplier began producing for you. Recent research has found that consumers are willing to pay more for products from companies that provide greater supply chain transparency<sup>6</sup>. Talking about where the items you sell come from creates greater trust between your brand and your consumers.</li>
                </ul>

                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Already done those suggestions?</Typography>

                <ul>
                    <li style={{margin: "10px 0"}}>Join the <a href="https://transparencypledge.org/" target="_blank" rel="noopener noreferrer">
          Apparel and Footwear Transparency Pledge</a>. This pledge was developed by multiple human rights and labour rights organisations. It calls on fashion brands committed to transparency to publish their supplier details on their websites and any other communication platforms. The pledge includes:
                    <ol>
                        <li>The full name of all authorised production units and processing facilities</li>
                        <li>The site addresses</li>
                        <li>The parent company of the business site</li>
                        <li>Types of products made</li>
                        <li>Worker numbers at each site</li>
                    </ol>
                    </li>
                    <li style={{margin: "10px 0"}}>A step up from publishing your supplier details on your website is to join the <a href="https://info.openapparel.org/" target="_blank" rel="noopener noreferrer">Open Apparel Registry Website (OAR)</a>. The OAR is a free open source, interactive map of garment facilities across the world. It shows which organisations and companies are affiliated with each facility. This is a gamechanger in increasing transparency in the fashion industry. As of summer 2022 there are more than 97,000 production facilities listed worldwide.</li>
                    <li style={{margin: "10px 0"}}>Once you start collating supplier information, a next step could be to publish more in-depth information on your supply chains. This could include information on the percentage of migrant workers in a factory’s workforce, the presence of unions, or the types of energy sources a factory uses<sup>7</sup>.</li>
                    <li style={{margin: "10px 0"}}>The ultimate end goal for supply chain visibility is to trace and disclose information on all stages of production - where raw materials come from, how yarns are spun, fabrics are dyed and fabrics produced. This of course is not an easy task and involves a long-term sustainability dialogue that prioritises both traceability and transparency to achieve.</li>
                </ul>

            </Box>
            <Box>
                <ResourcesReferences references={refs} />
            </Box>
        </Box>
    )
}

export default TransTrace