import React from 'react'
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const ResourcesTitle = ({title, isMain, refs}) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" bgcolor="#1A2259" position="relative" p={isMain ? "20px 50px" : "12px 30px"} m="50px auto"  style={{
            color: "white",
            minWidth: "400px"
        }}>
            {
                isMain &&
        <Box style={{
            position: "absolute",
            top: "-20px",
            left: "5%",
            height: "40px",
            padding: "5px 20px",
            background: "#5556E9",
            borderRadius: "20px",
            zIndex: "2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Typography style={{
                fontFamily: "Syne",
                fontWeight: "700"
            }}>RESOURCES 101</Typography>
        </Box>
            }
            <Typography style={{
                fontSize: isMain ? "36px" : "24px",
                fontFamily: "Syne",
                fontWeight: "600"
            }}>{title}<sup>{refs}</sup></Typography>
        </Box>
    )
}

ResourcesTitle.propTypes = {
    title: PropTypes.string.isRequired,
    isMain: PropTypes.bool.isRequired,
    refs: PropTypes.string.isRequired
}

export default ResourcesTitle