import React from "react";
import { Page } from "../../UI/templates/Page";
import { TextField, Box, Button, Paper, Typography, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import { Visibility, VisibilityOff} from '@material-ui/icons';
import AuthState from '../../authStates';
import { useState } from "react";
import { useUserStore } from '../../assets/state';
import { useStyles } from '../../UI/styles/themes'
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const ResetPassword = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const setAuthState = useUserStore((state) => state.setAuthState);
    // useState for password
    const [values, setValues] = useState({
        amount: '',
        old_password: '',
        new_password: '',
        email: '',
        showOldPassword: false,
        showNewPassword: false,
        weight: '',
        weightRange: '',
    });
    const history = useHistory();

    // Yup schema for react hook form validation
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('E-mail address is required'),
        oldPassword: Yup.string()
            .required('Old password is required'),
        newPassword: Yup.string()
            .required('New password is required')
            .min(8, 'Password must be at least 8 characters')
            .max(40, 'Password must not exceed 40 characters')
            .matches(
                /(?=.*?[A-Z])/,
                "Must contain an uppercase character"
            )
            .matches(
                /(?=.*?[0-9])/,
                "Must contain a digit"
            )
            .matches(
                /(?=.*?[#?!@$%^&*-])/,
                "Must contain a special character"
            )
            .matches(
                /(?=.*?[a-z])/,
                "Must contain a lowercase character"
            ),
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({resolver: yupResolver(validationSchema)});

    async function handleResetPassword(data) {
        Auth.signIn(data.email, data.oldPassword)
            .then(user => {
                return Auth.changePassword(user, data.oldPassword, data.newPassword);
            })
            .then(() => {
                setAuthState(AuthState.SignedOut);
                // console.warn(data);
                history.push('/home');
            })
            .catch(err => {
                setErrorMessage(err.message);
                // console.error(err);
            });
    }

    // Functions for toggling password ----
    // const handleChange = (prop) => (event) => {
    //   setValues({ ...values, [prop]: event.target.value });
    // };

    const handleClickShowOldPassword = () => {
        setValues({ ...values, showOldPassword: !values.showOldPassword });
    };

    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //  -----------------


    // allow use of customer styles/classes from themes.jsx
    const classes = useStyles();

    return (
        <Page>
            <Box display='flex' flexDirection='column'>
                <Paper
                    elevation={3}
                    className={classes.paperShadow}
                    height="100%"
                >
                    <Box className={classes.mainContainer} width={400}>
                        <Box
                            textAlign="center"
                            color="#1A2259"
                            mb='16px'
                        >
                            <Typography variant="h3">Reset Password</Typography>
                        </Box>
                        <Box height="24px" mb='12px' textAlign='center'>
                            {
                                errorMessage && (
                                    <Typography variant="body2" className={classes.errorText}>
                                        {errorMessage}
                                    </Typography>
                                )
                            }
                        </Box>
                        <Box display='flex' flexDirection='column' alignItems='center' width={1}>
                            <form onSubmit={handleSubmit(handleResetPassword)}>
                                <Box width={1}>
                                    <TextField
                                        type="email"
                                        id="oulined-basic"
                                        variant='outlined'
                                        label="Enter e-mail address"
                                        // value={values.email}
                                        {...register('email')}
                                        error={errors.email ? true : false}
                                        // onChange={handleChange('email')}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Box>
                                <Box width={1} height='18px' textAlign='left' mt='2px'>
                                    <Typography variant="body2" className={classes.errorText}>
                                        {errors.email?.message}
                                    </Typography>
                                </Box>
                                <Box mt="16px" width={1}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel margin="dense" htmlFor="outlined-adornment-password">Enter old password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showOldPassword ? 'text' : 'password'}
                                            // value={values.old_password}
                                            {...register('oldPassword')}
                                            error={errors.oldPassword ? true : false}
                                            // onChange={handleChange('old_password')}
                                            fullWidth
                                            margin="dense"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowOldPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                    </FormControl>
                                </Box>
                                <Box width={1} height='18px' textAlign='left' mt='2px'>
                                    <Typography variant="body2" className={classes.errorText}>
                                        {errors.oldPassword?.message}
                                    </Typography>
                                </Box>
                                <Box mt="16px" width={1}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel margin="dense" htmlFor="outlined-adornment-password">Enter new password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showNewPassword ? 'text' : 'password'}
                                            // value={values.new_password}
                                            {...register('newPassword')}
                                            error={errors.code ? true : false}
                                            // onChange={handleChange('new_password')}
                                            fullWidth
                                            margin="dense"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                    </FormControl>
                                </Box>
                                <Box width={1} height='18px' textAlign='left' mt='2px'>
                                    <Typography variant="body2" className={classes.errorText}>
                                        {errors.newPassword?.message}
                                    </Typography>
                                </Box>
                                <Box width={1} mt="20px">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type='submit'
                                        className={classes.button}
                                        size="large"
                                    >
                    Confirm
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Paper>
                <Box mt="40px">
                    <Button
                        color="primary"
                        className={classes.buttonText}
                        onClick={() => {history.push('/home')}}
                    >
                        {`<`} Cancel
                    </Button>
                </Box>
            </Box>
        </Page>
    );
};

export default ResetPassword