import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BrandSignup from '../BrandSignup';
import Signup from '../Signup';
import ExportSurvey from '../ExportSurvey';
import ResetPassword from '../../ResetPassword';
import SurveyPages from '../SurveyPages';
import TopNavBarAdmin from '../../../components/TopNavBarAdmin';
import SurveyResults from '../SurveyResults';
import UserDetails from '../UserDetails';
import { Box } from '@material-ui/core';

const AdminHome = () => {

    return (
        <Box minHeight="100vh" bgcolor="#F4F6FB" display="flex" flexDirection="column">
            <TopNavBarAdmin />
            <Box minHeight="93vh">
                <Switch>
                    <Route path={`/signup/user`}>
                        <Signup />
                    </Route>
                    <Route path={`/signup/brand`}>
                        <BrandSignup />
                    </Route>
                    <Route path={`/export/survey`}>
                        <ExportSurvey />
                    </Route>
                    <Route path={`/resetpassword`}>
                        <ResetPassword />
                    </Route>
                    <Route path={`/surveyresults`}>
                        <SurveyResults />
                    </Route>
                    <Route path={`/users`}>
                        <UserDetails />
                    </Route>
                    <Route path={`/survey`}>
                        <SurveyPages />
                    </Route>
                </Switch>
            </Box>
        </Box>
    )
}

export default AdminHome
