import React from 'react';
import { Box, ListItem, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const ResourcesNavPanel = (props) => {
    const { title, url } = props;
    const location = useLocation();
    const pathName = location.pathname;
    const history = useHistory();

    return (
        <ListItem
            button
            disableRipple='false'
            selected={pathName === url}
            onClick={() => {
                history.push(url)
            }}
        >
            <Box
                ml="16px"
                width={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <Typography style={{
                    fontWeight: "600",
                    fontSize: "15px"
                }}
                >{title}</Typography>
            </Box>
        </ListItem>
    )
}

ResourcesNavPanel.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}

export default ResourcesNavPanel