import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const CurrentBrandContext = createContext({
    currentBrand: null,
    setCurrentBrand: () => {},
});

const CurrentBrandProvider = (props) => {
    const { children } = props;
    const [currentBrand, setCurrentBrand] = useState(null);    /* current brand stored in global state */

    return (
        <CurrentBrandContext.Provider value={{ currentBrand, setCurrentBrand }}>
            {children}
        </CurrentBrandContext.Provider>
    );
};

CurrentBrandProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export { CurrentBrandProvider, CurrentBrandContext };