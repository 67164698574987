import React, { useState, useEffect } from 'react';
import { Page } from "../../../UI/templates/Page";
import {
    TextField,
    Box,
    Button,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { Auth, API } from 'aws-amplify';
import { useStyles } from '../../../UI/styles/themes';
import { useHistory } from 'react-router-dom';
import generator from 'generate-password';

const Signup = () => {
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [password, setPassword] = useState(''); /* Hardcoded for development (DELETE) */
    const [userType, setUserType] = useState("");
    const [accountName, setAccountName] = useState(" ");   /* default value? */
    const [brand, setBrand] = useState({});   /* default value? */
    const [brands, setBrands] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const history = useHistory();

    const signUp = async () => {
        try {
            await Auth.signUp({
                username : email,
                password,
                attributes: {
                    email  ,        // optional
                    'custom:brandCode': brand.brandCode,
                    'custom:temporaryPassword': password,
                    name: fullName
                }
            })
                .then(() => {
                    alert('sign up successful');
                    history.push('/');
                })
        }
        catch (error) {
            setErrorMessage(error.message);
            // console.error('error signing up:', error);
        }
    }

    const getBrands = async () => {
        const tokens = await Auth.currentSession();
        const token = tokens.idToken.jwtToken;

        const payLoad = {
            brandCode : null  // null to get All brands
        };

        const myInit = {
            headers: {
                Authorization : token,
            },
            response: true,
            queryStringParameters : payLoad
        };
        try {
            const data = await API.get('surveyapi', '/brands', myInit)
            setBrands(data);
        }catch(err) {
            console.error(err);
            alert('An error ocurred');
        }
    }

    // get the list of brands with their name and brand codes
    useEffect(() => {
        getBrands();
        const generatedStr = generator.generate({
            length: 25,
            numbers: true,
            symbols: true
        });
        setPassword(generatedStr);
    }, [])

    const classes = useStyles();

    return (
        <Page>
            <Paper
                elevation={3}
                className={classes.paperShadow}
            >
                <Box className={classes.mainContainer} width={400}>
                    <Box
                        textAlign="center"
                        color="#1A2259"
                        mb='5px'
                    >
                        <Typography variant="h3">User sign up</Typography>
                    </Box>
                    <Box alignSelf="center" height="24px">
                        {
                            errorMessage && (
                                <Typography variant="body2" className={classes.errorText}>
                                    {errorMessage}
                                </Typography>
                            )
                        }
                    </Box>
                    <Box display='flex' flexDirection='column' alignItems='center' width={1} >
                        <Box width={1}>
                            {/* Full Name */}
                            <TextField
                                id="standard-required"
                                required
                                label="Full name"
                                value={fullName}
                                onChange={(event) => setFullName(event.target.value)}
                                helperText={errorMessage.fullName && "required"}
                                fullWidth
                                margin="dense"
                                variant='outlined'
                            />
                        </Box>

                        {/* Email */}
                        <Box py={1} width={1} mt="10px">
                            <TextField
                                id="standard-required"
                                required
                                label="Email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                fullWidth
                                margin="dense"
                                variant='outlined'
                            />
                        </Box>

                        {/* User type */}
                        <Box width={1} mt="10px">
                            <FormControl
                                required
                                className={classes.formControl}
                                fullWidth
                                margin="dense"
                                variant='outlined'
                            >
                                <InputLabel id="standard-required-select-label">User type</InputLabel>
                                <Select
                                    labelId="standard-required-select-label"
                                    id="standard-required-select"
                                    label="User type"
                                    value={userType}
                                    onChange={(event) => setUserType(event.target.value)}
                                >
                                    <MenuItem value={'admin'}>Admin</MenuItem>
                                    <MenuItem value={'account'}>Account</MenuItem>
                                    <MenuItem value={'brand'}>Brand</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        {/* Account Name -> conditional rendering*/}
                        {
                            (userType !== 'admin' && userType !== '') &&
              <Box width={1} mt="10px">
                  <FormControl
                      required
                      className={classes.formControl}
                      fullWidth
                      margin="dense"
                      variant='outlined'
                  >
                      <InputLabel id="standard-required-select-label">Account name</InputLabel>
                      <Select
                          labelId="standard-required-select-label"
                          id="standard-required-select"
                          label="Account name"
                          value={accountName}
                          onChange={(event) => setAccountName(event.target.value)}
                      >
                          <MenuItem value={'silkFred'}>SilkFred</MenuItem>
                          <MenuItem value={'general'}>General</MenuItem>
                      </Select>
                  </FormControl>
              </Box>
                        }

                        {
                            userType === 'brand' &&
              <Box py={1} width={1} mt="10px">
                  <FormControl fullWidth variant='outlined' margin='dense'>
                      <InputLabel id="brand-select">Brand</InputLabel>
                      <Select
                          labelId="brand-select-label"
                          id="brand-select"
                          value={brand}   /* not sure if this is correct */
                          label="Brand"
                          onChange={(event) => {setBrand(event.target.value)}}
                      >
                          {
                              brands.map((brand, idx) => {
                                  return (
                                      <MenuItem key={idx} value={brand}>{brand.name}</MenuItem>
                                  )
                              })
                          }
                      </Select>
                  </FormControl>
              </Box>
                        }
                        <Box py={1} width={1} mt="20px">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={signUp}
                                className={classes.button}
                                size="large"
                            >
                Sign Up
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Page>
    )
}

export default Signup
