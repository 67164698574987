export const compDetJSON = {
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    type: "text",
                    name: "Company_name",
                    title: "Your company's legal name",
                    titleLocation: "left",
                    isRequired: true
                },
                {
                    type: "text",
                    name: "Company_nameOther",
                    title: "Your company's trading name (if different)",
                    titleLocation: "left",
                    hideNumber: true
                },
                {
                    type: "radiogroup",
                    name: "Company_status",
                    title: "Your legal status",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Sole trader"
                        },
                        {
                            value: "2",
                            text: "Registered company"
                        },
                        {
                            value: "3",
                            text: "Business partnership"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "companiesHouseNumber",
                    visibleIf: "{Company_status} = 2",
                    indent: 4,
                    title: "Please enter your Companies House number",
                    titleLocation: "left",
                    hideNumber: true,
                    isRequired: true,
                    size: 300
                },
                {
                    type: "text",
                    name: "Company_estYear",
                    title: "Year established ",
                    titleLocation: "left",
                    isRequired: true,
                    validators: [
                        {
                            type: "numeric",
                            text: "RangeCheck",
                            minValue: 1900,
                            maxValue: 2021
                        }
                    ],
                    inputType: "number",
                    size: 300,
                    min: "1970",
                    minErrorText: "Value too Low",
                    maxErrorText: "Value cant be more than current year"
                },
                {
                    type: "text",
                    name: "Company_yourName",
                    title: "Your full name ",
                    titleLocation: "left",
                    isRequired: true
                },
                {
                    type: "text",
                    name: "Company_yourRole",
                    title: "Your role",
                    titleLocation: "left",
                    isRequired: true
                },
                {
                    type: "checkbox",
                    name: "Company_sells",
                    title: "What does your company sell? (Select all that apply)",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Apparel"
                        },
                        {
                            value: "2",
                            text: "Footwear"
                        },
                        {
                            value: "3",
                            text: "Jewelery"
                        },
                        {
                            value: "4",
                            text: "Other"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Company_sells_other",
                    visibleIf: "{Company_sells} = [4]",
                    indent: 3,
                    title: "Please describe",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "text",
                    name: "Company_fullTime",
                    title: "How many people work at your company full-time? ",
                    titleLocation: "left",
                    isRequired: true,
                    inputType: "number",
                    min: "1"
                },
                {
                    type: "text",
                    name: "Company_partTime",
                    title: "7.1 How many people work at your company part-time? ",
                    titleLocation: "left",
                    hideNumber: true,
                    defaultValue: 0,
                    isRequired: true,
                    inputType: "number",
                    min: "0"
                },
                {
                    type: "checkbox",
                    name: "Company_salesChannels",
                    title: "Which sales channels do you use? (Select all that apply)",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Marketplace"
                        },
                        {
                            value: "2",
                            text: "Wholesale"
                        },
                        {
                            value: "3",
                            text: "Direct to consumer (e.g. website, social media)"
                        },
                        {
                            value: "4",
                            text: "Concessions"
                        },
                        {
                            value: "5",
                            text: "Bricks and Mortar"
                        },
                        {
                            value: "6",
                            text: "Other"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Company_salesChannels_other",
                    visibleIf: "{Company_salesChannels} = [6]",
                    indent: 3,
                    title: "Please describe",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "checkbox",
                    name: "Company_salesLocs",
                    title: "Where do you sell your products? (Select all that apply)",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "UK"
                        },
                        {
                            value: "2",
                            text: "Europe"
                        },
                        {
                            value: "3",
                            text: "USA"
                        },
                        {
                            value: "4",
                            text: "Other"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Company_salesLocs_other",
                    visibleIf: "{Company_salesLocs} = [4]",
                    indent: 3,
                    title: "Please describe",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "radiogroup",
                    name: "Company_prodRenew",
                    title: "How frequently (on average) do you renew or update your product ranges?",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Several times each month"
                        },
                        {
                            value: "2",
                            text: "Each month"
                        },
                        {
                            value: "3",
                            text: "Every three months"
                        },
                        {
                            value: "4",
                            text: "Every six months"
                        },
                        {
                            value: "5",
                            text: "Less than once per year"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "Company_susReview",
                    title: "Have you ever completed a sustainability assessment before?",
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        },
                        {
                            value: "3",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Company_erpPlm",
                    title: "If you use a Product Lifecycle Management (PLM) tool (e.g. Centric) or an Enterprise Resource Planning (ERP) tool (e.g. Zedonk) please enter it below"
                },
                {
                    type: "text",
                    name: "Company_ecommSoftware",
                    title: "If you use an e-commerce software (e.g. Shopify) please enter it below\n"
                }
                //  {
                //   type: "radiogroup",
                //   name: "Company_revenue",
                //   title: "In which of these brackets was your annual revenue in the last financial year? ",
                //   choices: [
                //    {
                //     value: "1",
                //     text: "Less than £100k"
                //    },
                //    {
                //     value: "2",
                //     text: "Between £100k - £250k"
                //    },
                //    {
                //     value: "3",
                //     text: "Between £250k - £500k"
                //    },
                //    {
                //     value: "4",
                //     text: "Between £500k - £1m"
                //    },
                //    {
                //     value: "5",
                //     text: "Between £1m - £2.5m"
                //    },
                //    {
                //     value: "6",
                //     text: "Between £2.5m - £5m"
                //    },
                //    {
                //     value: "7",
                //     text: "Greater than £5m"
                //    }
                //   ]
                //  }
            ],
            descriptionEdit: "Before beginning the sustainability assessment we are asking some general questions about your company. This information helps us to contextualise your answers to the assessment."
        }
    ],
    showCompletedPage: false
}