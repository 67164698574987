import { Auth, API, Storage } from 'aws-amplify';

// function to check if subsection is complete
export const checkSubsectionComplete = (section, subsection, surveyData = []) => {
    return surveyData.some(survey => survey.surveyName === `${section}_${subsection}`)
}

export const removeUnderscores = (str) => {
    return str.split('_').join('')
}

// gets the current users brand data and returns an object where: object.data[0] is the brand{}
export const getBrand = async () => {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    const brandCode = session.getIdToken().payload['custom:brandCode'];

    const payLoad = {
        brandCode : brandCode  // specific brand
    };

    const myInit = {
        headers: {
            Authorization : token,
        },
        response: true,
        queryStringParameters : payLoad
    };

    try {
        return await API.get('surveyapi', '/brands', myInit)
    }
    catch(err) {
        console.error(err);
    }
}

export const checkSessionValid = async() => {
    return Auth.currentAuthenticatedUser()
        .then(() => {
            return true
        })
        .catch(() => {
            return false
        })
}

export const eliminateSpaces = (str) => {
    return str.split(' ').join('')
}


// Part of the survey file upload process
export const uploadFile = (file, userBrandCode, fileName) => {
    const newFile = Object.create(null);
    newFile.name = file.name;
    newFile.type=  file.type;
    newFile.S3Loc = `${userBrandCode}/${fileName}`;

    // encode file content
    const base64Data = new Buffer.from(file.content.replace(/^data:\w+\/\w+;base64,/, ""), 'base64');

    // try: upload file to storage bucket
    try {
        Storage.put(newFile.S3Loc,base64Data, {
            level: 'public',
            ContentEncoding: 'base64',
            contentType: file.type // contentType is optional
        });
    } catch (err) {
        console.error('error uploading to S3 bucket', err);
    }

    return newFile
}