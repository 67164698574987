import React from 'react';
import { Box, Button, Paper } from '@material-ui/core';
import { useStyles } from '../../../UI/styles/themes';
import { useHistory } from 'react-router-dom';
import WelcomeSection from './WelcomeSection';
import { ReactComponent as ClockIcon } from '../../../assets/svg/homePage/clock.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/svg/homePage/document.svg';
import { ReactComponent as AskIcon } from '../../../assets/svg/homePage/ask.svg';

const WelcomePage = () => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <Paper
            elevation={3}
            className={classes.paperShadow}
            height="100%"
        >
            <Box
                bgcolor='#FFFFFF'
                height='auto'
                width='680px'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                borderRadius='12px'
                p="46px 41px"
            >
                <WelcomeSection icon={<ClockIcon />} title="⏱️  How long will it take?" text="< 1.5 hours. The survey is split into several sections so you don't have to finish it in one go!" exampleEvidence={false} />

                <WelcomeSection icon={<DocumentIcon />} title="✏️   What will you need?" text="Relevant sustainability-related documents to upload - we use this as evidence to validate your answers. ⚠️ We ask you to provide robust evidence for each question - if you are unable to provide evidence to support an answer it means we won't be able to give you a score for it! ⚠️" exampleEvidence={true}/>

                <WelcomeSection icon={<AskIcon />} title="❓ Any questions?" text="Contact us on " boldText="support@bendi.ai" exampleEvidence={false} />
                <Box mt="10px">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {history.push('/survey/company')}}
                        className={classes.button}
                        style={{
                            height: '56px',
                            width: '266px'
                        }}
                        size="large"
                        disableRipple='false'
                    >
            Start the assessment
                    </Button>
                </Box>
            </Box>
        </Paper>
    )
}

export default WelcomePage
