import React, { useState } from 'react';
import { Button, Box, Typography, Menu, MenuItem } from '@material-ui/core';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/svg/SFIndexLogo.svg';
import { useStyles } from '../../UI/styles/themes';
import { ReactComponent as SurveyIcon } from '../../assets/svg/surveyIcon.svg';
import { ReactComponent as LogOutIcon } from '../../assets/svg/logOutIcon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/svg/profileIcon.svg';
import { ReactComponent as PadlockIcon } from '../../assets/svg/padlock.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { Auth } from 'aws-amplify';
import AuthState from '../../authStates';

import { useUserStore } from '../../assets/state';
import roles from '../../userTypes';

const TopNavBarAdmin = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const user = useUserStore((state) => state.user);
    const classes = useStyles();
    const location = useLocation();
    const pathName = location.pathname;
    const setAuthState = useUserStore((state) => state.setAuthState);
    const setUser = useUserStore((state) => state.setUser);
    const setUserType = useUserStore((state) => state.setUserType);
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // sign out auth function for sign out button
    async function signOut() {
        try {
            await Auth.signOut();
            setAuthState(AuthState.SignedOut);
            history.push('/');
            setUser(null);
            setUserType(roles.Brand);
        } catch (error) {
            // console.error("error signing out: ", error);
        }
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent='space-between'
            borderBottom="1px solid #E2E5ED"
            bgcolor="white.main"
            height='72px'
            width={1}
            px="30px"
        >
            <Box display="flex" alignItems="center">
                <Box mr='20px'>
                    <a href="/" rel="noreferrer">
                        <img src={logo} alt="SilkFred Impact Index" height='22px' />
                    </a>
                </Box>
                <Button
                    className={classes.buttonTopNav}
                    component={NavLink}
                    disableRipple='false'
                    to="/signup/user"
                >
                    <PersonAddRoundedIcon fill={pathName==='/signup/user' ? '#5155F8' : '#838791'} />
          Sign-Up
                </Button>

                <Button
                    className={classes.buttonTopNav}
                    component={NavLink}
                    disableRipple='false'
                    to="/signup/brand"
                >
                    <AddCircleOutlineRoundedIcon fill={pathName==='/signup/brand' ? '#5155F8' : '#838791'} />
          Brand Sign-Up
                </Button>

                <Button
                    className={classes.buttonTopNav}
                    component={NavLink}
                    disableRipple='false'
                    to="/surveyresults"
                >
                    <SurveyIcon fill={pathName.includes('/surveyresults') ? '#5155F8' : '#838791'} />
          Survey Results
                </Button>

                <Button
                    className={classes.buttonTopNav}
                    component={NavLink}
                    disableRipple='false'
                    to="/users"
                >
                    <ProfileIcon fill={pathName.includes('/users') ? '#5155F8' : '#838791'} />
          Users
                </Button>

                <Button
                    className={classes.buttonTopNav}
                    component={NavLink}
                    disableRipple='false'
                    to="/export/survey"
                >
                    <GetAppRoundedIcon fill={pathName.includes('/users') ? '#5155F8' : '#838791'} />
          Export Survey
                </Button>
            </Box>
            <Box alignItems='center' display="flex" justifyContent="flexEnd">
                <Button
                    className={classes.profileButton}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={handleClick}
                    disableRipple='false'
                >
                    <ProfileIcon fill="#BEC0C5"/>
                    <Typography
                        className={classes.H200}
                        style={{marginLeft: '9px', color: '#202532'}}
                    >
                        {user ? user.name : 'YOUR ACCOUNT'}
                    </Typography>
                    <ArrowDropDownIcon className={anchorEl && classes.up} />
                </Button>
                <Menu
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.profileMenuList}
                >
                    <MenuItem disabled='true' style={{
                        width: '174px',
                        opacity: "1"
                    }}>
                        <Box py="4px" width={1}>
                            <Typography className={classes.H100}>YOUR ACCOUNT</Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem
                        className={classes.profileButtonMenuItem}
                        disableRipple='false'
                        onClick={() => history.push('/resetpassword')}>
                        <PadlockIcon />
                        <Typography style={{marginLeft: '13px', padding: '2px 0px'}} className={classes.bodyLarge}>Reset password</Typography>
                    </MenuItem>
                    <MenuItem
                        className={classes.profileButtonMenuItem}
                        disableRipple='false'
                        onClick={signOut}>
                        <LogOutIcon />
                        <Typography style={{marginLeft: '10px', padding: '2px 0px'}} className={classes.bodyLarge}>Logout</Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}

export default TopNavBarAdmin
