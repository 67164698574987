import React from 'react';
import HomePage from '../../../UI/templates/HomePage';
import MultiProgressBar from '../../../components/dashboard/MultiProgressBar';
import Panel from '../../../components/dashboard/Panel';
import BrandTable from '../../../components/dashboard/BrandTable';
import TableCSVExport from '../../../components/dashboard/TableCSVExport';
import { Box, ThemeProvider, Typography } from '@material-ui/core';
import { font2Theme, useStyles } from '../../../UI/styles/themes';
import SectionHeader from '../../../components/dashboard/SectionHeader';
import { ReactComponent as DialIcon } from '../../../assets/svg/dial.svg';
import { ReactComponent as ScoreIcon } from '../../../assets/svg/barCharts.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/svg/surveyBar/peopleIcon.svg';
import { ReactComponent as PlanetIcon } from '../../../assets/svg/surveyBar/planetIcon.svg';
import { ReactComponent as SourcingIcon } from '../../../assets/svg/surveyBar/sourcingIcon.svg';
import { useUserStore } from '../../../assets/state';
import { Auth, API } from 'aws-amplify';
import { useQuery } from 'react-query';

const DEFAULT_SCORES = {
    completed : 0,
    inProgress: 0,
    declined: 0,
    people: {
        average: 0
    },
    planet: {
        average: 0
    },
    sourcing: {
        average: 0
    }
}

const computeScores = (brandData) => {
    if (!brandData)  return DEFAULT_SCORES

    const totalPeople = brandData.reduce((acc, curr) => acc + curr.surveyPeopleScore, 0) ;
    const totalPlanet = brandData.reduce((acc, curr) => acc + curr.surveyPlanetScore, 0);
    const totalSourcing = brandData.reduce((acc, curr) => acc + curr.surveySourcingScore, 0);
    return {
        completed : brandData.filter(d => d.surveyStatus === "Complete").length,
        inProgress: brandData.filter(d => d.surveyStatus === "In progress").length,
        declined: brandData.filter(d => d.surveyStatus === "NONE").length,
        people: {
            average: totalPeople / brandData.length
        },
        planet: {
            average: totalPlanet / brandData.length
        },
        sourcing: {
            average: totalSourcing / brandData.length
        }
    }
}

const Home = () => {
    const classes = useStyles();
    const user = useUserStore((state) => state.user);

    // get brands data
    const {data: brandTableData, isLoading, isError} = useQuery('brands', async () => {
        const tokens = await Auth.currentSession();
        const token = tokens.idToken.jwtToken;

        const payLoad = {
            brandCode : null  // null to get All brands
        };

        const myInit = {
            headers: {
                Authorization : token,
            },
            response: true,
            queryStringParameters : payLoad
        };

        const result = await API.get('surveyapi', '/brands', myInit)
        return result.data
    });

    isError && console.warn(isError);

    const compiledData = computeScores(brandTableData);

    const progressBarInput = [
        {
            name: "Completed",
            score: compiledData.completed,
            colour: '#3855B3'
        },
        {
            name: "In progress",
            score: compiledData.inProgress,
            colour: '#7688C9'
        },
        {
            name: "Declined",
            score: compiledData.declined,
            colour: '#F0F1F2'
        }
    ];

    return (
        <HomePage>
            <Box p="41px 80px">
                <Box display="flex" color="offBlack.main" mb="41px">
                    <ThemeProvider theme={font2Theme}>
                        <Box>
                            <Typography className={classes.H600}>{user && user.name ? `Welcome, ${user.name}!` : 'Welcome'}</Typography>
                        </Box>
                    </ThemeProvider>
                </Box>
                <Box
                    display="grid"
                    gridTemplateRows="1fr auto"
                    gridTemplateColumns="1fr auto"
                    gridGap="47px"
                    width="fit-content"
                    margin="auto"
                >
                    {/* Progress overview section */}
                    <Box
                        className={classes.dashboardSection}
                        height="auto"
                        width="618px"
                    >
                        <SectionHeader title="Progress Overview" subtitle="Based on the 15 SilkFred brands invited" icon={<DialIcon fill="#706CEC" />} />
                        <Box width="100%" display="flex" justifyContent="center" mt="30px">
                            <MultiProgressBar
                                height={14}
                                width={0.8 * 618}
                                input={progressBarInput}
                            />
                        </Box>
                    </Box>

                    {/* average score breakdown */}
                    <Box
                        className={classes.dashboardSection}
                        height="auto"
                        width="602px"
                    >
                        <SectionHeader title="Score breakdown" subtitle={`Based on ${compiledData.completed} completed surveys`} icon={<ScoreIcon fill="#706CEC" />} score={(compiledData.people.average + compiledData.planet.average + compiledData.sourcing.average)/3} />

                        <Panel icon={<PeopleIcon fill="#706CEC" width="60%" height="60%" />} title="Average score" subtitle="People" button={false} score={compiledData.people.average} height="auto" />

                        <Panel icon={<PlanetIcon fill="#706CEC" width="60%" height="60%"/>} title="Average score" subtitle="Planet" button={false} score={compiledData.planet.average} height="auto" />

                        <Panel icon={<SourcingIcon fill="#706CEC" width="60%" height="60%"/>} title="Average score" subtitle={`Sourcing`} button={false} score={compiledData.sourcing.average} height="auto" />
                    </Box>

                    {/* brand scores table */}
                    <Box
                        className={classes.dashboardSection}
                        height="auto"
                        width="100%"
                        style={{
                            gridColumnStart: "1",
                            gridColumnEnd: "3"
                        }}
                    >
                        <Box
                            color="#1A2259"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p="20px 43px"
                            style={{
                                borderBottom: "1px solid #E6E7E9"
                            }}
                        >
                            <p style={{
                                margin: "auto 0",
                                padding: "0"
                            }}>Brand scores</p>

                            <TableCSVExport headers={["Brand", "Overall score", "People score", "Planet score", "Sourcing score", "Last completed"]} data={brandTableData} />
                        </Box>
                        {
                            isLoading ? <p>Loading..</p> : <BrandTable brandData={brandTableData} />
                        }
                    </Box>
                </Box>
            </Box>
        </HomePage>
    )
}

export default Home