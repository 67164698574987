import React from 'react';
import { Box } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PropTypes from 'prop-types';

const SurveySelector = (props) => {
    const {
        setSurveyAnswersSelected,
        surveyData,
        onChangeAction
    } = props;

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const handleSelectionChange = (event, newValue) => {
        onChangeAction()
        setSurveyAnswersSelected(newValue);
    }

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            p='6px 10px 10px 10px'
            mt="10px"
            borderRadius='10px'
        >
            <Autocomplete
                multiple
                id="survey-checkbox-selection"
                options={surveyData}
                disableCloseOnSelect
                noOptionsText="Please select a brand"
                getOptionLabel={(option) => option.surveyName}
                renderOption={(props, option, { selected }) => {
                    let surveyName = option.surveyName.split('_').join(' ');
                    return (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                value={option}
                            />
                            {surveyName}
                        </li>
                    )}
                }
                style={{ width: 500 }}
                renderInput={(params) => (
                    <TextField {...params} label="Surveys" />
                )}
                onChange={(event, newValue) => handleSelectionChange(event, newValue)}
            />
        </Box>
    )
};

SurveySelector.propTypes = {
    setSurveyAnswersSelected: PropTypes.func.isRequired,
    surveyData: PropTypes.array.isRequired,
    onChangeAction: PropTypes.func.isRequired
}

export default SurveySelector;
