import React, { useState } from 'react';
import {
    Typography,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Paper,
    Button,
    Modal
} from "@material-ui/core";
import { Auth, API } from 'aws-amplify';
import { useQuery } from 'react-query';
import SurveySelector from '../../../components/Export/SurveySelector';
import CsvExporter from '../../../components/Export/CsvExporter';
import { surveysNew } from './ImportNewSurveys';
import AdminFileList from '../../../components/AdminFileList';

const ExportSurvey = () => {
    const [brand, setBrand] = useState();
    const [surveyAnswersSelected, setSurveyAnswersSelected] = useState([]);   /* answers selection [] */
    const [surveyData, setSurveyData] = useState([]);   /* all answers for a brand [] */
    const [surveyArray, setSurveyArray] = useState([]);   /* answers and questions */
    const [showFileList, setShowFileList] = useState(false);
    // --- For the modal/exporter ---
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    }

    // pop-over for exporting
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #706CEC',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4
    };
    // ---

    const {data, isLoading, isError} = useQuery('brands', async () => {
        const tokens = await Auth.currentSession();
        const token = tokens.idToken.jwtToken;

        const payLoad = {
            brandCode : null  // null to get All brands
        };

        const myInit = {
            headers: {
                Authorization : token,
            },
            response: true,
            queryStringParameters : payLoad
        };

        const result = await API.get('surveyapi', '/brands', myInit);
        return result.data
    });

    const getSurveys = async(selectedBrand) => {
        const tokens = await Auth.currentSession();
        const token = tokens.idToken.jwtToken

        if(!selectedBrand.brandCode) {
            console.error('No brand code associated with the current user 71')
            return;
        }

        const payload = {
            brandCode : selectedBrand.brandCode,
            surveyName: ''    /* all surveys for user */
        }

        const myInit = {
            headers: {
                Authorization : token,
            },
            response: true,
            queryStringParameters : payload
        };

        const { data } = await API.get('surveyapi', '/survey-results', myInit)
        setSurveyData(data);
    }


    const handlePreviewExport = () => {
        let csvSurveyDataArray = surveyAnswersSelected.map((survey) => {
            return {
                questions: surveysNew[survey.surveyName].pages[0].elements,
                answers: survey.surveyData,
                surveyTitle: survey.surveyName.split('_').join(' ')
            }
        })
        setSurveyArray(csvSurveyDataArray);
        setOpen(true);
        setShowFileList(true);
    }

    if(isError) return <Typography>Oops something went wrong...</Typography>

    return (
        <Box p='5%'>
            {
                isLoading ? <Typography>...Loading</Typography> :
                    <Box display='flex' component={Paper} flexDirection='column' alignItems='center' m='auto' width='100%' p='3%'>
                        <Box mt="10px" width='300px'>
                            <FormControl fullWidth variant='outlined' margin='dense'>
                                <InputLabel id="brand-select">Brand</InputLabel>
                                <Select
                                    labelId="brand-select-label"
                                    id="brand-select"
                                    value={brand}
                                    label="Brand"
                                    onChange={(event) => {
                                        setBrand(event.target.value);
                                        getSurveys(event.target.value);
                                    }}
                                >
                                    {
                                        data.map((brand, index) => {
                                            return (
                                                <MenuItem key={`brand-${index}`} value={brand}>{brand.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <SurveySelector
                            setSurveyAnswersSelected={setSurveyAnswersSelected}
                            surveyData={surveyData}
                            onChangeAction={() => setShowFileList(false)}
                        />
                        <Box mt='50px'>
                            <Button
                                variant="contained"
                                color="primary"
                                margin='dense'
                                disabled={brand && surveyAnswersSelected[0] ? false : true}
                                onClick={() => {
                                    handlePreviewExport();
                                }}
                            >
              Preview export
                            </Button>
                        </Box>
                        {
                            surveyAnswersSelected && showFileList && <Box>
                                {
                                    surveyAnswersSelected.map((survey) => {
                                        return (
                                            <AdminFileList key={survey.surveyName} surveyData={surveyData} currentBrand={brand} surveyName={survey.surveyName} />
                                        )
                                    })
                                }
                            </Box>
                        }
                        {/* modal pop-over for exporting window */}
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="user-table-modal"
                            aria-describedby="modal-for-more-detailed-info"
                        >
                            <Box sx={modalStyle}>
                                <CsvExporter
                                    surveyArray={surveyArray}
                                />
                            </Box>
                        </Modal>
                    </Box>
            }
        </Box>
    )
};

export default ExportSurvey;