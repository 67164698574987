import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/water.jpeg';

const Water = () => {
    const refs = [
        " Interwoven Risks, Untapped Opportunities, CDP, 2020: https://cdn.cdp.net/cdp-production/cms/reports/documents/000/005/367/original/CDP_Water_Apparel_Report_September_2020.pdf?1602059378",
        `Cotton: A Casestudy In Misinformation, Transformers Foundation, 2021: https://static1.squarespace.com/static/5efdeb17898fb81c1
    491fb04/t/61de9a24d5a36752adcbf737/1641978418846/CottonPaper_120122_TransformersFoundation_.pdf`,
        "Why fashion brands need to prioritise water conservation from an investor’s perspective, Fashion Revolution, 2019: https://www.fashionrevolution.org/why-fashion-brands-need-to-prioritise-water-conservation-from-an-investors-perspective/",
        "Annual Freshwater Withdrawals, World Bank, no date: https://data.worldbank.org/indicator/ER.H2O.FWTL.K3",
        "Wastewater: Fashion’s Grotesk Sustainability Problem, Fashion United, 2020: https://fashionunited.uk/news/fashion/wastewater-fashion-s-grotesk-sustainability-problem/2020050548770",
        "Browne, M.A., Crump, P., Niven, S.J., Teuten, E., Tonkin, A., Galloway, T. and Thompson, R. (2011). Accumulation of Microplastic on Shorelines Worldwide: Sources and Sinks. Environmental Science & Technology, 45(21), pp.9175–9179.",
        "Water Footprint Assessment of Polyester and Viscose, Water Footprint Network, 2017 https://waterfootprint.org/media/downloads/WFA_Polyester_and__Viscose_2017.pdf",
        "Water Recycling in the Textile Industry, Axium Process, no date: https://axiumprocess.com/industries/water-recycling/water-recycling-waste-stream-treatment/water-recycling-textile-industry/"
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title="Water" isMain={true}/>
            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>
                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>
                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>
              Clean water is essential to all life. However less than 3% of the earth’s water is freshwater.
                            <br /><br />
              Producing clothes consumes a lot of water. From agricultural run-off and discharge of chemical dyes to the release of microfibres when washing, fashion pollutes water systems at every stage.
                            <br /><br />
              According to NGO CDP, the vast majority of water-related risks happen in the manufacturing stages of clothing production. Water risks and their required responses vary from region to region<sup>1</sup>.
                        </Typography>
                    </Box>
                </Box>

                <Typography style={{fontSize: "1.1rem"}}>
          When we talk about water in fashion, we use phrases such as water use, water consumption and water footprint, often interchangeably<sup>2</sup>. In truth, calculating a water footprint for any given product is a very challenging thing to measure accurately. A globally accepted methodology doesn’t really exist for calculating water footprints, in comparison to, say, carbon<sup>3</sup>.
                    <br /><br />
          This topic is really about how (ir)responsibly water is managed: how much is taken, how much is lost, how much is discarded, and whether it is clean or polluted.
                </Typography>

                <ResourceTitle title="Why is this important?" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>There are a number of interconnected issues relating to the fashion industry’s use of water. Below we explore some of the major issues. <br /><br /></Typography>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Overconsumption of water</span><br />
          Water-stress refers to a situation where current water resources cannot fulfil the needs of a local area. This results in water scarcity. Many countries involved in the supply of raw materials to textile factories, as well as the host countries of the factories themselves, are situated in water-stressed areas.
                    <br /><br />
          The causes of water stress can be numerous and include poor governance of water as a utility, local agricultural practices and the impact of climate change. Water use by the fashion industry is predicted to increase by 50% by 2030, meaning lack of available water can become even more severe<sup>4 5</sup>.
                    <br /><br />
                </Typography>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Microplastic pollution</span><br />
          During the wet processing in production, as well as the home laundry of garments, items made from synthetic materials (such as polyester, acrylic and nylon) release microplastic fibres into water systems. These tiny, microscopic fibres then enter rivers and oceans.
                    <br /><br />
          An average laundry load can release upwards of 93,000 microplastic fibres<sup>6</sup>. Currently, we don’t know the long term impact of microplastic pollution in water. However, it has been linked to the death of fish, mammals and birds, as well as contaminating human food and drinking water.
                    <br /><br />
                </Typography>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Chemical pollution</span><br />
          Chemicals are released into the local waterways throughout all the stages of garment manufacture. This can be from:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Herbicides and pesticides used in agriculture</li>
                    <li style={{margin: "10px 0"}}>Oil exploration and refinery</li>
                    <li style={{margin: "10px 0"}}>Wet processing such as bleaching, dying and printing</li>
                    <li style={{margin: "10px 0"}}>Industrial washing</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>If left untreated these chemicals can pollute local groundwater and drinking water.</Typography>

                <ResourceTitle title="Potential next steps" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>Below are some suggestions for possible steps you can take to reduce your water use.</Typography>

                <Typography style={{fontSize: "1.1rem"}}><span style={{fontWeight: "600"}}>Designing products</span></Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>The vast majority of a product’s environmental impacts are decided at the design stage, and this also relates to water usage and water pollution. There are steps you can take in the design of your products to attempt  to reduce your water impact.</li>

                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Use a more sustainable cotton, rather than conventional cotton. Examples of more sustainable cotton include organic such as GOTS, FairTrade or Better Cotton Initiative.</li>

                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Use recycled fabrics. Using recycled fabrics reduces the water used in the manufacture of virgin fibres. For example, creating virgin polyester fibre uses water during the oil exploration process, refinery, and the fibre manufacturing process<sup>7</sup>.</li>

                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Reserve the use of synthetic fabrics for garments that get washed more scarcely - such as outerwear - to minimise the need for washing and shedding of microfibres.</li>

                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Use water-free dyeing techniques in the finishing of fabrics</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}><span style={{fontWeight: "600"}}>Engaging suppliers</span></Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>As the majority of water use occurs in the manufacturing stage of products, engaging your suppliers to understand their management of water as a resource is crucial. If you have not done so before, here are some examples of things you can ask your suppliers to begin a dialogue around water use.</li>

                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Do they know what the main source of the water used in their production is?</li>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}>How much freshwater is used at each stage of production?</li>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}>How is wastewater treated?</li>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Is any of the wastewater recycled?</li>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Do they use a filtration system to remove microplastics from greywater?</li>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}>What are the local regulations for discharge and treatment of wastewater? How are they compliant?</li>
                    <li style={{margin: "10px 0"}}>If you have a supplier or onboarding code of conduct, the conversation around this can be a good means to engage and develop relationships with your suppliers. Including these conversations can be a good means to improve awareness of water use and pollution.</li>
                    <li style={{margin: "10px 0"}}>Work with production sites that reuse or cycle effluent water from processing. Choose production sites and suppliers that make efforts to recycle effluent and treat their wastewater and clean it without polluting the local environment. This is especially important for dyeing, washing and finishing processes.</li>
                    <li style={{margin: "10px 0"}}>A specific example of this is the use of technology such as membrane filtration, used to recycle water<sup>8</sup>, or other closed-loop water systems. Having a closed-loop system means that the discharge of water from manufacturing is used repeatedly, reducing the amount of fresh water required.</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}><span style={{fontWeight: "600"}}>Engaging your consumers</span></Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>How garments are washed and laundered by your consumers is important. This is all about how your customers use and. Make sure you provide clear instructions to consumers to reduce their water use and ensure your products are properly cared for. Some suggestions of things to communicate are below.</li>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Washing on fuller loads reduces the volume of water used per garment per wash. Additionally this can protect garments from related damage such as pilling.</li>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Provide guidance on how frequently items need washing. Some items (trousers, outerwear) often do not need washing after each wear.</li>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Washing less reduces the number of microplastic fibres released from synthetic garments. Provide details of products such as guppy balls - used in domestic washing machines to trap fibres whilst clothes are washed.</li>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}></li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>Have this information for customers to find on your website or (even better!) on your product care labels.</Typography>
            </Box>

            <ResourcesReferences references={refs} />

        </Box>
    )
}

export default Water