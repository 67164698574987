import React from 'react'
import { Box, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

const Panel = (props) => {
    const { icon, title, subtitle, score, button, height } = props;
    const history = useHistory();

    return (
        <Box
            width="100%"
            height={height ? height : "89px"}
            display="flex"
            padding="15px 30px"
            flexDirection="row"
            justifyContent="space-between"
            borderBottom="1px solid #EAECEE"
        >
            <Box
                display="flex"
                flexDirection="row"
            >
                <Box
                    height="46px"
                    width="46px"
                    borderRadius="50%"
                    bgcolor="#F4F6F8"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr="21px"
                >{icon}</Box>
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <h3
                        style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#9EA0A5",
                            margin: "0px"
                        }}
                    >{title}</h3>
                    <p
                        style={{
                            fontFamily: "Inter",
                            fontSize: "17px",
                            fontWeight: "500",
                            color: "#3E3F42",
                            margin: "8px 0px"
                        }}
                    >{subtitle}</p>
                </Box>
            </Box>
            {
                button &&
        <Button
            style={{
                background: "#F6F2FC",
                borderRadius: "4px",
                padding: "8px 16px",
                height: "38px",
                fontWeight: "600",
                fontSize: "14px",
                textAlign: "center",
                color: "#706CEC",
                textTransform: "none",
                margin: "auto 0"
            }}
            disableRipple='false'
            onClick={() => {
                history.push(`/resources`);
            }}
        >View resource</Button>
            }

            {
                score &&
        <p style={{
            color: "#1A2259",
            fontWeight: "500",
            fontSize: "20px",
            margin: "auto"
        }}>{score}</p>
            }

        </Box>
    )
}

Panel.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    score: PropTypes.string,
    button: PropTypes.string,
    height: PropTypes.string
}

export default Panel