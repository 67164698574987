import React, { useState } from 'react';
import { Box, Table, Paper, TableContainer, TableHead, TableRow, TableBody, TableCell, Typography, Button, Modal } from '@material-ui/core';
import { Auth, API } from 'aws-amplify';
import { useQuery } from 'react-query';

const USER_STATUS = {
    FORCE_CHANGE_PASSWORD : 'Not logged in',
    CONFIRMED : 'Confirmed',
}

const UNAVAILABLE = 'cognito:default_val';

const sortArrayObjectAlphabetical = (a, b) => {
    let textA = a.email.toLowerCase();
    let textB = b.email.toLowerCase();
    if(a.name && b.name){
        textA = a.name.toLowerCase();
        textB = b.name.toLowerCase();
    }
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #706CEC',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
};

const UserDetails = () => {
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedUser(null);
    }

    const brandQueryResult =  useQuery('brands', async () => {
        const tokens = await Auth.currentSession();
        const token = tokens.idToken.jwtToken;
        const payLoad = {
            brandCode : null  // null to get All brands
        };

        const myInit = {
            headers: {
                Authorization : token,
            },
            response: true,
            queryStringParameters : payLoad
        };

        const result = await API.get('surveyapi', '/brands', myInit)
        return result.data
    });

    const userQueryResult = useQuery('users', async () => {
        const tokens = await Auth.currentSession();
        const token = tokens.idToken.jwtToken;

        const payLoad = {
            brandCode : null  // null to get All users
        };

        const myInit = {
            headers: {
                Authorization : token,
            },
            response: true,
            queryStringParameters : payLoad
        };

        const result = await API.get('surveyapi', '/user-list', myInit);
        return result.data;
    });

    return (
        <Box p="5%" height={1}>
            {
                userQueryResult.isLoading || brandQueryResult.isLoading ? <Typography>Loading...</Typography> :
                    userQueryResult.isError || brandQueryResult.isError ? <Typography>Oops something went wrong...</Typography> : (
                        <TableContainer component={Paper} style={{maxHeight: '100%'}}>
                            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Email adress</TableCell>
                                        <TableCell align="left">User name</TableCell>
                                        <TableCell align="left">Brand name</TableCell>
                                        <TableCell align="right">User type</TableCell>
                                        <TableCell align="right">Brand code</TableCell>
                                        <TableCell align="right">Account</TableCell>
                                        <TableCell align="right">User status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        userQueryResult.data.sort(sortArrayObjectAlphabetical).map((user) => {
                                            let currentBrandName = "";
                                            brandQueryResult.data.forEach((brand) => {
                                                if (brand.brandCode === user.brandCode) {
                                                    currentBrandName = brand.name
                                                }
                                            })
                                            return (
                                                <TableRow
                                                    key={user.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Button onClick={() => {
                                                            handleOpen();
                                                            setSelectedUser(user);
                                                        }}>{user.email}</Button>
                                                    </TableCell>
                                                    <TableCell align="left">{user.name === UNAVAILABLE ? 'N/A' : user.name}</TableCell>
                                                    <TableCell align="left">{currentBrandName}</TableCell>
                                                    <TableCell align="right">{user.userType}</TableCell>
                                                    <TableCell align="right">{user.brandCode}</TableCell>
                                                    <TableCell align="right">{user.account}</TableCell>
                                                    <TableCell align="right">{USER_STATUS[user.userStatus] || 'Unconfirmed'}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
            }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="user-table-modal"
                aria-describedby="modal-for-more-detailed-info"
            >
                <Box sx={modalStyle}>
                    <TableContainer component={Paper}>
                        <Table aria-label="detailed table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>id</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="right">User type</TableCell>
                                    <TableCell align="right">Brand code</TableCell>
                                    <TableCell align="right">Account</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="left">User status</TableCell>
                                    <TableCell align="left">User Confirmed Date</TableCell>
                                    <TableCell align="right">Created</TableCell>
                                    <TableCell align="right">Last login</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">{selectedUser?.id}</TableCell>
                                    <TableCell align="left">{selectedUser?.name}</TableCell>
                                    <TableCell align="right">{selectedUser?.userType}</TableCell>
                                    <TableCell align="right">{selectedUser?.brandCode}</TableCell>
                                    <TableCell align="right">{selectedUser?.account}</TableCell>
                                    <TableCell align="left">{selectedUser?.email}</TableCell>
                                    <TableCell align="left">{selectedUser?.userStatus}</TableCell>
                                    <TableCell align="left">{selectedUser?.userConfirmedDate}</TableCell>
                                    <TableCell align="right">{selectedUser?.createdAt}</TableCell>
                                    <TableCell align="right">{selectedUser?.lastLogin}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </Box>
    )
}

export default UserDetails