import React, { useState } from 'react';
import { Page } from "../../UI/templates/Page";
import { TextField, Box, Button, Paper, Typography } from "@material-ui/core";
import { Auth } from 'aws-amplify';
import AuthState from '../../authStates';
import { useStyles } from '../../UI/styles/themes';
import { useUserStore } from '../../assets/state';
import PropTypes from 'prop-types';

const Verify = (props) => {
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const setAuthState = useUserStore((state) => state.setAuthState);
    const [resentCode, setResentCode] = useState(false);

    // handle resend of verification code
    async function resendConfirmationCode() {
        try {
            await Auth.resendSignUp(props.email);
        // console.warn('code resent successfully');
        } catch (err) {
        // console.error('error resending code: ', err);
            setErrorMessage(err.message);
        }
    }

    // on verify function sends verification code
    async function confirmSignUp() {
        try {
            // email = amplify account username
            await Auth.confirmSignUp(props.email, code)
                .then(() => {
                    props.setEmail('');
                    props.setPassword('');
                    setAuthState(AuthState.SignedOut)
                })
        } catch (error) {
            if (error.code === 'ExpiredCodeException') {
                setResentCode(true);
                resendConfirmationCode();
            } else {
                setErrorMessage('Invalid code. Please try again');
                // console.error('error confirming sign up', error);
            }
        }
    }

    // allow use of customer styles/classes from themes.jsx
    const classes = useStyles();

    return (
        <Page title="Verification Page">
            <Paper
                elevation={3}
                className={classes.paperShadow}
                height="100%"
            >
                <Box className={classes.mainContainer} width={400} height={421}>
                    <Box
                        textAlign="center"
                        color="#1A2259"
                    >
                        <Typography variant="h3">Nearly there!</Typography>
                    </Box>
                    <Box pt="10px" pb="13px">
                        {
                            resentCode ? <Typography variant="subtitle1">Sorry! Your verification code has expired. Please check your inbox for a new one.</Typography> :
                                <Typography variant="subtitle1">
              Enter the verification code we provided with your temporary login details.
                                </Typography>
                        }
                    </Box>
                    <Box display='flex' height='18px' alignItems='center' textAlign='center' justifyContent="center" mb="13px">
                        {
                            errorMessage && (
                                <Typography className={classes.errorText} variant="body2" color="primary">
                                    {errorMessage}
                                </Typography>
                            )
                        }
                    </Box>
                    <Box display='flex' flexDirection='column' alignItems='center'>
                        <Box width={1}>
                            <TextField
                                type="number"
                                id="oulined-basic"
                                variant='outlined'
                                label="Enter verification code"
                                fullWidth
                                margin="dense"
                                value={code}
                                onChange={(event) => setCode(event.target.value)}
                            />
                        </Box>
                        <Box mt='24px' width={1} py={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={confirmSignUp}
                                fullWidth
                                size='large'
                                className={classes.button}
                            >
                Verify
                            </Button>
                        </Box>
                    </Box>
                    <Box width={1} mt='32px' pt="11px" pb="33px">
                        <Button
                            className={classes.buttonText}
                            onClick={resendConfirmationCode}
                        >
              Resend code
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Page>
    )
};

Verify.propTypes = {
    email: PropTypes.string,
    setEmail: PropTypes.func,
    setPassword: PropTypes.func
}

export default Verify
