import React, { useState } from 'react'
import { TextField, Box, Button, Paper, Typography, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import { Page } from "../../UI/templates/Page";
import { Visibility, VisibilityOff} from '@material-ui/icons';
import { useStyles } from '../../UI/styles/themes'
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import AuthState from '../../authStates';
import { useUserStore } from '../../assets/state';

const ForgotPassword = () => {
    const setAuthState = useUserStore((state) => state.setAuthState);
    const [pageState, setPageState] = useState('email');
    const [errorMessage, setErrorMessage] = useState("");

    // useState for password
    const [values, setValues] = useState({
        amount: '',
        password: '',
        email: '',
        code: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    // Yup schema for react hook form validation
    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required('Verification code is required'),
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters')
            .max(40, 'Password must not exceed 40 characters')
            .matches(
                /(?=.*?[A-Z])/,
                "Must contain an uppercase character"
            )
            .matches(
                /(?=.*?[0-9])/,
                "Must contain a digit"
            )
            .matches(
                /(?=.*?[#?!@$%^&*-])/,
                "Must contain a special character"
            )
            .matches(
                /(?=.*?[a-z])/,
                "Must contain a lowercase character"
            ),
    });

    // react hook form initialized
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({resolver: yupResolver(validationSchema)});

    // Send confirmation code to user's email
    async function sendCode() {
        Auth.forgotPassword(values.email)
            .then(() => {
                setPageState('resetPassword');
            })
            .catch(() => {
                setErrorMessage('Account not found or requires a password change.');

            });
    }

    // on form submission
    async function onSubmit(data) {
    // Collect confirmation code and new password, then
        Auth.forgotPasswordSubmit(values.email, data.code, data.password)
            .then(() => {
                setAuthState(AuthState.SignedOut);
            })
            .catch(() => {
                setErrorMessage('Invalid or expired code. Please try again');
                // console.error(err);
            });
    }

    //  -----------------
    // Functions for toggling password ----
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //  -----------------

    // allow use of customer styles/classes from themes.jsx
    const classes = useStyles();

    return (
        <Page>
            <Box display='flex' flexDirection='column'>
                <Paper
                    elevation={3}
                    className={classes.paperShadow}
                    height="100%"
                >
                    <Box className={classes.mainContainer} width={400} minHeight={359}>
                        <Box
                            textAlign="center"
                            color="#1A2259"
                            mb='5px'
                        >
                            <Typography variant="h3">Forgot password</Typography>
                        </Box>
                        {/* Part 1 of forgot password */}
                        <Box display='flex' flexDirection='column' alignItems='center'>
                            {
                                pageState === 'email' ? (
                                    <>
                                        <Box mt='12px'   width={1}>
                                            <Box width={1} mb="18px" color='#1A2259' textAlign='center'>
                                                <Typography variant="caption">Please enter your email address to receive a password reset code</Typography>
                                            </Box>
                                            <Box height="20px" mb='18px' textAlign='center'>
                                                {
                                                    errorMessage && (
                                                        <Typography variant="body2" className={classes.errorText}>
                                                            {errorMessage}
                                                        </Typography>
                                                    )
                                                }
                                            </Box>
                                            <TextField
                                                type="email"
                                                id="oulined-basic"
                                                variant='outlined'
                                                label="Email"
                                                value={values.email}
                                                onChange={handleChange('email')}
                                                fullWidth
                                                margin="dense"
                                            />
                                        </Box>
                                        <Box width={1} mt="34px">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={sendCode}
                                                className={classes.button}
                                                size="large"
                                            >
                    Next
                                            </Button>
                                        </Box>
                                    </>) : (
                                    <Box mt='12px' width={1}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Box width={1} mb='27px'>
                                                <Box pb="8px" textAlign='center' style={{color: '#1A2259'}}>
                                                    <Typography variant="caption">Please check your inbox - we have sent you a verification code </Typography>
                                                </Box>
                                                <Box height="24px" mb='20px' textAlign='center'>
                                                    {
                                                        errorMessage && (
                                                            <Typography variant="body2" className={classes.errorText}>
                                                                {errorMessage}
                                                            </Typography>
                                                        )
                                                    }
                                                </Box>
                                                <TextField
                                                    type="number"
                                                    id="oulined-basic"
                                                    variant='outlined'
                                                    label="Enter verification code"
                                                    fullWidth
                                                    margin="dense"
                                                    {...register('code')}
                                                    error={errors.code ? true : false}
                                                />
                                                <Typography variant="body2" className={classes.errorText}>
                                                    {errors.code?.message}
                                                </Typography>
                                            </Box>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel margin="dense" htmlFor="outlined-adornment-password">Password</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={values.showPassword ? 'text' : 'password'}
                                                    fullWidth
                                                    margin="dense"
                                                    {...register('password')}
                                                    error={errors.password ? true : false}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                />
                                            </FormControl>
                                            <Box textAlign='left !important' mt='2px' width={1}>
                                                <Typography variant="body2" className={classes.errorText}>
                                                    {errors.password?.message}
                                                </Typography>
                                            </Box>
                                            <Box width={1} mt="30px" mb='43px'>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    className={classes.button}
                                                    size="large"
                                                >
                        Confirm
                                                </Button>
                                            </Box>
                                        </form>
                                        <Button
                                            color="primary"
                                            className={classes.buttonText}
                                            onClick={sendCode}
                                        >
                    Resend code
                                        </Button>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                </Paper>
                <Box mt="40px">
                    <Button
                        color="primary"
                        className={classes.buttonText}
                        onClick={() => {setAuthState(AuthState.SignedOut)}}
                    >
                        {`<`} Back to login
                    </Button>
                </Box>
            </Box>
        </Page>
    )
}

export default ForgotPassword
