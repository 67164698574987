import React from 'react'
import { Box, Button, Typography } from "@material-ui/core";
import { useStyles } from '../../UI/styles/themes';
import { useHistory } from "react-router-dom";
import { ReactComponent as CompletedIcon } from '../../assets/svg/completed.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/svg/rightArrowIcon.svg';
import PropTypes from 'prop-types';

const SurveySectionButton = (props) => {
    const { sectionName, url, isCompleted, number, description} = props;
    // allow use of customer styles/classes from themes.jsx
    const classes = useStyles();
    const history = useHistory();

    return (
        <Box my='16px'>
            <Button
                className={`${classes.buttonSection} parentInvisibleOnHover`}
                disableRipple='false'
                onClick={() => {
                    history.push(`/survey/${url}`);
                }}
            >
                <Box width={1} pl="24px" pr="22px" display='flex' justifyContent="space-between" alignItems="center">
                    <Box>
                        {props.children}
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="start" mr='26px' ml='24px' width="349px">
                        <Typography className={classes.H400}>{number}. {sectionName}</Typography>
                        <Typography className={classes.bodyMedium} style={{color: '#838791'}}>{description}</Typography>
                    </Box>
                    {
                        isCompleted ?
                            <CompletedIcon
                                fill="#6FCF97"
                                className={'invisibleOnHover'}
                            /> :
                            <CompletedIcon
                                fill="#DFDFE2"
                                className={'invisibleOnHover'}
                            />
                    }
                    <RightArrowIcon className={'visibleOnHover'}
                        style={{
                            display: 'none',
                        }}
                    />
                </Box>
            </Button>
        </Box>
    )
}

SurveySectionButton.propTypes = {
    sectionName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    number: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    children: PropTypes.element,
}

export default SurveySectionButton
