import React, { useEffect, useState }  from 'react';
import ProgressBar from '../../../components/dashboard/ProgressBar';
import DialBar from '../../../components/dashboard/DialBar';
import ScoreTile from '../../../components/dashboard/ScoreTile';
import Panel from '../../../components/dashboard/Panel';
import { font2Theme, useStyles } from '../../../UI/styles/themes';
import { Box, ThemeProvider, Typography } from '@material-ui/core';
import SectionHeader from '../../../components/dashboard/SectionHeader';
import { ReactComponent as DialIcon } from '../../../assets/svg/dial.svg';
import { ReactComponent as ScoreIcon } from '../../../assets/svg/barCharts.svg';
import { ReactComponent as RibbonIcon } from '../../../assets/svg/ribbon.svg';
import { ReactComponent as GoalsIcon } from '../../../assets/svg/surveyBar/yourGoalsIcon.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/svg/surveyBar/peopleIcon.svg';
import { ReactComponent as PlanetIcon } from '../../../assets/svg/surveyBar/planetIcon.svg';
import { ReactComponent as SourcingIcon } from '../../../assets/svg/surveyBar/sourcingIcon.svg';
import peopleSVG from '../../../assets/svg/sectionTile/peopleIcon.svg';
import planetSVG from '../../../assets/svg/sectionTile/planetIcon.svg';
import sourcingSVG from '../../../assets/svg/sectionTile/sourcingIcon.svg';
import { useUserStore } from '../../../assets/state';
import { API, Auth } from 'aws-amplify';

const SF_THRESHOLD_SCORE = 40;

const ResultsPage = () => {
    const [SFAverageScore, setSFAverageScore] = useState({
        people: 0,
        planet: 0,
        sourcing: 0
    })
    const [brandScore, setBrandScore] = useState({
        brandName: '',
        avgScore: 0,
        people: 0,
        planet: 0,
        sourcing: 0
    })

    useEffect(() => {
        Auth.currentSession().then((session) => {
            const token = session.idToken.jwtToken;
            const payLoad = {
                "brandCode": session.getIdToken().payload['custom:brandCode'] || "",
                "type": "summary"
            }
            const myInit = {
                headers: {
                    Authorization : token,
                },
                response: true,
                queryStringParameters : payLoad
            };
            API.get('surveyapi', '/brands', myInit).then(response => {
                setSFAverageScore(response.data.SFAverageScore)
                setBrandScore(response.data.brandScore)
            })
        });
    },[])

    const classes = useStyles();
    const user = useUserStore((state) => state.user);

    return (
        <Box p="41px 80px">
            <Box display="flex" color="offBlack.main" mb="41px">
                <ThemeProvider theme={font2Theme}>
                    <Box>
                        <Typography className={classes.H600}>{user && user.name ? `Welcome, ${user.name}!` : 'Welcome'}</Typography>
                    </Box>
                </ThemeProvider>
            </Box>
            <Box
                display="grid"
                gridTemplateRows="1fr auto"
                gridTemplateColumns="1fr auto"
                gridGap="47px"
                width="fit-content"
                margin="auto"
            >
                {/* overall score */}
                <Box
                    className={classes.dashboardSection}
                    height="366px"
                    width="483px"
                >
                    <SectionHeader title="Overall sustainability score" subtitle="Based on the survey you completed" icon={<DialIcon fill="#706CEC" />} />
                    <Box
                        display="flex"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                        pb="10px"
                    >
                        <DialBar height={146} width={293} thickness={50} percentage={brandScore.avgScore} bgcolor="#FFFFFF" text="Overall score" />
                    </Box>
                </Box>

                {/* Score breakdown */}
                <Box
                    className={classes.dashboardSection}
                    height="366px"
                    width="753px"
                >
                    <SectionHeader title="Score breakdown" subtitle="A detailed breakdown of your score" icon={<ScoreIcon fill="#706CEC" />} />
                    <Box
                        width="100%"
                        height="100%"
                        display= "flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        <ScoreTile icon={peopleSVG} section="PEOPLE" score={brandScore.people} aveScore={SFAverageScore.people} />
                        <ScoreTile icon={planetSVG} section="PLANET" score={brandScore.planet} aveScore={SFAverageScore.planet} />
                        <ScoreTile icon={sourcingSVG} section="SOURCING" score={brandScore.sourcing} aveScore={SFAverageScore.sourcing} />
                    </Box>
                </Box>

                {/* Score comparison */}
                <Box
                    className={classes.dashboardSection}
                    height="535px"
                    width="483px"
                >
                    <SectionHeader title="Sustainability Index" link={true} icon={<RibbonIcon fill="#706CEC" />} />
                    <Box
                        height="100%"
                        padding="0px 32px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                    >
                        <Box>
                            <Box width="100%" display="flex" justifyContent="center" mt="30px">
                                <ProgressBar
                                    height={14}
                                    width={414}
                                    current={brandScore.avgScore}
                                    average={SF_THRESHOLD_SCORE}
                                    currentName={brandScore.brandName}
                                    averageName="SilkFred threshold"
                                />
                            </Box>
                            <Box
                                color="#3E3F42"
                                lineHeight="22px"
                                mt="45px"
                            >
                                {/* Content here depends on score */}
                                {
                                    brandScore.avgScore >= SF_THRESHOLD_SCORE ?
                                        <>
                                            <Typography style={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                marginBottom: "7px"
                                            }} ><span style={{fontWeight: "700"}}>Congratulations!</span> You have passed SilkFred’s threshold to appear on the Insight Index.</Typography>
                                            <Typography style={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                color: "#6B6C6F"
                                            }}>SilkFred will spotlight brands that reach the {SF_THRESHOLD_SCORE}% threshold on product pages and in the <a style={{
                                                    color: "#2F80ED",
                                                    textDecoration: "none",
                                                    fontWeight: "600"
                                                }} href="/" target="_blank" rel="noopener noreferrer">SilkFred Insight Edit</a>. This is an additional – and valuable - marketing channel for you.</Typography>
                                        </> :
                                        <>
                                            <Typography style={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                marginBottom: "7px"
                                            }} >There’s still a little way for you to go before appearing on the Insight Index.</Typography>
                                            <Typography style={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                color: "#6B6C6F"
                                            }}>SilkFred will spotlight brands that reach the {SF_THRESHOLD_SCORE}% threshold on product pages and in the <a style={{
                                                    color: "#2F80ED",
                                                    textDecoration: "none",
                                                    fontWeight: "600"
                                                }} href="/" target="_blank" rel="noopener noreferrer">SilkFred Insight Edit</a>. This is an additional – and valuable - marketing channel for you.</Typography>
                                        </>
                                }
                                <Typography style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#6B6C6F",
                                    marginTop: "25px"
                                }}>Click here to find out more about the <a style={{
                                        color: "#2F80ED",
                                        textDecoration: "none",
                                    }} href="/" target="_blank" rel="noopener noreferrer">SilkFred Insight Edit</a></Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Next steps */}
                <Box
                    className={classes.dashboardSection}
                    height="535px"
                    width="753px"
                >
                    <SectionHeader title="Suggested next steps" subtitle="Take a look at the following resources, based on your interests and your score" icon={<GoalsIcon fill="#706CEC" />} />
                    <Panel icon={<PeopleIcon fill="#706CEC" width="60%" height="60%" />} title="PEOPLE" subtitle="Communication"/>
                    <Panel icon={<PlanetIcon fill="#706CEC" width="60%" height="60%"/>} title="PLANET" subtitle="Waste Management"/>
                    <Panel icon={<SourcingIcon fill="#706CEC" width="60%" height="60%"/>} title="SOURCING" subtitle={`Transparency & Traceability`}/>
                </Box>
            </Box>
        </Box>
    )
}

export default ResultsPage