import React from 'react'
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

// height and width are numbers
const DialBar = (props) => {
    const  { percentage, text, width, thickness, bgcolor } = props;
    const angle = (180*(percentage/100))-90; // 270deg === 100%

    return (
        <Box position="relative" padding="0">
            <Box
                overflow="hidden"
                position="relative"
                height={`${width/2}px`}
                width={`${width}px`}
                borderRadius={`${width/2}px ${width/2}px 0 0`}
            >
                <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={`${width}px`}
                    width={`${width}px`}
                    bgcolor="#EAEAFD"
                    borderRadius={`50%`}
                >
                    <Box
                        bgcolor={bgcolor}
                        zIndex="5"
                        height={`${width-thickness}px`}
                        width={`${width-thickness}px`}
                        borderRadius="50%"
                        paddingBottom={`${(width-thickness)/2}px`}
                        display="flex"
                        flexDirection='column'
                        justifyContent="flex-end"
                    >
                        <Box
                            display="flex"
                            flexDirection='column'
                            alignItems="center"
                        >
                            {/* text here */}
                            <h3 style={{
                                fontWeight: "500",
                                fontSize: "36px",
                                color: "#1A2259",
                                margin: "0"
                            }}
                            >{percentage}%</h3>
                            <p
                                style={{
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    color: "rgba(24, 24, 25, 0.42)",
                                    margin: "0"
                                }}
                            >{text}</p>
                        </Box>
                    </Box>
                    {/* gauge bar */}
                    <div style={{
                        transform: `rotate(${angle}deg)`,
                        position: "absolute",
                        height: `${width}px`,
                        width: `${width}px`,
                        background: "linear-gradient(90deg, #706CEC 50%, #706CEC 50%, rgba(0,0,0,0) 0%)"
                    }}
                    >
                    </div>
                </Box>

                {/* dotted line  */}
                <Box
                    padding="35px"
                    borderRadius="50%"
                    bottom="-100%"
                    zIndex="10"
                    position="absolute"
                    height={`${width}px`}
                    width={`${width}px`}
                >
                    <Box
                        border="4px dotted #5E659680"
                        borderRadius="50%"
                        width="100%"
                        height="100%"
                    ></Box>
                </Box>
            </Box>

            <p style={{
                position: 'absolute',
                bottom: '-2em',
                left: '0',
                margin: '0',
                padding: '0',
                fontWeight: 700,
                fontSize: "14px",
                color: "rgba(24, 24, 25, 0.42)"
            }}>0%</p>
            <p style={{
                position: 'absolute',
                bottom: '-2em',
                right: '-0.5em',
                margin: '0',
                padding: '0',
                fontWeight: 700,
                fontSize: "14px",
                color: "rgba(24, 24, 25, 0.42)"
            }}>100%</p>
        </Box>
    )
}

DialBar.propTypes = {
    percentage: PropTypes.number,
    text: PropTypes.string,
    width:  PropTypes.number,
    thickness: PropTypes.string,
    bgcolor: PropTypes.string
}

export default DialBar