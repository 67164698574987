import React, { useState, useEffect } from "react";
import HomePage from '../..//../UI/templates/HomePage';
import ResultsPage from '../ResultsPage';
import BrandStartPage from "../BrandStartPage";

// Home page has three states:
//  1. Brand has not completed survey -> welcome page with links to survey sections
//  2. Brand has completed survey -> results pending
//  3. Brand survey results complete -> results page/dashboard

export const Home = () => {
    const [brandResultsCompleted, setBrandResultsCompleted] = useState(false); // ** will have to calculate from backend **

    useEffect(() => {
        setBrandResultsCompleted(true);
    }, []);

    return (
        <HomePage>
            {
                brandResultsCompleted ? 
                    <ResultsPage /> : <BrandStartPage />
            }
        </HomePage>
    );
};
