import React from 'react';
import { Box, List, Typography } from '@material-ui/core';
import HomePage from '../../../UI/templates/HomePage';
import { useStyles } from '../../../UI/styles/themes';
import ResourcesNavPanel from '../../../components/ResourcesNavPanel';
import TransTrace from '../Resources/TransTrace';
import Chemicals from '../Resources/Chemicals';
import WasteCircularity from '../Resources/WasteCircularity/WasteCircularity';
import Water from '../Resources/Water';
import Materials from '../Resources/Materials';
import GHG12 from '../Resources/GHG12';
import GHG3 from '../Resources/GHG3';
import PeopleProAssess from '../Resources/PeopleProAssess/PeopleProAssess';
import Community from '../Resources/Community';

import {
    Switch,
    Route,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import Biodiversity from '../Resources/Biodiversity/Biodiversity';

const ResourcesPage = () => {
    const classes = useStyles();
    const { path } = useRouteMatch();

    return (
        <HomePage>
            <Box display="flex">
                <Box minHeight="100vh" bgcolor="white.main" borderRight="1px solid #E2E5ED" minWidth="335px">
                    <Box width={1} px="39px" pt="31px">
                        <Box m='0px' p='0px'>
                            <Typography style={{
                                fontFamily: 'Syne',
                                fontWeight: "600",
                                fontSize: "20px",
                                textAlign: "center",
                                color: "#1A2259"
                            }}>Brand Resources</Typography>
                        </Box>
                        <List className={classes.panel}>
                            <ResourcesNavPanel title={`Transparency & traceability`} url={`/resources/transparency&traceability`} />
                            <ResourcesNavPanel title={`Chemicals`} url={`/resources/chemicals`} />
                            <ResourcesNavPanel title={`Biodiversity`} url={`/resources/biodiversity`} />
                            <ResourcesNavPanel title={`Waste & circularity`} url={`/resources/waste&circularity`} />
                            <ResourcesNavPanel title={`Water`} url={`/resources/water`} />
                            <ResourcesNavPanel title={`Materials`} url={`/resources/materials`} />
                            <ResourcesNavPanel title={`GHG Emissions Scope 1 & 2`} url={`/resources/ghg-scope12`} />
                            <ResourcesNavPanel title={`GHG Emissions Scope 3`} url={`/resources/ghg-scope3`} />
                            <ResourcesNavPanel title={`People Processes & Assessments`} url={`/resources/people-processes-assessments`} />
                            <ResourcesNavPanel title={`People Community`} url={`/resources/people-community`} />
                        </List>
                    </Box>
                </Box>
                <Box width="100%" height="100vh">
                    <Switch>
                        <Route path={`${path}/transparency&traceability`}>
                            <TransTrace />
                        </Route>
                        <Route path={`${path}/chemicals`}>
                            <Chemicals />
                        </Route>
                        <Route path={`${path}/biodiversity`}>
                            <Biodiversity />
                        </Route>
                        <Route path={`${path}/waste&circularity`}>
                            <WasteCircularity />
                        </Route>
                        <Route path={`${path}/water`}>
                            <Water />
                        </Route>
                        <Route path={`${path}/materials`}>
                            <Materials />
                        </Route>
                        <Route path={`${path}/ghg-scope12`}>
                            <GHG12 />
                        </Route>
                        <Route path={`${path}/ghg-scope3`}>
                            <GHG3 />
                        </Route>
                        <Route path={`${path}/people-processes-assessments`}>
                            <PeopleProAssess />
                        </Route>
                        <Route path={`${path}/people-community`}>
                            <Community />
                        </Route>
                        <Redirect from={path} to={`${path}/transparency&traceability`} />
                    </Switch>
                </Box>
            </Box>
        </HomePage>
    )
}

export default ResourcesPage