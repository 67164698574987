import React, { useEffect, useState } from 'react';
import { useUserStore } from './assets/state';
import { ThemeProvider } from "@material-ui/core";
import { useHistory, useLocation, Route, Switch } from 'react-router-dom';
import { getBrand } from './assets/helpers';
import { QueryClient, QueryClientProvider } from 'react-query';
// styling + fonts
import { theme } from './UI/styles/themes';
import { StylesProvider } from '@material-ui/core/styles';
import 'typeface-inter';
import 'typeface-syne';
import './App.css';

// components
import { Login } from './Pages/Login';
import Verify from './Pages/Verify';
import AppWithAuth from './components/AppWithAuth';
import ForgotPassword from './Pages/ForgotPassword';

// import and configure amplify
import {Amplify, Auth} from 'aws-amplify';
import AuthState from './authStates';
import { Authenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports.json';
import roles from './userTypes';
Amplify.configure(awsconfig)

function App() {

    const setUserType = useUserStore((state) => state.setUserType);
    const userType = useUserStore((state) => state.userType);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const setUser = useUserStore((state) => state.setUser);
    const setUserBrand = useUserStore((state) => state.setUserBrand);
    const authState = useUserStore((state) => state.authState);
    const setAuthState = useUserStore((state) => state.setAuthState);
    const location = useLocation();
    const history = useHistory();
    const queryClient = new QueryClient()
    const user = useUserStore((state) => state.user);
    const accountName = process.env.REACT_APP_ACCOUNT_NAME

    // run on app start -> this is for is user refreshes/closes and opens tab
    useEffect(() => {
        const defineUser = async() => {
            try {
                const user = await Auth.currentAuthenticatedUser()
                const isAccountSilkfred = accountName === 'silkfred'

                setAuthState(AuthState.SignedIn);
                setUser(user.attributes);

                const session = await Auth.currentSession();
                const groups = session.getIdToken().payload['cognito:groups'] || [];

                if(groups.includes("account-admins")){
                    setUserType(roles.Admin)
                } else if(groups.includes("account-viewers") && isAccountSilkfred){
                    setUserType(roles.AccountViewer)
                } else {
                    setUserType(roles.Brand)
                }

                // if user is a brand, get brand data and store in global state
                if(userType === roles.Brand) {
                    try {
                        const brandResult = await getBrand()
                        setUserBrand(brandResult.data[0]);
                    } catch (error) {
                        console.error('An error occurred when fetching users brand info', error);
                    }
                }
            } catch (error) {
                setUserType(roles.Brand);
                setAuthState(AuthState.SignedOut);
            }
        }
        if(!user){
            defineUser();
        }
    },[user, setAuthState, setUserType, setUser, setUserBrand, accountName])

    useEffect(() => {
        if(location.pathname === '/') {
            history.push('/home');
        }
    }, [location, history])

    // function to render content is app
    const renderContent = (authState) => {
        switch (authState) {
        case AuthState.SignedIn:
            return <AppWithAuth />
        case AuthState.SignedOut:
            return <Login setEmail={setEmail} setPassword={setPassword} />
        case AuthState.ConfirmSignUp:
            return <Verify email={email} setEmail={setEmail} password={password} setPassword={setPassword} />
        case AuthState.ForgotPassword:
            return <ForgotPassword />
        default:
            return <Authenticator />
        }
    }

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <Switch>
                        <Route path="/" >
                            {renderContent(authState)}
                        </Route>
                    </Switch>
                </QueryClientProvider>
            </ThemeProvider>
        </StylesProvider>
    );
}

export default App;