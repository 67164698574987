import React from 'react';
import { Route } from 'react-router-dom';
import { useUserStore } from '../../assets/state';
import AuthState from '../../authStates';

import PropTypes from 'prop-types';
import roles from '../../userTypes';

const PrivateRoute = ({component: Component, ...rest}) => {
    const userType = useUserStore((state) => state.userType);
    const setAuthState = useUserStore((state) => state.setAuthState);
    const setUser = useUserStore((state) => state.setUser);

    const RedirectUser = () => {
        setUser(null);
        setAuthState(AuthState.SignedOut);
    };

    return (
        <Route
            {...rest}
            render={() => {
                return userType === roles.Admin ?
                    <Component />  : <RedirectUser />
            }}
        />
    )
};

PrivateRoute.propTypes = {
    component: PropTypes.func
}

export default PrivateRoute
