import React from 'react'
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const ScoreTile = (props) => {
    const { icon, section, score, aveScore } = props;

    return (
        <Box
            position="relative"
            display="flex"
            flexDirection="column"
            padding="24px 30px"
            bgcolor="#6758F3"
            borderRadius="8px"
            width="215px"
            height="197px"
            color="#FFFFFF"
        >
            <Box
                position="absolute"
                top="0%"
                right="0%"
            >
                <img src={icon} width="100px" height="auto" alt="section icon" style={{margin: "5px 5px 0 0"}}/>
            </Box>
            <Typography style={{
                fontSize: "14px",
                fontWeight: "500"
            }}
            >
                {section}
            </Typography>
            <Typography style={{
                fontWeight: "500",
                fontSize: "36px"
            }}
            >
                {score}%
            </Typography>
            <Box
                borderTop="1px solid rgba(255, 255, 255, 0.4)"
                display="flex"
                alignItems="center"
                padding="16px 0px"
                mt="46px"
            >
                <Typography style={{
                    fontWeight: "500",
                    fontSize: "12px"
                }}
                >
          SilkFred average: {aveScore}%
                </Typography>
            </Box>
        </Box>
    )
}

ScoreTile.propTypes = {
    icon: PropTypes.string,
    section: PropTypes.string,
    score: PropTypes.number,
    aveScore: PropTypes.number
}

export default ScoreTile