import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const WelcomeSection = (props) => {
    return (
        <Box display="flex" mb="45px" width={1}>
            <Box
                mr='34px'
                minWidth="60px"
                minHeight="60px"
                maxWidth="60px"
                maxHeight="60px"
                borderRadius="50%"
                bgcolor="#F8F9FA"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >{props.icon}</Box>
            <Box display="flex" flexDirection="column">
                <Typography style={{fontWeight: '700', fontSize: '14px', color: '#1A2259'}}>{props.title}</Typography>
                <Typography style={{marginTop: '16px', fontWeight: '500', fontSize: '14px', color: '#5E6596', lineHeight: '150%'}}>{props.text}<span style={{fontWeight: 'bold'}}>{props.boldText}</span></Typography>
                {
                    props.exampleEvidence && (
                        <>
                            <Typography style={{marginTop: '12px', fontWeight: '500', fontSize: '14px', color: '#5E6596', lineHeight: '150%'}}>Examples of the type of evidence we ask for are included by each question. This includes documents such as:</Typography>
                            <ul style={{marginTop: '12px', fontWeight: '500', fontSize: '14px', color: '#5E6596', lineHeight: '150%'}}>
                                <li>Certifications for any certified raw materials or processes you use in your production.</li>
                                <li>Company policies, code of conducts, or supplier onboarding documents.</li>
                                <li>Invoices or purchase orders relating to your packaging, transportation and logistics.</li>
                                <li>Audit documents you may have relating to the factories where your products are made.</li>
                            </ul>
                        </>
                    )
                }
            </Box>
        </Box>
    )
}

WelcomeSection.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    text: PropTypes.string,
    boldText: PropTypes.string,
    exampleEvidence: PropTypes.bool,
}

export default WelcomeSection
