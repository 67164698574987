export const logEmissJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Shippingtocust_1",
                    title: "Do you manage the shipping of your products to your customers? ",
                    description: "This question is asking how you manage order fulfilment. If shipping products to customers is something you manage directly then we will ask you some questions about your logistics.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes - we send our products to our customers ourselves"
                        },
                        {
                            value: "2",
                            text: "No - our products are sent to our customers by a third party"
                        },
                        {
                            value: "3",
                            text: "Other"
                        }
                    ]
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Shippingtocust_other",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 3",
                    indent: 3,
                    title: "Please provide details",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Primarymethod_1.1",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1",
                    indent: 3,
                    title: "1.1 What is the primary method you use to send products to customers?  ",
                    description: "This question is asking for the method you most commonly use when you send your products to your customers. If you do not ship your own products, please select \"Other\" to explain.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Sea transport "
                        },
                        {
                            value: "2",
                            text: "Air transport"
                        },
                        {
                            value: "3",
                            text: "Rail transport"
                        },
                        {
                            value: "4",
                            text: "Road transport"
                        },
                        {
                            value: "5",
                            text: "Other"
                        }
                    ]
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Primarymethod_desc",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1 and {Planet_Logistics_Primarymethod_1.1} = 5",
                    indent: 4,
                    title: "Please provide details",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Lowimpact_1.2",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1",
                    indent: 3,
                    title: "1.2. Do you use any low impact transport solutions?",
                    description: "Low impact transport solutions could include clean or low-impact vehicles such as electric vehicles, hybrid vehicles, use of biofuels, training in efficient fuel techniques etc.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        },
                        {
                            value: "3",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Logistics_Lowimpact_file",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1 and {Planet_Logistics_Lowimpact_1.2} = 1",
                    indent: 4,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include details provided by your transportation provider referencing how they ship products.",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Lowimpact_desc",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1 and {Planet_Logistics_Lowimpact_1.2} = 1",
                    indent: 4,
                    title: "If you do not have something to upload, please describe their process",
                    hideNumber: true,
                    requiredIf: "{Planet_Logistics_Shippingtocust_1} = 1 and {Planet_Logistics_Lowimpact_1.2} = 1 and {Planet_Logistics_Lowimpact_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Monitorimpacts_1.3",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1",
                    indent: 3,
                    title: "1.3. Do you monitor or track the environmental impacts related to this transportation?",
                    description: "You might have recieved a report detailing carbon emissions data from your transport carriers.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Logistics_Monitorimpacts_file",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1 and {Planet_Logistics_Monitorimpacts_1.3} = 1",
                    indent: 4,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Monitorimpacts_desc",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1 and {Planet_Logistics_Monitorimpacts_1.3} = 1",
                    indent: 4,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Planet_Logistics_Shippingtocust_1} = 1 and {Planet_Logistics_Monitorimpacts_1.3} = 1 and {Planet_Logistics_Monitorimpacts_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Sustpackaging_1.4",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1",
                    indent: 3,
                    title: "1.4. Do you use any more sustainable packaging when you send goods to your customers?  ",
                    description: "More sustainable packaging could include recycled content packaging (e.g. rPET), Forest Stewardship Council certified packaging, Terracycle; corrugated boxes/plastics, ISO 14001 accredited packaging, etc. You might have received some information telling you how much of the packaging is recycled content. ",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "4",
                            text: "No - none of our packaging is more sustainably sourced"
                        },
                        {
                            value: "5",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Planet_Logistics_Sustpackaging_num",
                    visibleIf: "{Planet_Logistics_Sustpackaging_1.4} = 1 and {Planet_Logistics_Shippingtocust_1} = 1",
                    indent: 4,
                    title: "Please enter the percentage of more sustainable packaging you use as a number from 0 - 100. ",
                    hideNumber: true,
                    isRequired: true,
                    inputType: "number",
                    min: "0",
                    max: "100"
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Sustpackaging_other",
                    visibleIf: "{Planet_Logistics_Sustpackaging_1.4} = 1",
                    indent: 4,
                    title: "Please provide details of the packaging type you use",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "file",
                    name: "Planet_Logistics_Sustpackaging_file",
                    visibleIf: "{Planet_Logistics_Shippingtocust_1} = 1 and {Planet_Logistics_Sustpackaging_1.4} <= 3",
                    indent: 4,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include information provided by your packaging provider referencing the type of packaging they provide.",
                    hideNumber: true,
                    isRequired: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Shippingfromsupplier_2",
                    title: " Do your suppliers ship finished products directly to you? ",
                    description: "This question is asking whether suppliers send finished products to you directly. It could be that you don't receive finished goods and they are sent directly to a warehouse managed by a third party.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes - our suppliers send finished products to us directly"
                        },
                        {
                            value: "2",
                            text: "No - finished products are sent to a third party fulfilment centre"
                        },
                        {
                            value: "3",
                            text: "Other "
                        }
                    ]
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Shippingfromsupplier_desc",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 3",
                    indent: 3,
                    title: "Please provide details",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Transportmethod_2.1",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1",
                    indent: 3,
                    title: "2.1. What is the primary method suppliers send products to you?  ",
                    description: "This refers to how your company's direct (tier one) suppliers send products to you. ",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Sea transport "
                        },
                        {
                            value: "2",
                            text: "Air transport"
                        },
                        {
                            value: "3",
                            text: "Rail transport"
                        },
                        {
                            value: "4",
                            text: "Road transport"
                        },
                        {
                            value: "5",
                            text: "Other"
                        }
                    ]
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Transportmethod_desc",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1 and {Planet_Logistics_Transportmethod_2.1} = 5",
                    indent: 4,
                    title: "Please provide details",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Greentransport_2.2",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1",
                    indent: 3,
                    title: "2.2. Do any of these companies use low impact transport solutions? ",
                    description: "Low impact transport solutions could include clean or low-impact vehicles such as electric vehicles, hybrid vehicles, use of biofuels, training in efficient fuel techniques etc.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        },
                        {
                            value: "3",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Logistics_Greentransport_file",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1 and {Planet_Logistics_Greentransport_2.2} = 1",
                    indent: 4,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include details provided by a supplier of their transportation provider referencing how they ship products.",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Greentransport_desc",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1 and {Planet_Logistics_Greentransport_2.2} = 1",
                    indent: 4,
                    title: "If you do not have something to upload, please describe their process",
                    hideNumber: true,
                    requiredIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1 and {Planet_Logistics_Greentransport_2.2} = 1 and {Planet_Logistics_Greentransport_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Trackimpacts_2.3",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1",
                    indent: 3,
                    title: "2.3 Do you monitor or track the environmental impacts related to this transportation?",
                    description: "This data includes information about the greenhouse gasses your transport carriers emitted whilst delivering your company's products to you. The transport carriers may have provided you with an emission reports.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Logistics_Trackimpacts_file",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1 and {Planet_Logistics_Trackimpacts_2.3} = 1",
                    indent: 4,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Trackimpacts_desc",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1 and {Planet_Logistics_Trackimpacts_2.3} = 1",
                    indent: 4,
                    title: "If you do not have something to upload, please describe their process",
                    hideNumber: true,
                    requiredIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1 and {Planet_Logistics_Trackimpacts_2.3} = 1 and {Planet_Logistics_Trackimpacts_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Logistics_Supplierpackaging_2.4",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1",
                    indent: 3,
                    title: "2.4. Do your suppliers use more sustainable packaging when sending goods to you? ",
                    description: "More sustainable packaging could include recycled content packaging (e.g. rPET), Forest Stewardship Council certified packaging, Terracycle; corrugated boxes/plastics, ISO 14001 accredited packaging, etc. You might have received some information telling you how much of the packaging is recycled content. ",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "4",
                            text: "No - none of their packaging is more sustainably sourced"
                        },
                        {
                            value: "5",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Planet_Logistics_Supplierpackaging_num",
                    visibleIf: "{Planet_Logistics_Supplierpackaging_2.4} = 1",
                    indent: 4,
                    title: "Please enter the percentage of more sustainable packaging used as a number from 0 - 100 (estimating is ok).",
                    hideNumber: true,
                    isRequired: true,
                    inputType: "number",
                    min: "0",
                    max: "100"
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Supplierpackaging_desc",
                    visibleIf: "{Planet_Logistics_Supplierpackaging_2.4} = 1",
                    indent: 4,
                    title: "Please provide details of the packaging type you use",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "file",
                    name: "Planet_Logistics_Supplierpackaging_file",
                    visibleIf: "{Planet_Logistics_Shippingfromsupplier_2} = 1 and {Planet_Logistics_Supplierpackaging_2.4} <= 3",
                    indent: 4,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include details provided by a supplier of their packaging provider referencing the type of packaging they provide.",
                    hideNumber: true,
                    isRequired: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "text",
                    name: "Planet_Logistics_Suppliervisibility_3",
                    title: "As a percentage, how much visibility do you have of transportation beyond your direct suppliers? ",
                    description: "We are trying to understand if you have any knowledge of how the fibres and fabrics that create your products are transported to your direct (tier one) suppliers. Please enter the number as a percentage from 0 - 100.",
                    isRequired: true,
                    inputType: "number",
                    min: "0",
                    max: "100"
                },
                {
                    type: "file",
                    name: "Planet_Logistics_Suppliervisibility_file",
                    visibleIf: "{Planet_Logistics_Suppliervisibility_3} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    showPreview: false,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Suppliervisibility_desc",
                    visibleIf: "{Planet_Logistics_Suppliervisibility_3} notempty",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Planet_Logistics_Suppliervisibility_file} empty"
                },
                {
                    type: "comment",
                    name: "Planet_Logistics_Other",
                    title: "Is there anything else that you would like to share about GHG emissions from logistics and transportation?",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on how your products are transported and includes how they are shipped and the types of packaging used. "
        }
    ],
    showCompletedPage: false
}