import React from 'react';
import { DataGrid, GridToolbarExport, GridToolbarContainer } from '@material-ui/data-grid';
import { Box } from '@material-ui/core';
import { createRows } from '../../../assets/helpers/ExportFunctions';
import PropTypes from 'prop-types';

// --- CSV/Excel exporter ---

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

// Table column headers
const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Name', minWidth: 170 },
    { field: 'title', headerName: 'Title', flex: 0.5 },
    { field: 'answer', headerName: 'Answer', flex: 0.5 }
];

const CsvExporter = (props) => {
    const { surveyArray } = props;
    return (
        <Box width={window.innerWidth/1.5} height={window.innerHeight/1.5} >
            <DataGrid
                rows={createRows(surveyArray)}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </Box>
    )
};

CsvExporter.propTypes = {
    surveyArray: PropTypes.array,
}

export default CsvExporter;
