export const goalJSON = {
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "checkbox",
                    "name": "Company_topSusAreas",
                    "title": "Which are the top three areas of sustainability that you would most like to improve on? ",
                    "description": "This question aims to understand whether there are any sustainability topics we have covered in this questionnaire that you would like to prioritise and improve on. Please select three. It's ok if you're not sure at this stage - there are no right or wrong answers.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "People in your supply chain: Policies and proccesses"
                        },
                        {
                            "value": "2",
                            "text": "People in your supply chain: Assessments"
                        },
                        {
                            "value": "3",
                            "text": "People in your supply chain: Community"
                        },
                        {
                            "value": "4",
                            "text": "People in your company"
                        },
                        {
                            "value": "5",
                            "text": "Communications and marketing"
                        },
                        {
                            "value": "6",
                            "text": "Environmental measurement"
                        },
                        {
                            "value": "7",
                            "text": "GHG emissions from your own company"
                        },
                        {
                            "value": "8",
                            "text": "GHG emissions from production"
                        },
                        {
                            "value": "9",
                            "text": "GHG emissions from logistics"
                        },
                        {
                            "value": "10",
                            "text": "Water management"
                        },
                        {
                            "value": "11",
                            "text": "Chemical management"
                        },
                        {
                            "value": "12",
                            "text": "Waste management and circularity "
                        },
                        {
                            "value": "14",
                            "text": "Biodiversity"
                        },
                        {
                            "value": "16",
                            "text": "Transparency and traceability"
                        },
                        {
                            "value": "17",
                            "text": "Materials"
                        },
                        {
                            "value": "18",
                            "text": "Animal welfare"
                        },
                        {
                            "value": "19",
                            "text": "Jewellery"
                        }
                    ],
                    "maxSelectedChoices": 3
                },
                {
                    "type": "radiogroup",
                    "name": "Company_futureSustargets",
                    "title": "Do you have any future sustainability targets or commitments?",
                    "description": "We would like to know whether you have set any specific internal goals for your company in relation to sustainability - this could range from creating a strategy roadmap to setting a year by which you hope to achieve a target.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "comment",
                    "name": "Company_futureSustargets_desc",
                    "visibleIf": "{Company_futureSustargets} = 1",
                    "indent": 3,
                    "title": "Please describe",
                    "hideNumber": true,
                    "isRequired": true
                },
                {
                    "type": "radiogroup",
                    "name": "Company_susCosts",
                    "title": "Would you consider adopting more sustainable practices even if they cost more?",
                    "description": "This could be adopting different practices in your supply chain, your internal company operations or how you sell and engage with customers.\n",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes and I would consider absorbing the costs"
                        },
                        {
                            "value": "2",
                            "text": "Yes but I would probably pass the costs onto the customer"
                        },
                        {
                            "value": "3",
                            "text": "No"
                        },
                        {
                            "value": "4",
                            "text": "I don't know as it depends on the price difference"
                        },
                        {
                            "value": "5",
                            "text": "Other"
                        }
                    ]
                },
                {
                    "type": "comment",
                    "name": "Company_susCostsOther",
                    "visibleIf": "{Company_susCosts} = 5",
                    "indent": 3,
                    "title": "Please describe",
                    "hideNumber": true,
                    "isRequired": true
                },
                {
                    "type": "radiogroup",
                    "name": "Company_custSusExp",
                    "title": "Have you found your customers’ expectations around sustainability are changing? ",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        },
                        {
                            "value": "3",
                            "text": "I don't know"
                        }
                    ]
                },
                {
                    "type": "comment",
                    "name": "Company_custSusExp_desc",
                    "visibleIf": "{Company_custSusExp} = 1",
                    "indent": 3,
                    "title": "Please describe",
                    "hideNumber": true
                },
                {
                    "type": "radiogroup",
                    "name": "Company_custSusExpComp",
                    "title": "Do you think your customers have the same expectations of you as an independent brand as they do of a larger high-street brand?",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        },
                        {
                            "value": "3",
                            "text": "I don't know"
                        }
                    ]
                },
                {
                    "type": "comment",
                    "name": "Company_custSusExpComp_desc",
                    "visibleIf": "{Company_custSusExpComp} = 1",
                    "indent": 3,
                    "title": "Please describe",
                    "hideNumber": true
                },
                {
                    "type": "comment",
                    "name": "Company_whySusImp",
                    "title": "Why is sustainability important to your brand? ",
                    "description": "For example, it might be important to you personally or because your customers are telling you it is a topic they care about."
                },
                {
                    "type": "comment",
                    "name": "Company_susProud",
                    "title": "What one thing are you most proud of on your sustainability journey so far?",
                    "description": "If there's something you've done that you're particularly proud of then we'd love to hear about."
                },
                {
                    "type": "comment",
                    "name": "Company_susNextFocus",
                    "title": "What is the one thing you’d like to do next on your sustainability journey?",
                    "description": "We'd love to know about any future sustainability actions you either already have planned or that you'd like to do (even if you're not sure how to get started)."
                },
                {
                    "type": "rating",
                    "name": "Company_susLiteracy",
                    "title": "On a scale of 1 to 10, how comfortable do you feel on the topics covered in this questionnaire?",
                    "description": "1 - I feel out of my depth as this is all new; 10 - I feel clued up and confident that I have good understanding",
                    "rateMax": 10
                },
                {
                    "type": "comment",
                    "name": "Company_susOther",
                    "title": "Is there anything else you would like to say that hasn't been captured anywhere else in this assessment? ",
                    "hideNumber": true
                }
            ],
            "descriptionEdit": "These final questions aim to get a sense of how you feel you're doing on your sustainability journey and whether there are any areas of sustainability that you are specifically interested in."
        }
    ],
    "showCompletedPage": false
}
