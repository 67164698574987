import React, { useEffect } from 'react';
import { Page } from "../../../UI/templates/Page";
import {
    TextField,
    Box,
    Button,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Auth, API } from 'aws-amplify';
import { useStyles } from '../../../UI/styles/themes';
import { eliminateSpaces } from '../../../assets/helpers';

const BrandSignup = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const history = useHistory();
    const [token, setToken] = useState();
    const accountName = process.env.REACT_APP_ACCOUNT_NAME || "";
    const [formData, setFormData] = useState({
        createdBy: "",
        name: "",
        brandCode: "",
        account: accountName,
    });

    // set form data userName to email of current user
    useEffect(() => {
        Auth.currentSession()
            .then((userSession) => {
                setFormData({...formData, createdBy: userSession.idToken.payload.email});
                setToken(userSession.idToken.jwtToken);
            })
    }, []);

    const myInit = {
        method: 'POST',
        headers: {
            Authorization : token,
        },
        body: formData
    };

    const submitBrand = () => {
        API.post('surveyapi', '/brands', myInit)
            .then(() => {
                alert('Brand submission complete');
                history.push('/');
            } )
            .catch(err => {
                setErrorMessage(err.message);
            })
    };

    const classes = useStyles();

    return (
        <Page>
            <Paper elevation={3}>
                <Box className={classes.mainContainer} width={470}>
                    <Box
                        textAlign="center"
                        color="#1A2259"
                        mb='34px'
                    >
                        <Typography variant="h3">Onboard a new brand</Typography>
                    </Box>
                    <Box display='flex' flexDirection='column' alignItems='center' width={1} px="30px">
                        <Box py={1} width={1}>
                            {/* Brand Name */}
                            <TextField
                                id="standard-required"
                                required
                                label="Brand name"
                                value={formData.name}
                                onChange={(event) => setFormData({...formData, name: event.target.value})}
                                // error
                                // helperText={errorMessage.fullName && "required"}
                                fullWidth
                                margin="dense"
                                variant='outlined'
                            />
                        </Box>

                        {/* Brand Code */}
                        <Box py={1} width={1}>
                            <TextField
                                id="standard-required"
                                required
                                label="Brand code"
                                value={formData.brandCode}
                                onChange={(event) => setFormData({...formData, brandCode: eliminateSpaces(event.target.value)})}
                                // error
                                // helperText=
                                fullWidth
                                margin="dense"
                                variant='outlined'
                            />
                        </Box>

                        {/* Account Name */}
                        <Box py={1} width={1}>
                            <FormControl
                                required
                                className={classes.formControl}
                                // error
                                fullWidth
                                margin="dense"
                                variant='outlined'
                            >
                                <InputLabel id="standard-required-select-label">Account name</InputLabel>
                                <Select
                                    labelId="standard-required-select-label"
                                    id="standard-required-select"
                                    label="Account"
                                    value={formData.account}
                                    onChange={(event) => setFormData({...formData, account: event.target.value})}
                                    // helperText=
                                >
                                    <MenuItem value={'silkFred'}>SilkFred</MenuItem>
                                    <MenuItem value={'general'}>General</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>


                        <Box py={1} width={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitBrand}
                                className={classes.button}
                                size="large"
                            >
              Sign Up Brand
                            </Button>
                        </Box>
                        {errorMessage && (
                            <Box py={2}>
                                <Typography variant="body1" color="primary">
                                    {errorMessage}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Paper>
        </Page>
    )
}

export default BrandSignup