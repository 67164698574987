import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const SurveyDataContext = createContext({
    surveyData: [],
    setSurveyData: () => {},
});

const SurveyDataProvider = (props) => {
    const { children } = props;
    const [surveyData, setSurveyData] = useState([]);

    return (
        <SurveyDataContext.Provider value={{ surveyData, setSurveyData }}>
            {children}
        </SurveyDataContext.Provider>
    );
};

SurveyDataProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export { SurveyDataProvider, SurveyDataContext };