import React, { useState, useContext, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import SurveySectionButton from '../../../components/SurveySectionButton';
import WelcomePage from '../WelcomePage';
import { ThemeProvider } from '@material-ui/core';
import { font2Theme, useStyles } from '../../../UI/styles/themes';
import { ReactComponent as CompanyIcon } from '../../../assets/svg/homePage/companyIcon.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/svg/homePage/peopleIcon.svg';
import { ReactComponent as PlanetIcon } from '../../../assets/svg/homePage/planetIcon.svg';
import { ReactComponent as SourcingIcon } from '../../../assets/svg/homePage/sourcingIcon.svg';
import { ReactComponent as YourGoalsIcon } from '../../../assets/svg/homePage/yourGoalsIcon.svg';
import { SurveyDataContext } from '../../../assets/contexts/SurveyData';
import { ReactComponent as CompletedIcon } from '../../../assets/svg/completed.svg';
import { useUserStore } from '../../../assets/state';

const BrandStartPage = () => {
    const [planetCompleted, setPlanetCompleted] = useState(false);
    const [peopleCompleted, setPeopleCompleted] = useState(false);
    const [sourcingCompleted, setSourcingCompleted] = useState(false);
    const [yourGoalsCompleted, setYourGoalsCompleted] = useState(false);
    const [companyCompleted, setCompanyCompleted] = useState(false);
    const {surveyData} = useContext(SurveyDataContext);
    const userBrand = useUserStore((state) => state.userBrand);
    const surveyCompleted =  [companyCompleted, peopleCompleted, planetCompleted, yourGoalsCompleted, sourcingCompleted].every(state => state === true);

    useEffect(() => {
        const checkWholeSectionIsComplete = async(section, subsections) => {
            return subsections.every(subsection => {
                return surveyData.some(survey => survey.surveyName === `${section}_${subsection}`)
            })
        }

        // Update global state depending on the local storage data on each subsection
        const peopleResult = checkWholeSectionIsComplete('People', ['Processes', 'Assessments', 'Community', 'Company', 'Communication']);
        setPeopleCompleted(peopleResult);

        const companyResult = checkWholeSectionIsComplete('Company', ['Companydetails']);
        setCompanyCompleted(companyResult);

        const planetResult = checkWholeSectionIsComplete('Planet', ['Environmentalmanagement', 'Companyemissions', 'Productionemissions', 'Logisticsemissions', 'Watermanagement', 'Chemicalmanagement', 'Waste&circularity', 'Biodiversity']);
        setPlanetCompleted(planetResult);

        const sourcingResult = checkWholeSectionIsComplete('Sourcing', ['Transparency&traceability', 'Materials', 'Animalwelfare', 'Jewellery']);
        setSourcingCompleted(sourcingResult);

        const yourGoalsResult = checkWholeSectionIsComplete('Yourgoals', ['']);
        setYourGoalsCompleted(yourGoalsResult);
    }, [surveyData ]);

    const classes = useStyles();

    return (
        <Box display="flex" justifyContent="center" alignItems="center" pb="142px">
            <Box width={761}>
                <Box display="flex" color="offBlack.main" flexDirection="column" alignItems="center" mt="55px">
                    <ThemeProvider theme={font2Theme}>
                        {
                            surveyCompleted ?
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    justifyContent="center"
                                    position="relative"
                                    width={1}>
                                    <CompletedIcon fill="#6FCF97" style={{left: '35%', position: 'absolute'}} />
                                    <Typography className={classes.H600}>Success!</Typography>
                                </Box> :
                                <Box mb='30px'>
                                    <Typography className={classes.H600}>Welcome!</Typography>
                                </Box>
                        }
                    </ThemeProvider>
                    <Box mt="12px" mb="20px" >
                        <Typography style={{color: "#5E6596", fontSize: '15px', marginBottom: '22px'}} variant="subtitle1">
                            {
                                surveyCompleted ?
                                    'Thanks for completing this Sustainability Assessment - there is no further action required from you now. We will go through your responses and get back to you soon!' :
                                    "This is Sustainability Assessment. Answering the questions will help us understand your brand's strengths & identify potential improvement areas to help you reach your sustainability goals."
                            }
                        </Typography>
                        <SourcingIcon style={{width: '56px', height: '56px', display: 'none'}} />
                        <Box style={{color: "#838791", fontSize: '12px', textAlign: "center"}} display="flex" flexDirection='column' alignItems="center" p='0px 3px'>
                            {
                                surveyCompleted && (
                                    <ul style={{margin: '3px 0 3px 0', paddingLeft: '20px', display: "flex", flexDirection: 'column', alignItems: 'center'}}>
                                        <li>
                                            <Typography style={{fontSize: '12px', lineHeight: '175%'}}>
                                                <span style={{
                                                    color: '#5E6596',
                                                    fontWeight: 'bold'
                                                }}>➡️  What happens next? : </span>{`We will use answers & evidence you have provided to calculate a unique sustainability score. This score is a means to assess where you currently are & to suggest steps you could take to improve your sustainability performance. We will communicate your score to you by email & we can discuss this in more detail during a follow-up call.`}
                                            </Typography>
                                        </li>
                                    </ul>
                                )
                            }
                        </Box>
                    </Box>
                    {
                        userBrand?.surveyStatus === 'None' ? <WelcomePage /> : !surveyCompleted && (
                            <>
                                <SurveySectionButton
                                    sectionName='Your company'
                                    url='company'
                                    isCompleted={companyCompleted}
                                    number='1'
                                    description='General questions about your company to help us contextualise your answers to the assessment.'
                                >
                                    <CompanyIcon fill="#F6F2FC"/>
                                </SurveySectionButton>

                                <SurveySectionButton
                                    sectionName='Planet'
                                    url='planet/environmentalmanagement'
                                    isCompleted={planetCompleted}
                                    number='2'
                                    description='Questions on various environmental aspects of your company & the supply chains involved.'
                                >
                                    <PlanetIcon />
                                </SurveySectionButton>

                                <SurveySectionButton
                                    sectionName='People'
                                    url='people/processes'
                                    isCompleted={peopleCompleted}
                                    number='3'
                                    description='Questions about the initiatives taken by your company to promote the well-being of workers.'
                                >
                                    <PeopleIcon />
                                </SurveySectionButton>

                                <SurveySectionButton
                                    sectionName='Sourcing'
                                    url={`sourcing/transparency&traceability`}
                                    isCompleted={sourcingCompleted}
                                    number='4'
                                    description='Questions that focus on how your company sources its goods and raw materials.'
                                >
                                    <SourcingIcon style={{width: '56px', height: '56px'}} />
                                </SurveySectionButton>

                                <SurveySectionButton
                                    sectionName='Your goals'
                                    url='yourgoals'
                                    isCompleted={yourGoalsCompleted}
                                    number='5'
                                    description='Final questions focus on the sustainability areas in which you would like more support.'
                                >
                                    <YourGoalsIcon fill="#50545E" style={{width: '56px', height: '56px'}} />
                                </SurveySectionButton>
                            </>
                        )
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default BrandStartPage