import { compDetJSON } from '../../../assets/surveyJSON/company/companyDetails';
// people
import { assessmentsJSON } from '../../../assets/surveyJSON/people/assessments';
import { communicationJSON } from '../../../assets/surveyJSON/people/communication';
import { communityJSON } from '../../../assets/surveyJSON/people/community';
import { companyJSON } from '../../../assets/surveyJSON/people/company';
import { procJSON } from '../../../assets/surveyJSON/people/processes';
// planet
import { bioJSON } from '../../../assets/surveyJSON/planet/biodiversity';
import { chemManJSON } from '../../../assets/surveyJSON/planet/chemicalManagement';
import { compEmissJSON } from '../../../assets/surveyJSON/planet/companyEmissions';
import { environmentalMgtJSON } from '../../../assets/surveyJSON/planet/environmentalManagement';
import { logEmissJSON } from '../../../assets/surveyJSON/planet/logisticsEmissions';
import { prodEmissJSON } from '../../../assets/surveyJSON/planet/productionEmissions';
import { wasCircJSON } from '../../../assets/surveyJSON/planet/wasteCircularity';
import { waterMgtJSON } from '../../../assets/surveyJSON/planet/waterManagement';
// sourcing
import { aniWelJSON } from '../../../assets/surveyJSON/sourcing/animalWelfare';
import { jewelleryJSON } from '../../../assets/surveyJSON/sourcing/jewellery';
import { matJSON } from '../../../assets/surveyJSON/sourcing/materials';
import { transparencyTraceJSON } from '../../../assets/surveyJSON/sourcing/transparencyTraceability';
// your goals
import { goalJSON } from '../../../assets/surveyJSON/yourGoals/goals';

export const surveysNew = {
    Company_Companydetails: compDetJSON,
    People_Assessments: assessmentsJSON,
    People_Communication: communicationJSON,
    People_Community: communityJSON,
    People_Company: companyJSON,
    People_Processes: procJSON,
    Planet_Biodiversity: bioJSON,
    Planet_Chemicalmanagement: chemManJSON,
    Planet_Companyemissions: compEmissJSON,
    Planet_Environmentalmanagement: environmentalMgtJSON,
    Planet_Logisticsemissions: logEmissJSON,
    Planet_Productionemissions: prodEmissJSON,
    [`Planet_Waste&circularity`]: wasCircJSON,
    Planet_Watermanagement: waterMgtJSON,
    Sourcing_Animalwelfare: aniWelJSON,
    Sourcing_Jewellery: jewelleryJSON,
    Sourcing_Materials: matJSON,
    [`Sourcing_Transparency&traceability`]: transparencyTraceJSON,
    Yourgoals_: goalJSON
};