export const compEmissJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "Planet_Compghg_Record_1",
                    title: "Does your company monitor, record, or report on the emission of greenhouse gases from your operations ?  ",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "Planet_Compghg_Renewable_2",
                    visibleIf: "{Planet_Compghg_Record_1} = 1",
                    title: "Is the energy you use (such as electricity or gas) from renewable or low-impact sources? ",
                    description: "This question is asking whether your company uses energy from renewable sources such as wind or solar. Your electricity supplier will be able to tell you this if you are not sure.",
                    isRequired: true,
                    choices: [
                        {
                            value: "item1",
                            text: "Yes - all our electricity is from renewable sources "
                        },
                        {
                            value: "item2",
                            text: "Yes - all our gas is carbon neutral"
                        },
                        {
                            value: "item3",
                            text: "No"
                        }
                    ],
                    hasOther: true,
                    otherText: "I don't know"
                },
                {
                    type: "file",
                    name: "Planet_Compghg_Renewable_file",
                    visibleIf: "{Planet_Compghg_Record_1} = 1 and {Planet_Compghg_Renewable_2} anyof ['item2', 'item1']",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include a recent dated bill from your energy supplier.",
                    hideNumber: true,
                    isRequired: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "radiogroup",
                    name: "Planet_Compghg_Offset_3",
                    visibleIf: "{Planet_Compghg_Record_1} = 1",
                    title: "Have you ever undertaken any GHG emission offset activities?  ",
                    description: "Offset activities include practices that balance or offset the amount of GHG emissions released into the atmosphere. Examples of offset activites include purchasing carbon credits, supporting reforestation projects, etc.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Compghg_Offset_file",
                    visibleIf: "{Planet_Compghg_Offset_3} = 1 and {Planet_Compghg_Record_1} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include purchase of offset credits.",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "text",
                    name: "Planet_Compghg_Offset_desc",
                    visibleIf: "{Planet_Compghg_Record_1} = 1 and {Planet_Compghg_Offset_3} = 1",
                    indent: 3,
                    title: "Please provide details",
                    hideNumber: true,
                    requiredIf: "{Planet_Compghg_Offset_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Compghg_Reduction_4",
                    visibleIf: "{Planet_Compghg_Record_1} = 1",
                    title: "Have you ever undertaken any GHG emission reduction activities?  ",
                    description: "Reduction activities include practices that reduce or limit the amount of GHG emissions released into the atmosphere. Examples of reduction activites include using renewable energy sources, taking trains rather than flying, etc.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Compghg_Reduction_file",
                    visibleIf: "{Planet_Compghg_Reduction_4} = 1 and {Planet_Compghg_Record_1} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include purchase of offset credits.",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "text",
                    name: "Planet_Compghg_Reduction_desc",
                    visibleIf: "{Planet_Compghg_Record_1} = 1 and {Planet_Compghg_Reduction_4} = 1",
                    indent: 3,
                    title: "Please provide details",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "radiogroup",
                    name: "Planet_Compghg_Scope1_5",
                    visibleIf: "({Planet_Compghg_Record_1} == 1)",
                    title: "Have you ever calculated your Scope 1 GHG emissions?  ",
                    description: "Scope 1 emissions are direct greenhouse gas emissions that come from sources controlled or owned by your company and your operations. This could include any company vehicles as well as any on-site manfuacturing you do yourself. It is likely that your brand will not have many GHG emissions that fall into Scope 1.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Compghg_Scope1_file",
                    visibleIf: "{Planet_Compghg_Scope1_5} = 1 and {Planet_Compghg_Record_1} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include purchase of offset credits.",
                    hideNumber: true,
                    isRequired: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "text",
                    name: "Planet_Compghg_Scope1_num",
                    visibleIf: "(({Planet_Compghg_Scope1_5} == 1) and ({Planet_Compghg_Record_1} == 1))",
                    indent: 3,
                    title: "Please provide the value in metric tonnes of carbon dioxide equivalent - CO2e",
                    hideNumber: true,
                    isRequired: true,
                    inputType: "number"
                },
                {
                    type: "text",
                    name: "Planet_Compghg_Scope1_year",
                    visibleIf: "(({Planet_Compghg_Scope1_5} == 1) and ({Planet_Compghg_Record_1} == 1))",
                    indent: 3,
                    title: "When was this calculated? (Year)",
                    hideNumber: true,
                    isRequired: true,
                    inputType: "number"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Compghg_Scope2_6",
                    visibleIf: "({Planet_Compghg_Record_1} == 1)",
                    title: "Have you ever calculated your Scope 2 GHG emissions? ",
                    description: "Scope 2 emissions are indirect greenhouse gas emissions from the generation of purchased energy (that is probably supplied to you by your energy provider). This is likely to be the electricity you buy that keeps the lights on in your office, but can also include heat and cooling. It is likely that the majority of the GHG emissions resulting from your brand's operations fall into Scope 2.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Planet_Compghg_Scope2_num",
                    visibleIf: "(({Planet_Compghg_Record_1} == 1) and ({Planet_Compghg_Scope2_6} == 1))",
                    indent: 3,
                    title: "Please provide the value in metric tonnes of carbon dioxide equivalent - CO2e",
                    hideNumber: true,
                    isRequired: true,
                    inputType: "number"
                },
                {
                    type: "text",
                    name: "Planet_Compghg_Scope2_year",
                    visibleIf: "(({Planet_Compghg_Scope2_6} == 1) and ({Planet_Compghg_Record_1} == 1))",
                    indent: 3,
                    title: "When was this calculated? (Year)",
                    hideNumber: true,
                    isRequired: true,
                    inputType: "number"
                },
                {
                    type: "file",
                    name: "Planet_Compghg_Scope2_file",
                    visibleIf: "{Planet_Compghg_Scope2_6} = 1 and {Planet_Compghg_Record_1} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include purchase of offset credits.",
                    hideNumber: true,
                    isRequired: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Compghg_Other",
                    visibleIf: "{Planet_Compghg_Record_1} = 1",
                    title: "Is there anything else that you would like to share about GHG emissions from your company? ",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on the greenhouse gases emitted by your company's direct operations - for example electricity, heating, and any manufacturing you do yourself."
        }
    ],
    showCompletedPage: false
}