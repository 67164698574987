import React from 'react'
import { Box, Typography } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/biodiversity.jpeg';

const Biodiversity = () => {
    const refs = [
        "Biodiversity 2020: A strategy for England’s Wildlife and Ecosystem Services, Department for Environment Food and Rural Affairs, 2020: https://www.gov.uk/government/publications/biodiversity-2020-a-strategy-for-england-s-wildlife-and-ecosystem-services",
        "Nature in Freefall: How Fashion Contributes to Biodiversity Loss, Fashion Revolution, 2020: https://www.fashionrevolution.org/nature-in-freefall/",
        "Living Planet Report 2020, WWF, 2020: https://livingplanet.panda.org/en-gb/",
        "Living Planet Report 2020, WWF, 2020: https://livingplanet.panda.org/en-gb/",
        "Biodiversity & Brands - A ProvenanceLive Recap, 2020: https://www.commonobjective.co/article/biodiversity-brands-a-provenancelive-recap",
        "Fashion on Climate, McKinsey, 2020: https://www.mckinsey.com/industries/retail/our-insights/fashion-on-climate",
        "Biodiversity The Next Frontier in Sustainable Fashion, McKinsey, 2020: https://www.mckinsey.com/industries/retail/our-insights/biodiversity-the-next-frontier-in-sustainable-fashion",
        "Introduction Fashion and Biodiversity, Fashion Values, no date: https://fashionvalues.org/methods/Introduction-Fashion-and-Biodiversity",
        "CanopyStyle, Canopy, no date: https://canopyplanet.org/campaigns/canopystyle/",
        "The Fashion Pact: First Steps to Transform Our Industry, The Fashion Pact, 2020: https://thefashionpact.org/?lang=en",
        "Deforestation, Survival International, no date: https://www.survivalinternational.org/about/deforestation",
        "Nature in Freefall: How Fashion Contributes to Biodiversity Loss, Fashion Revolution, 2020: https://www.fashionrevolution.org/nature-in-freefall/",
        "How Much Do Our Wardrobes Cost to the Environment?, World Bank, 2019:  https://www.worldbank.org/en/news/feature/2019/09/23/costo-moda-medio-ambiente",
        "Our Clothes Shed Microfibres Heres What We Can Do, Fashion Revolution, 2019: https://www.fashionrevolution.org/our-clothes-shed-microfibres-heres-what-we-can-do/",
        "CanopyStyle adds new brands to take it to over 400, Canopy, 2021: https://fashionunited.uk/news/business/canopystyle-adds-new-brands-to-take-it-to-over-400/2021091657748",
        "Sustainable Fashion Glossary, Condé Nast, no date: https://www.condenast.com/glossary/climate-emergency/regenerative-agriculture",
        "The Fashion Pact: First Steps to Transform Our Industry, The Fashion Pact, 2020: https://thefashionpact.org/?lang=en"
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title="Biodiversity" isMain={true}/>

            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>

                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>

                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>
              We may be more familiar with the term climate crisis. However, we are equally in the midst of a major biodiversity crisis.
                            <br /><br />
              Biodiversity simply put is the “variety of all life on Earth. It includes all species of animals and plants – everything that is alive on our planet”<sup>1</sup>. It also includes fungi and microorganisms like bacteria. Each individual species contributes to their own local ecosystem, which in turn supports the wider global ecosystem.
                        </Typography>
                    </Box>
                </Box>
                <Typography style={{fontSize: "1.1rem"}}>
          Biodiversity also refers to genetic diversity within species. As populations decline so does genetic diversity, reducing the ability for a species to adapt to changes to its environment<sup>2</sup>.
                    <br/><br/>
          A recent World Wildlife Fund (WWF) report rang alarm bells, finding there has been an average 68% decline globally in the size of animal populations since the 1970s<sup>3</sup>.
                    <br/><br/>
          The biggest threats to biodiversity vary across the world but are caused by numerous human-caused interconnected factors, including:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Changes to land and sea use, including unsustainable agriculture, tree logging, transportation, urbanisation, energy production and mining</li>
                    <li style={{margin: "10px 0"}}>Species overexploitation</li>
                    <li style={{margin: "10px 0"}}>Invasive species and disease</li>
                    <li style={{margin: "10px 0"}}>Climate change</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
        Human activity is the driving force behind these impacts, with research showing that humans are overusing the earth&lsquo;s biocapacity – the ability of the planet’s ecosystems to regenerate – by more than 50%<sup>4</sup>.
                    <br/><br/>
        We urgently need to find alternative, nature based solutions for preserving our ecosystems to support biodiversity rather than exploit and degrade it.
                </Typography>

                <ResourceTitle title={`What is the connection between biodiversity and fashion?`} refs="5 6 7 8" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
          There has been a lot of discussion on the fashion industry’s impact on climate change. However, efforts to address the industry&lsquo;s heavy biodiversity footprint are growing. Let’s unpack the key impacts:
                </Typography>

                <ol style={{margin: "10px 0"}}>
                    <li style={{margin: "20px 0"}}>
                        <span style={{fontWeight: "600"}}>Changing land use</span><br />
            A significant amount of the clothing we wear begins its life on farms and forests as so-called natural fibres such as cotton, linen, wool, and viscose/rayon (made from the dissolved pulp of trees). A large proportion of the fashion industry&lsquo;s impact on biodiversity is caused by habitat change due to agricultural processes used to produce these materials.
                        <br /><br />
            The fashion industry is a major contributor to global deforestation. According to the forest protection agency Canopy, <span style={{fontWeight: "600"}}>more than 200 million trees</span>are logged every year and turned into cellulosic fabrics like viscose. To put that in perspective, if all of those trees were placed end-to-end they would circle the Earth seven times<sup>9</sup>.
                        <br /><br />
            The use of leather in the fashion industry is also an issue because rearing cattle for leather export requires vast stretches of forest to be cleared<sup>10</sup>.
                        <br /><br />
            Deforestation does not only impact the environment, but also has a human impact with indigenous peoples around the world being driven from their land<sup>11</sup>.
                        <br /><br />
            The demand for raw materials for clothing is set to increase, with the fashion industry projected to use 35% more land for fibre production by 2030<sup>12</sup>.
                    </li>

                    <li style={{margin: "20px 0"}}>
                        <span style={{fontWeight: "600"}}>Pollution</span><br />
            The extent of fashion’s pollution impact is unknown, but a significant amount is linked to wet processing stages of textiles production (including the scouring, bleaching, dyeing, printing and finishing of textiles which are water and chemical intensive)<sup>13</sup>.
                        <br /><br />
            The chemicals used for the production of natural fibres (such as cotton) have been shown to reduce the population of vital species and degrade soil.
                        <br /><br />
            There is now large concern regarding microplastic pollution - tiny pieces of plastic that are released when we wash clothing made from synthetic materials. These microfibres detach from our clothing during washing and end up in wastewater, which then ends up in the sewage system. These fibres are so small that they can pass through filtration processes and are polluting our rivers and seas, adversely impacting marine ecosystems.<sup>14</sup>
                    </li>

                    <li style={{margin: "20px 0"}}>
                        <span style={{fontWeight: "600"}}>Climate change</span><br />
            The fashion industry is a significant contributor to the greenhouse gas emissions that are warming our planet. Biodiversity and the climate are mutually dependent and reinforcing – one accelerates the other and vice versa.
                        <br /><br />
            The world’s rainforests are vital for capturing carbon dioxide that is released into the atmosphere. Their decline will accelerate global warming as less carbon is absorbed. Similarly, climate change is already having a huge impact on biodiversity as it alters temperatures and weather patterns, which is catastrophic for our natural ecosystems as it makes them inhospitable to local species of plants, insects, fungi, animals and humans.
                    </li>
                </ol>

                <Typography style={{fontSize: "1.1rem"}}>The current state of the fashion industry is marked by overproduction and overconsumption, which are all drivers of biodiversity loss as they underlie land-use change, habitat loss,  pollution, climate change and the overexploitation of natural resources.</Typography>

                <ResourceTitle title="Why is this important?" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
        Biodiversity is the foundation of the world&lsquo;s ecosystems and each ecosystem provides a range of benefits for us and our planet, including:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Food and energy sources</li>
                    <li style={{margin: "10px 0"}}>The purification of air, water and soil</li>
                    <li style={{margin: "10px 0"}}>Nutrient cycling (return of nutrients used by plants back into the soil)</li>
                    <li style={{margin: "10px 0"}}>Regulations of floods and diseases</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>When biodiversity is lost, we lose the ecosystems that humans rely on to maintain our lives - from the water we drink to the food we eat and the air we breathe.</Typography>

                <ResourceTitle title="What does good look like?" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
          Biodiversity is made up of multiple systems and its decline impacts a range of areas from forests to soil, rivers, lakes and seas. This variability means we need several metrics to measure impacts and progress as well as a range of solutions. This is inherently more challenging than focusing on a singular area such as carbon emissions. But just because something may require more work does not mean it is not worth the effort.
                    <br /><br />
          A key example of this is the <a href="https://canopyplanet.org/campaigns/canopystyle/" target="_blank" rel="noopener noreferrer">CanopyStyle</a> campaign created by the environmental organisation, Canopy, in collaboration with over 400 fashion brands, retailers and key fashion industry players. This initiative has aimed to protect the world&lsquo;s ancient and endangered forest from unsustainable practices within viscose supply chains, which are closely connected to the fashion industry. Since its inception in 2014, ConopyStyle has seen producers who are responsible for 90% of the viscose supply chain develop commitments to stop sourcing from the world&lsquo;s ancient and endangered forest<sup>15</sup>.
                    <br /><br />
          52% of global viscose production can now be classed as ‘green shirt’, meaning those viscose manufacturers are classed as low risk for sourcing from ancient and endangered forests.
                    <br /><br />
          ConopyStyle is also working with several major viscose producers to create material solutions using viscose from recycled textiles waste, instead of extracting it from finite natural resources.
                </Typography>

                <Typography style={{
                    fontSize: "1.2rem",
                    textDecoration: "underline",
                    fontWeight: "600",
                    margin: "20px 0px"
                }}>What are possible next steps?</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>
                        <a href="https://textileexchange.org/" target="_blank" rel="noopener noreferrer">Textiles Exchange</a> is a non-profit membership organisation that you can join to support the adoption of more sustainable and circular materials. Membership connects you with a global community of brands, retailers and suppliers, ranging in size from across the textiles supply chain. Members gain access to learning opportunities, certifications tools, relevant data, insight reports, and industry networks. They are currently devising specific tools and guidance that will support members to take biodiversity into account in their material sourcing and production practices.
                    </li>
                    <li style={{margin: "10px 0"}}>
                        <a href="https://www.rainforest-alliance.org/business/forest-allies/" target="_blank" rel="noopener noreferrer">Rainforest Alliance</a> is an international non-profit organisation working at the intersection of business, agriculture, and forests to make responsible business the new normal. Companies who join become ‘Forest Allies’ working with local forest communities to protect, restore, and sustainably manage tropical forests.
                    </li>
                    <li style={{margin: "10px 0"}}>
                        <a href="https://members.fsc.org/en/Members" target="_blank" rel="noopener noreferrer">Forest Stewardship Council</a> is a non-profit organisation that promotes responsible management of the world&lsquo;s forest. Companies working to protect forests are able to complete their well known Forest Stewardship Certification (FSC).
                    </li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Design and product development</span><br />
        Taking the decision to consider biodiversity in the design process redirects priorities from a human-centric to a nature-centric approach. This means linking aesthetics, price points and availability to environmental criteria. Simply put: you begin to ask ‘what does nature need?’
        The steps you need to take will encompass a range of areas in sustainability, including waste, pollution, emissions, water, chemicals, circularity and transparency and traceability, as they are part of a broader shift towards a more sustainable business model. Therefore, we encourage you to review all of these 101 resources to build a holistic approach to sustainability.
                    <br /><br />
                    <span style={{fontWeight: "600"}}>Sourcing and diversifying fibres</span><br />
        In order to reduce stress on ecosystems, you can think about diversifying the types of fibres you use to make your products by using renewable sources, recycled materials and agricultural waste. Having a larger range of fibres is vital for protecting biodiversity because it reduces intensive monocropping (use of large areas of land for a singular crop) and reduces our dependence on a limited number of crop types.
                    <br /><br />
        In addition to this, aim to develop sourcing strategies that are informed by environmental standards that prioritise biodiversity.
                </Typography>

                <ol style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>
        Look into choosing certified lower-impact materials - some of these include:
                        <ul>
                            <li><a href="https://global-standard.org/" target="_blank" rel="noopener noreferrer">Global Organic Textiles Standard (GOTS)</a></li>
                            <li><a href="https://textileexchange.org/standards/organic-content-standard/" target="_blank" rel="noopener noreferrer">Organic Content Standard (OCS)</a></li>
                            <li><a href="https://www.leatherworkinggroup.com/" target="_blank" rel="noopener noreferrer">Leather Working Group</a></li>
                            <li><a href="https://textileexchange.org/standards/responsible-wool/" target="_blank" rel="noopener noreferrer">Responsible Wool Standard (RWS)</a></li>
                            <li><a href="https://textileexchange.org/standards/recycled-claim-standard-global-recycled-standard/" target="_blank" rel="noopener noreferrer">Recycled Claim Standard (RCS)</a></li>
                            <li><a href="https://textileexchange.org/standards/recycled-claim-standard-global-recycled-standard/" target="_blank" rel="noopener noreferrer">Global Recycled Standard (GRS)</a></li>
                            <li><a href="https://www.rainforest-alliance.org/tag/2020-certification-program/" target="_blank" rel="noopener noreferrer">Sustainable Agriculture Standard (SAS)</a></li>
                            <li><a href="https://fsc.org/en/forest-management-certification" target="_blank" rel="noopener noreferrer">Forest Management Certification</a></li>
                        </ul>
                    </li>
                    <li style={{margin: "10px 0"}}>Eliminate or phase out non-renewable materials, conventional cotton, synthetics, and regenerated cellulosics derived from high risk sources (e.g. those from ancient and endangered forests).</li>
                    <li style={{margin: "10px 0"}}>Position sourcing and materials selection early into the design process, so designs can respond to materials rather than driving material choice.</li>
                    <li style={{margin: "10px 0"}}>Check out Kerings informative guide for <a href="https://www.kering.com/en/news/kering-open-sources-new-suite-of-industry-leading-standards" target="_blank" rel="noopener noreferrer">raw material standards</a>. It provides detailed information on how to improve sustainability at the raw materials stage of your production process.</li>
                </ol>

                <Typography style={{fontSize: "1.1rem", margin: "20px 0px"}}>
                    <span style={{fontWeight: "600"}}>Regenerative agriculture</span><br />
                    <a href="https://www.condenast.com/glossary/climate-emergency/regenerative-agriculture" target="_blank" rel="noopener noreferrer">Regenerative agriculture</a> is a farming method that combines conservation and rehabilitation to restore, renew and revitalise agricultural landscapes and their ecosystem<sup>16</sup>. The aim here is for farming practices to regenerate the land, by recognising the mutual interdependence of all human and natural systems. This leads to a more efficient use of land and resources, higher-quality crops and soil, and it protects biodiversity by supporting ecosystems rather than destroying them. Regenerative farming differs from the large-scale industrial farming that we are more familiar with, as it rejects chemical fertilisers and pesticides and the monocropping of genetically modified crops.
                    <br /><br />
        Regenerative farming is still an emerging area within the fashion industry, however, it holds a lot of potential to make a positive impact where raw materials like cotton, linen, hemp, and wool begin their lives.
                    <br /><br />
        If this is something you would like to work towards you could think about sourcing from farms and suppliers certified by regenerative standards, such as  <a href="https://savory.global/land-to-market/eov/" target="_blank" rel="noopener noreferrer">EOV</a> or <a href="https://regenorganic.org/" target="_blank" rel="noopener noreferrer">Regenerative Organic Certified Standard</a>.
                </Typography>

                <Typography style={{fontSize: "1.1rem", margin: "20px 0px"}}>
                    <span style={{fontWeight: "600"}}>Devise a biodiversity strategy</span><br />
        Once you have assessed and gauged how your business practices are impacting different sustainability areas you can devise a wider strategy, which can be built to define targets, indicators and priorities that can help you make informed decisions relating to biodiversity specifically.
                    <br /><br />
        A useful resource to help you begin this journey is <a href="https://www.cisl.cam.ac.uk/resources/natural-resource-security-publications/developing-a-corporate-biodiversity-strategy-a-primer-for-the-fashion-sector" target="_blank" rel="noopener noreferrer">The Biodiversity Primer</a>, co-designed by Kering and the University of  Cambridge Institute for Sustainability Leadership. It is a flexible framework that consists of an eight step-process that supports brands to identify motivations as well as to implement, monitor, learn and deliver.
                </Typography>

                <Typography style={{fontSize: "1.1rem", margin: "20px 0px"}}>
                    <span style={{fontWeight: "600"}}>Biodiversity Benchmarks in the Fashion Industry</span><br />
        We are now seeing the emergence of biodiversity blueprints and benchmarks specifically formulated for the fashion industry.
                    <br /><br />
        Since 2020, <a href="https://thefashionpact.org/wp-content/uploads/2020/10/038906e111abca13dce4c77d419e4f21.pdf" target="_blank" rel="noopener noreferrer">The Fashion Pact</a> and Conservation International have been working together with some of the world&lsquo;s largest fashion brands to devise a biodiversity strategy for the fashion industry, which will be an expert-informed blueprint that other brands and suppliers can apply to their own business context. The main science based target the Fashion Pact is supporting is zero deforestation and sustainable forest management by 2025, which the pact&lsquo;s signatories will contribute towards through changes to their supply chains and sourcing practices, as well as through supporting and promoting sustainable forestry<sup>17</sup>.
                    <br /><br />
        Similarly, the Textile Exchange is currently devising a <a href="https://mci.textileexchange.org/biodiversity/" target="_blank" rel="noopener noreferrer">Biodiversity Benchmark</a> tool that their members and the wider textiles industry can use to understand their impact and dependencies on nature within their material sourcing and production practices, to integrate biodiversity into their business strategy and operations, and to benchmark and measure progress. They are currently working on a beta pilot phase to devise the benchmark, which any company who sources raw materials and has an interest in biodiversity is eligible to participate in.
                </Typography>

                <Typography style={{fontSize: "1.1rem", margin: "20px 0px"}}>
                    <span style={{fontWeight: "600"}}>Science Based Targets for Nature</span><br />
        The <a href="https://sciencebasedtargetsnetwork.org/" target="_blank" rel="noopener noreferrer">Science Based Targets Network</a> provides step-by-step guidance on how companies can understand their impact and dependencies on nature, and devise strategies for business to align with global sustainability goals. Science Based Targets for Nature and Biodiversity are currently under development.
                </Typography>

            </Box>
            <Box>
                <ResourcesReferences references={refs} />
            </Box>
        </Box>
    )
}

export default Biodiversity