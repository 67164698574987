import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/materials.jpeg';

const Materials = () => {
    const refs = [
        "Sustainable Material Mix, Global Fashion Agenda, no date: https://www2.globalfashionagenda.com/tag/sustainable-material-mix/",
        "Preferred Fibrer and Materials Market Report 2021, Textile Exchange, 2021: https://textileexchange.org/wp-content/uploads/2021/08/Textile-Exchange_Preferred-Fiber-and-Materials-Market-Report_2021.pdf",
        "How to Choose the Most Eco-Friendly Fabric For Your Garment, Common Objective, no date: https://commonobjective-uploads.storage.googleapis.com/resources/articles/CO-DATA-Fibre-Comparison-Tables-03c.pdf",
        "Materials Index, Council of Fashion Designers of America, no date: https://cfda.com/resources/materials",
        " Green Claims Code Checklist, Competition and Markets Authority, 2021: https://www.gov.uk/government/publications/green-claims-code-making-environmental-claims/green-claims-and-your-business"
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title="Materials" isMain={true}/>
            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>
                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>
                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>
              The materials your products are made from decide so much - what your products look like, feel like and how they fit. Material choices are also where, as an independent fashion brand, you may have the most leverage to positively influence factors further down the supply chain through your sourcing and purchasing decisions. 
                            <br /><br />
              It is not always straightforward to say that one material is “more sustainable” than another. Each material has different kinds of impact on the environment and on the people involved in its production, depending on where, how and by whom it was made.
                        </Typography>
                    </Box>
                </Box>

                <Typography style={{fontSize: "1.1rem"}}>A huge amount of work is being done by many different actors in the industry - from standards and certification bodies to sector NGOs - to try and reduce the impacts that come from fashion’s material choices.</Typography>

                <ResourceTitle title="Why is this important?" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
          The vast majority of a product’s environmental footprint is decided at the design stage. The materials used in your collections have a big impact on factors including energy, water, chemicals and land use<sup>1</sup>. This is to do with how the raw materials have been extracted, produced and processed into finished fabrics. 
                    <br /><br />
          Material choices also determine how a product performs from wear and washing, how long it lasts before being thrown away, and what happens once it is no longer wanted - for example, whether it is possible for it to be recycled.
                </Typography>

                <ResourceTitle title="What does good look like?" refs="2 3" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>Below we detail some of the main environmental and social points to consider when sourcing the most commonly used materials.</Typography>

                <Typography style={{fontSize: "1.1rem", marginTop: '20px'}}>
                    <span style={{fontWeight: "600"}}>Cotton</span><br />
          Cotton is one of the most widely used materials in the world. Look at what you’re wearing right now - it’s quite likely that most, possibly all, of the clothes you have on are made out of cotton. 
                    <br /><br />
          There are a number of preferred sources of “more sustainable” cotton. This means the cotton has been grown and processed in ways that protects the environment and improves working conditions in comparison to conventionally grown and processed cotton. Examples of initiatives and standards of more sustainable cotton include Cotton Made in Africa, Better Cotton Initiative and Fairtrade Cotton.
                    <br /><br />
          There is growing use of organic cotton across the industry - one of the most widely used standards here is the Global Organic Textiles Standard. 
                    <br /><br />
          There has recently been concern that the amount of product sold as organic cotton actually outstrips current supply globally. For more information on questionable claims in the organic cotton market see this <a href="https://www.nytimes.com/2022/02/13/world/asia/organic-cotton-fraud-india.html" target="_blank" rel="noopener noreferrer">article from the New York Times</a>. As a minimum, always ask for the accompanying documentation from your suppliers if you are selling clothing made from any certified material.
                </Typography>

                <Typography style={{fontSize: "1.1rem", marginTop: '20px'}}>
                    <span style={{fontWeight: "600"}}>Polyester</span><br />
          Polyester is the world’s most widely used fibre according to the NGO Textile Exchange. Virgin polyester is derived from petroleum. Polyester does not biodegrade, taking several hundred years to decompose. Polyester also sheds microplastic particles when washed (also known as microfibres), which are found in growing numbers in waterways and oceans globally. 
                    <br /><br />
          There is increasing use of recycled polyester in fashion. Nearly all polyester marked as recycled has been downcycled from other plastic waste such as PET bottles for fizzy drinks, rather than from other polyester textiles. This is because the textile-to-textile recycling required to recycle polyester thread does not currently exist at scale. 
                    <br /><br />
          This is noteworthy because plastic waste such as PET bottles can be recycled into other PET bottles repeatedly. When a PET bottle is downcycled into polyester fibre, it becomes a lot harder to recycle again. There is huge investment going on in textile-to-textile recycling globally but truly scalable operations are still some years away. The energy required to recycle polyester can also be intensive. 
                    <br /><br />
          In addition to innovation in textile-to-textile recycling, there is also a growing movement to address the livelihoods and working conditions of the waste picking communities that collect the plastic waste (such as PET bottles) which end up as recycled polyester thread. 
                    <br /><br />
          Common standards for recycled polyester include the Global Recycled Standard (GRS) and the Recycled Claim Standard (RCS).
                </Typography>

                <Typography style={{fontSize: "1.1rem", marginTop: '20px'}}>
                    <span style={{fontWeight: "600"}}>Wool</span><br />
          Sheep’s wool is the most common animal-based fibre globally. The main considerations here relate to animal welfare, land health and grazing practices. Major certifications for wool include the Responsible Wool Standard and ZQ Merino. There is a growing market for recycled wool, with some yarns blending virgin and recycled wool to reduce the need for virgin wool fibres. 
                    <br /><br />
          Major standards for recycled wool include the Recycled Claim Standard, the Global Recycled Standard, and Cardato Recycled. 
                </Typography>

                <Typography style={{fontSize: "1.1rem", marginTop: '20px'}}>
                    <span style={{fontWeight: "600"}}>Cellulosic fibres</span><br />
          This category covers materials such as viscose/rayon, TENCEL™ Lyocell and Modal. These are often derived from fast growing trees such as eucalyptus and beech but can also be derived from sources such as bamboo. 
                    <br /><br />
          A major concern is ensuring that cellulosic fibres are not sourced from controversial sources - such as ancient or endangered forests. Sourcing of cellulosic fibres has been linked to deforestation in countries including Canada and Indonesia, massively reducing mature forests’ ability to store carbon. 
                    <br /><br />
          The process of turning trees into pulp that is then spun into fibre can be highly chemically intensive. Materials such as TENCEL™  Lyocell, produced by Austrian fibre producer Lenzing, repurpose the vast majority of the chemicals used in processing in a closed-loop system.
                    <br /><br />
          Innovation in the recycling of cellulosic fibres are being trialled but are still some years away from being scalable at large. The organisation Canopy works to ensure the sourcing of cellulosic materials from sustainably managed forests, as does the Forest Stewardship Council. FSC-certified fabrics are increasingly being used in fashion.
                </Typography>

                <ResourceTitle title="Potential next steps" refs="4" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
          Below are some suggestions for possible steps you can take to source and use more sustainable materials in your products and collections.
                    <br /><br />
                    <span style={{fontWeight: "600"}}>Consider functionality in design</span><br />
          When choosing fabrics, consider longevity alongside quality and price. Durability ultimately denotes how sustainable a fabric will be. Things to consider include:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>How do you expect the item to perform?</li>
                    <li style={{margin: "10px 0"}}>How many times do you intend the garment to be worn? </li>
                    <li style={{margin: "10px 0"}}>How often do you intend the item to be washed?</li>
                    <li style={{margin: "10px 0"}}>How durable are the materials used for delicate areas of the garment such as linings or pockets?</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Ask for information</span><br />
          Ask your suppliers for information about where the materials they supply you with come from.
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Where does the supplier source their materials from?</li>
                    <li style={{margin: "10px 0"}}>What do they know about where the fabric was processed/dyed/bleached?</li>
                    <li style={{margin: "10px 0"}}>What can the fabric supplier tell you about where the yarn/thread came from?</li>
                    <li style={{margin: "10px 0"}}>What can they tell you about the conditions in those facilities?</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Ask for evidence</span><br />
          If you are being sold something as certified or being told that a material meets a specific standard or specification, ask for evidence. This could be a transaction certificate or claim that demonstrates “chain of custody”. Major third party certification companies that commonly issue this type of documentation include Control Union, CERES and Ecocert. Collecting this documentation is important to ensure you can back up any sustainability claims you may make about your products. 
                </Typography>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Expand your materials portfolio</span><br />
          Consider expanding your materials portfolio if you have been using one or only a small number of materials throughout a collection. There are a number of organisations promoting sustainable materials - including the <a href="https://www.futurefabricsvirtualexpo.com/sustainability" target="_blank" rel="noopener noreferrer">Future Fabrics Virtual Expo</a> and the <a href="https://material-exchange.com/" target="_blank" rel="noopener noreferrer">Materials Exchange</a>. 
                    <br /><br />
          These platforms feature information about new fabrics, mills and fabric sources and can connect you to new fabric suppliers that have a focus on environmental and social welfare standards. Such platforms can improve traceability and transparency regarding the origin of your fabrics and also connect you with the latest developments in materials innovation.
                </Typography>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Check your consumer-facing product claims</span><br />
          Be careful if you use any “sustainability language” when talking about your products or what they are made out of in consumer-facing comms. This could be a product description on your website or promoting an item on your social channels. For example, be wary of using ambiguous terms in product descriptions such as ‘eco’ or ‘natural’.
                    <br /><br />
          There is a growing backlash against the use of sustainability claims which are deemed to be unsubstantiated. This has been brought about thanks to legislation in the UK and the EU which is cracking down on the marketing of products as “green”, without the evidence to back claims up. 
                    <br /><br />
          In the UK the legislation to be aware of is called the <a href="https://www.gov.uk/government/publications/green-claims-code-making-environmental-claims/green-claims-and-your-business" target="_blank" rel="noopener noreferrer">Green Claims Code</a>, which is overseen by the UK Government’s Competition and Markets Authority. According to the guidance issued by the government in September 2021, when making any sustainability claim you should be able to answer ‘yes’ to each of the following statements:
                </Typography>

                <ol style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>The claim is accurate and clear for all to understand.</li>
                    <li style={{margin: "10px 0"}}>There’s up-to-date, credible evidence to show that the green claim is true.</li>
                    <li style={{margin: "10px 0"}}>The claim clearly tells the whole story of a product or service; or relates to one part of the product or service without misleading people about the other parts or the overall impact on the environment.</li>
                    <li style={{margin: "10px 0"}}>The claim doesn’t contain partially correct or incorrect aspects or conditions that apply.</li>
                    <li style={{margin: "10px 0"}}>Where general claims (eco-friendly, green or sustainable for example) are being made, the claim reflects the whole life cycle of the brand, product, business or service and is justified by the evidence.</li>
                    <li style={{margin: "10px 0"}}>If conditions (or caveats) apply to the claim, they’re clearly set out and can be understood by all.</li>
                    <li style={{margin: "10px 0"}}>The claim won’t mislead customers or other suppliers.</li>
                    <li style={{margin: "10px 0"}}>The claim doesn’t exaggerate its positive environmental impact, or contain anything untrue – whether clearly stated or implied.</li>
                    <li style={{margin: "10px 0"}}>Durability or disposability information is clearly explained and labelled.</li>
                    <li style={{margin: "10px 0"}}>The claim doesn’t miss out or hide information about the environmental impact that people need to make informed choices.</li>
                    <li style={{margin: "10px 0"}}>Information that really can’t fit into the claim can be easily accessed by customers in another way (QR code, website, etc.).</li>
                    <li style={{margin: "10px 0"}}>Features or benefits that are necessary standard features or legal requirements of that product or service type, aren’t claimed as environmental benefits.</li>
                    <li style={{margin: "10px 0"}}>If a comparison is being used, the basis of it is fair and accurate, and is clear for all to understand<sup>5</sup>.</li>
                </ol>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Explore industry initiatives</span><br />
          More sustainable material choices are important to many brand’s identities. To help you stay up-to-date of what’s going on in the industry, here are some organisations you may find helpful to follow:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}><a href="https://cfda.com/resources/materials" target="_blank" rel="noopener noreferrer">Council of Fashion Designers of America Materials Index</a></li>
                    <li style={{margin: "10px 0"}}><a href="https://textileexchange.org/2025-sustainable-cotton-challenge/" target="_blank" rel="noopener noreferrer">Textile Exchange 2025 Sustainable Cotton Challenge</a></li>
                    <li style={{margin: "10px 0"}}><a href="https://www.small-but-perfect.com/" target="_blank" rel="noopener noreferrer">Small But Perfect</a></li>
                    <li style={{margin: "10px 0"}}><a href="https://globalfashionagenda.org/circular-fashion-partnership/" target="_blank" rel="noopener noreferrer">Global Fashion Agenda</a></li>
                </ul>

            </Box>

            <ResourcesReferences references={refs} />

        </Box>
    )
}

export default Materials