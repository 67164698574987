import React from 'react'
import { Box } from "@material-ui/core";
import TopNavbar from '../../components/TopNavbar';
import TopNavbarAccount from '../../components/TopNavbarAccount';
import Footer from '../../components/Footer';
import { useUserStore } from '../../assets/state';
import PropTypes from 'prop-types';
import roles from '../../userTypes';

const HomePage = ({children}) => {
    const userType = useUserStore((state) => state.userType);
    let NavComponent = () => null

    if (userType === roles.AccountViewer) {
        NavComponent = TopNavbarAccount
    } else if (userType === roles.Brand) {
        NavComponent = TopNavbar
    }

    return (
        <Box minHeight="100vh" bgcolor="#F4F6FB" display="flex" flexDirection="column" position="relative">
            <NavComponent />
            <Box width={1} minHeight="92vh">
                {children}
            </Box>
            <Footer position="relative" bottom="0"/>
        </Box>
    );
}

HomePage.propTypes = {
    children: PropTypes.node,
}

export default HomePage
