import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/community.jpeg';

const Community = () => {
    const refs = [
        "Purchasing Practices And Wow Wages In Global Supply Chains: Empirical Cases From The Garment Industry, Mark Starmanns (University of Zurich), 2017",
        "FairWear Foundation, no date: https://www.fairwear.org/",
        "Clean Clothes Campaign, no date: https://cleanclothes.org/",
        "Integrating ethical trade principles into core business practice: An introductory toolkit, Ethical Trading Initiative, no date; https://www.ethicaltrade.org/sites/default/files/shared_resources/integrating_ethical_trade_principles_in_core_business_practice.pdf",
        " UK Ethical Consumer Markets Report, Ethical Consumer, 2022: https://www.ethicalconsumer.org/research-hub/uk-ethical-consumer-markets-report",
        "What Is WSR, Worker-Driven Social Responsibility Network, no date: https://wsr-network.org/what-is-wsr/"
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title="People Community" isMain={true}/>
            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" refs="1 2 3" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>

                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>

                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>
              The causes of labour rights violations form an extremely complex question, shaped by a mixture of global and country-specific factors. However, some key reasons are summarised below.
                        </Typography>

                        <ul style={{margin: "10px 0"}}>
                            <li style={{margin: "10px 0"}}>
                                <span style={{fontWeight: "600"}}>Chasing low costs</span><br />
                As consumers expect low prices brands compete, and many brands will seek suppliers offering the lowest prices.
                            </li>
                            <li style={{margin: "10px 0"}}>
                                <span style={{fontWeight: "600"}}>Brand purchasing practices</span>
                Brand purchasing practices can unintentionally result in pressures on suppliers. This pressure can result in workers experiencing low pay, no pay, pay delays, forced labour, overtime, break and rests refused, violence, harassment, and health and safety risks.
                            </li>
                        </ul>
                    </Box>
                </Box>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Lack of transparency and accountability</span><br/>
            Abuses can only be held accountable if sourcing destinations are known. Many brands do not publish even their first tier suppliers (although this is slowly changing). Even less know or publish their tier 2 or 3.
                        <br/><br/>
            Transparency is complicated by the widespread practice of subcontracting and home working, which offer little visibility and can be highly unregulated resulting in higher chances of irregular workers, unaccounted overtime and pay abuses.
                    </li>

                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Weak international and national regulation</span><br/>
            Commitment to ethical trade principles such as having a code of conduct or getting audits are largely voluntary. Reputational risk is the largest penalty when a brand is caught sourcing from a supplier that has breached their code of conduct.
                    </li>
                </ul>

                <ResourceTitle title="Why is it important?" refs='4' isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>The reputational risks associated with labour rights violations in the supply chain can be extremely damaging to a brand. However, there are clear benefits from embedding the people who make your products into your sourcing and procurement decisions. These include:</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Minimising reputational risk</span><br/>
            Companies are under much tighter public and media scrutiny to be seen to be actively integrating ethical trading principles throughout their supply chains and will be shamed if they are found not to.
                    </li>
                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Reducing supply chain disruptions</span><br/>
            Unsafe working conditions or strikes can lead to factory closures, which can in turn impact a company&lsquo;s ability to maintain a supply of products and services.
                    </li>
                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Protecting and increasing sales</span><br/>
            So-called ethical business practices are increasingly important factors in people&lsquo;s decision making process. Consumers care where and how their clothes are made. The market for responsibility produced products and services is growing fast<sup>5</sup>.
                    </li>
                </ul>

                <ResourceTitle title="Potential next steps" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>Below are some suggestions for possible steps you can take to embed community elements relating to workers in your supply chain.
                    <br/><br/>
                    <span style={{fontWeight: "600"}}>Explore community development</span><br/>
        There are a number of organisations working to provide worker education and community development initiatives for garment workers. These programmes have additional benefits that aim to improve worker well-being and go beyond local legal requirements through workplace training.
                    <br/><br/>
        Trainings teach workers about their employment rights and how to exercise them - for example in relation to factory and supervisor management as well as gender and sexual harrasment.
                    <br/><br/>
        Major industry initiatives working to promote garment worker wellbeing include:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}><a href="https://www.careinternational.org.uk/get-involved/corporate-partnerships/dignified-work" rel="noopener noreferrer">Care International</a></li>
                    <li style={{margin: "10px 0"}}><a href="https://herproject.org/" rel="noopener noreferrer">Herproject</a></li>
                    <li style={{margin: "10px 0"}}><a href="https://www.unglobalcompact.org/take-action/action/womens-principles" rel="noopener noreferrer">Women’s Economic Empowerment Principles</a></li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Wage Analysis and Living Wage Commitment</span>
          Beginning a conversation with a supplier about the wages they pay might feel uncomfortable, especially if you feel you are only a small percentage of that supplier’s output. However, gaining an understanding of labour costs gives you a more itemised idea of the costs you pay as a brand.
                    <br/><br/>
          A Living Wage Commitment is when a brand works with their suppliers to ensure that all their workers receive a living wage relative to the cost of living in the country they are based in. Note that this will differ country to country, and within a country can vary by region.
                    <br/><br/>
          If you have information about how much workers are paid, you could then consider undertaking a living wage analysis. The Anker Methodology is a widely accepted approach to calculating a living wage. It assesses a workers’ ability to afford a decent standard of living.
                    <br/><br/>
          According to the <a href="https://www.globallivingwage.org/about/anker-methodology/" rel="noopener noreferrer">Anker Methodology</a>, a living wage calculation would take into account the costs for:
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Food</li>
                    <li style={{margin: "10px 0"}}>Water</li>
                    <li style={{margin: "10px 0"}}>Housing</li>
                    <li style={{margin: "10px 0"}}>Healthcare</li>
                    <li style={{margin: "10px 0"}}>Education</li>
                    <li style={{margin: "10px 0"}}>Transport</li>
                    <li style={{margin: "10px 0"}}>An emergency provision for unexpected events</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>Actions to begin a conversation around the payment of wages could include:</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Analysing supplier locations and local statutory minimum requirements</li>
                    <li style={{margin: "10px 0"}}>Location-specific living wage calculations</li>
                    <li style={{margin: "10px 0"}}>Consultation with factory workers on their needs</li>
                    <li style={{margin: "10px 0"}}>Development of your supplier onboarding process to include formalised leveraging of your company’s purchasing power</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>A bottom-up approach to ethical trade</span><br/>
                    <a href="https://wsr-network.org/what-is-wsr/" rel="noopener noreferrer">Worker-Driven Social Responsibility</a> (as opposed to Corporate Social Responsibility) is an alternative paradigm to current top down approaches.
                    <br/><br/>
          It is based on the premise that “in order to achieve meaningful and lasting improvements, human rights protections in corporate supply chains must be worker-driven, enforcement-focused, and based on legally binding commitments that assign responsibility for improving working conditions to the global corporations at the top of those supply chains<sup>6</sup>”.
                    <br/><br/>
          The Worker-Driven Social Responsibility Network has produced a <a href="https://wsr-network.org/about-us/endorsers/" rel="noopener noreferrer">statement of principles</a> which you may want to take inspiration from for your approach to labour rights in your supply chain.
                    <br/><br/>
          The focus is on ensuring workers have a chair at the table, as opposed to more traditional approaches to ethical trade which have been accused of prioritising auditors and other technical organisations to tell workers what they need to know, rather than listening to the workers themselves.
                    <br/><br/>
          Make sure to also consult the accompanying guide “People Processes and Assessments”.
                </Typography>
            </Box>
            <ResourcesReferences references={refs} />
        </Box>
    )
}

export default Community