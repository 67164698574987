export const aniWelJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "Sourcing_Animals_Sellanimal_1",
                    title: "Do you sell products made of animal-derived materials?",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "Sourcing_Animals_Welfarepolicy_2",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1",
                    title: "Do you have an animal welfare policy?",
                    description: "This is a document your company uses as a guideline when purchasing animal-derived products and materials from your suppliers. It should refer to the treatment and welfare of animals in your supply chains - such as cows for leather or sheep for wool.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "checkbox",
                    name: "Sourcing_Animals_Prohibit_2.1",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1 and {Sourcing_Animals_Welfarepolicy_2} = 1",
                    indent: 3,
                    title: "2.1 Does the policy prohibit the use of any of animal-derived materials?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Furs"
                        },
                        {
                            value: "2",
                            text: "Exotic skins"
                        },
                        {
                            value: "3",
                            text: "Horn"
                        },
                        {
                            value: "4",
                            text: "Bone"
                        },
                        {
                            value: "5",
                            text: "Mother of pearl"
                        }
                    ],
                    hasOther: true,
                    otherText: "Other - please describe"
                },
                {
                    type: "radiogroup",
                    name: "Sourcing_Animals_Leathers_2.2",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1 and {Sourcing_Animals_Welfarepolicy_2} = 1",
                    indent: 3,
                    title: "2.2 Do you have restrictions on where you source leathers?",
                    description: "This question is asking whether there are regions or countries from which you actively avoid sourcing leathers from - for example, the Amazon biome in South America.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        },
                        {
                            value: "3",
                            text: "I don't know"
                        },
                        {
                            value: "4",
                            text: "We don't use leather"
                        }
                    ]
                },
                {
                    type: "comment",
                    name: "Sourcing_Animals_Leathers_desc",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1 and {Sourcing_Animals_Leathers_2.2} = 1",
                    indent: 3,
                    title: "Please list the countries / regions",
                    hideNumber: true
                },
                {
                    type: "radiogroup",
                    name: "Sourcing_Animals_Public_2.3",
                    visibleIf: "{Sourcing_Animals_Welfarepolicy_2} = 1 and {Sourcing_Animals_Sellanimal_1} = 1",
                    indent: 3,
                    title: "2.3 Is it public?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Sourcing_Animals_Public_link",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1 and {Sourcing_Animals_Welfarepolicy_2} = 1 and {Sourcing_Animals_Public_2.3} = 1",
                    indent: 5,
                    title: "Please provide the link",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "file",
                    name: "Sourcing_Animals_Public_file",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1 and {Sourcing_Animals_Welfarepolicy_2} = 1 and {Sourcing_Animals_Public_2.3} = 2",
                    indent: 5,
                    title: "Please upload to share internally",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Sourcing_Animals_Public_desc",
                    visibleIf: "{Sourcing_Animals_Welfarepolicy_2} = 1 and {Sourcing_Animals_Sellanimal_1} = 1 and {Sourcing_Animals_Public_2.3} = 2",
                    indent: 5,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Sourcing_Animals_Public_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "Sourcing_Animals_Farmlevel_3",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1",
                    title: "Do you have traceability of any of your animal-product supply chains to farm-level? ",
                    description: "This question aims to understand whether you have the ability to trace these materials back to the farms they come from.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Sourcing_Animals_Farmlevel_file",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1 and {Sourcing_Animals_Farmlevel_3} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Sourcing_Animals_Farmlevel_desc",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1 and {Sourcing_Animals_Farmlevel_3} = 1",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Sourcing_Animals_Sellanimal_1} = 1 and {Sourcing_Animals_Farmlevel_3} = 1 and {Sourcing_Animals_Farmlevel_file} empty"
                },
                {
                    type: "text",
                    name: "Sourcing_Animals_Checkwelfare_4",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1",
                    title: "What processes do you have for checking animal welfare practices in your supply chain? ",
                    description: "Please describe your process in the box below.",
                    isRequired: true
                },
                {
                    type: "checkbox",
                    name: "Sourcing_Animals_Certifiedmats_5",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1",
                    title: "Do you use certified materials of animal origin? ",
                    description: "An example certification is the Responsible Down Standard (RDS).",
                    isRequired: true,
                    choices: [
                        {
                            value: "item1",
                            text: "Wool"
                        },
                        {
                            value: "item2",
                            text: "Cashmere"
                        },
                        {
                            value: "item3",
                            text: "Leather"
                        },
                        {
                            value: "item4",
                            text: "Feather and down"
                        },
                        {
                            value: "item5",
                            text: "Other"
                        },
                        {
                            value: "item6",
                            text: "No"
                        },
                        {
                            value: "item7",
                            text: "I'm not sure"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Sourcing_Animals_Certifiedmats_file",
                    visibleIf: "({Sourcing_Animals_Sellanimal_1} == 1)",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Sourcing_Animals_Certifiedmats_desc",
                    visibleIf: "({Sourcing_Animals_Sellanimal_1} == 1)",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Sourcing_Animals_Certifiedmats_file} empty"
                },
                {
                    type: "comment",
                    name: "Sourcing_Animals_Other",
                    visibleIf: "{Sourcing_Animals_Sellanimal_1} = 1",
                    title: " Is there anything else that you would like to share about Animal welfare?",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on any animal welfare policies and practices you have in place if you sell products made from animal-derived materials. Materials covered by these questions include wool, leather, feathers and down, etc."
        }
    ],
    showCompletedPage: false
}