import React from 'react'
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const SectionHeader = (props) => {
    const { icon, title, subtitle, link, score } = props;

    return (
        <Box
            width="100%"
            minHeight="108px"
            display="flex"
            padding="31px 30px"
            flexDirection="row"
            justifyContent="space-between"
            borderBottom="1px solid #EAECEE"
        >
            <Box
                display="flex"
            >
                <Box
                    height="46px"
                    width="46px"
                    borderRadius="4px"
                    bgcolor="#F4F6F8"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr="21px"
                >{icon}</Box>
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <h3
                        style={{
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#1A2259",
                            margin: "0px"
                        }}
                    >{title}</h3>
                    {
                        link ?
                            <p style={{
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#9EA0A5",
                                margin: "8px 0px"
                            }}>
              Find out more about the <a style={{
                                    color: "#2F80ED",
                                    textDecoration: "none",
                                    fontWeight: "500"
                                }} rel="noopener noreferrer">SilkFred Insight Edit</a>
                            </p> :
                            <p
                                style={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#9EA0A5",
                                    margin: "8px 0px"
                                }}
                            >{subtitle}</p>
                    }
                </Box>
            </Box>
            {
                score && <p style={{
                    color: "#1A2259",
                    fontWeight: "500",
                    fontSize: "20px",
                    margin: "auto 0"
                }}>{score}%</p>
            }
        </Box>
    )
}

SectionHeader.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.bool,
    score: PropTypes.number
}

export default SectionHeader