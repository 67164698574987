import React from "react";
import { Page } from "../../UI/templates/Page";
import { TextField, Box, Button, Paper, Typography, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import { Visibility, VisibilityOff} from '@material-ui/icons';
import { useState } from "react";
import { useStyles } from '../../UI/styles/themes'
import { Auth, API } from 'aws-amplify';
import AuthState from '../../authStates';
import { useUserStore } from '../../assets/state';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import roles from '../../userTypes';

export const Login = (props) => {
    const setUserType = useUserStore((state) => state.setUserType);
    const userType = useUserStore((state) => state.userType);

    const setUser = useUserStore((state) => state.setUser);

    const setUserBrand = useUserStore((state) => state.setUserBrand);
    const setAuthState = useUserStore((state) => state.setAuthState);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        amount: '',
        password: '',
        email: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const history = useHistory();

    // get the current user brand info and store in global state
    const getBrand = async () => {
        const session = await Auth.currentSession();
        const token = session.idToken.jwtToken;
        const brandCode = session.getIdToken().payload['custom:brandCode'];

        const payLoad = {
            brandCode : brandCode  // specific brand
        };

        const myInit = {
            headers: {
                Authorization : token,
            },
            response: true,
            queryStringParameters : payLoad
        };

        try {
            return await API.get('surveyapi', '/brands', myInit)
        }
        catch(err) {
            console.error(err);
        }
    }

    async function signIn() {
        try {
            // sign in with email, not username
            const user = await Auth.signIn(values.email, values.password)
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setAuthState(AuthState.ResetPassword);
                return;
            }

            const session = await Auth.currentSession()
            const groups = session.getIdToken().payload['cognito:groups'] || [];
            const accountName = process.env.REACT_APP_ACCOUNT_NAME || '';
            const isAccountSilkfred = accountName === 'silkfred'
            if(groups.includes("account-admins")){
                setUserType(roles.Admin);
            } else if(groups.includes("account-viewers") && isAccountSilkfred){
                setUserType(roles.AccountViewer)
            }

            setUser(user.attributes);

            if(userType === roles.Brand) {
                getBrand()
                    .then(({data}) => {
                        // if the brand has not started the survey, direct user to welcome page
                        setUserBrand(data[0]);   /* set the current users brand data */
                        history.push('/home');
                    })
            } else {
                history.push('/home');
            }

            setAuthState(AuthState.SignedIn);
        } catch(error){
            setErrorMessage('Incorrect e-mail or password');
            // if account is not verified they cannot log in and will be redirected
            if (error.code === "UserNotConfirmedException") {
                props.setEmail(values.email);
                setAuthState(AuthState.ConfirmSignUp);
            }
        }
    }

    // Functions for toggling password ----
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //  -----------------


    // allow use of customer styles/classes from themes.jsx
    const classes = useStyles();

    return (
        <Page>
            <Paper
                elevation={3}
                className={classes.paperShadow}
                height="100%"
            >
                <Box className={classes.mainContainer} width={400} height={420}>
                    <Box
                        textAlign="center"
                        color="#1A2259"
                        mb='10px'
                    >
                        <Typography variant="h3">LOG IN</Typography>
                    </Box>
                    <Box display='flex' justifyContent='center' textAlign='center' alignItems='center' width={1} height="24px">
                        {
                            errorMessage && (
                                <Typography variant="body2" className={classes.errorText}>
                                    {errorMessage}
                                </Typography>
                            )
                        }
                    </Box>
                    <Box display='flex' flexDirection='column' alignItems='center' mt='10px' >
                        <form>
                            <Box width={1}>
                                <TextField
                                    type="email"
                                    id="oulined-basic"
                                    variant='outlined'
                                    label="Enter e-mail address"
                                    value={values.email}
                                    onChange={handleChange('email')}
                                    fullWidth
                                    margin="dense"
                                />
                            </Box>
                            <Box mt="34px" mb="21px" width={1}>
                                <FormControl margin="dense" fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Enter password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                            </Box>
                        </form>
                        <Box display="flex" justifyContent='center' width={1}>
                            <Button
                                color="primary"
                                className={classes.buttonText}
                                onClick={() => {setAuthState(AuthState.ForgotPassword)}}
                            >Forgot your password?</Button>
                        </Box>
                        <Box width={1} mt="57px">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={signIn}
                                className={classes.button}
                                size="large"
                                disableRipple='false'
                            >
                Next
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Page>
    );
};

Login.propTypes = {
    setEmail: PropTypes.func.isRequired,
}