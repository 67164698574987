export const communicationJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "People_Comms_Sustlanguage_1",
                    title: "Do you use language about sustainability in your marketing? ",
                    description: "This question is asking about any marketing terms you might use to promote your brand or describe and sell your products. Please tell us if you use terms such as sustainable, eco, ethical, artisan, UK-made, planet friendly, green, vegan, etc. We are asking this question to understand how embedded sustainability is in your branding.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes - at the brand level"
                        },
                        {
                            value: "2",
                            text: "Yes - at the product level"
                        },
                        {
                            value: "3",
                            text: "Yes - at both the brand and product level"
                        },
                        {
                            value: "4",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "comment",
                    name: "People_Comms_Sustlanguage_link",
                    visibleIf: "{People_Comms_Sustlanguage_1} <= 3",
                    indent: 3,
                    title: "Please provide example links",
                    hideNumber: true,
                    isRequired: true,
                    rows: 2
                },
                {
                    type: "radiogroup",
                    name: "People_Comms_Publiccompany_1.1",
                    visibleIf: "{People_Comms_Sustlanguage_1} <= 3",
                    indent: 3,
                    title: "1.1. Do you publicly communicate your company's sustainability performance? ",
                    description: "This question seeks to understand whether you talk publicly about sustainability. This could be anything to do with your own operations or your products.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "comment",
                    name: "People_Comms_Publiccompany_link",
                    visibleIf: "{People_Comms_Sustlanguage_1} <= 3 and {People_Comms_Publiccompany_1.1} = 1",
                    indent: 5,
                    title: "Please provide example links",
                    hideNumber: true,
                    isRequired: true,
                    rows: 2
                },
                {
                    type: "radiogroup",
                    name: "People_Comms_Publicsupplychain_1.2",
                    visibleIf: "{People_Comms_Sustlanguage_1} <= 3",
                    indent: 3,
                    title: "1.2. Do you publicly communicate your supply chain's sustainability performance? ",
                    description: "This question is asking whether you specifically talk about your supply chain in your communications or marketing. This could be a story about garment workers in a factory or more sustainable production processes.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "comment",
                    name: "People_Comms_Publicsupplychain_link",
                    visibleIf: "{People_Comms_Sustlanguage_1} <= 3 and {People_Comms_Publicsupplychain_1.2} = 1",
                    indent: 5,
                    title: "Please provide example links",
                    hideNumber: true,
                    isRequired: true,
                    rows: 2
                },
                {
                    type: "checkbox",
                    name: "People_Comms_Greenclaims_1.3",
                    visibleIf: "{People_Comms_Sustlanguage_1} <= 3",
                    indent: 3,
                    title: "1.3 Do you ensure the following in the language you use publicly around sustainability claims?",
                    description: "Please select all that apply. The tick-list below ensures you are compliant with the Green Claims Code, recently introduced by the UK Competition and Markets Authority. The aim of the Code is to provide guidance to UK businesses on how to make sustainability claims that are not misleading or unsubstantiated.",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "item1",
                            text: "Claims are truthful and accurate"
                        },
                        {
                            value: "item2",
                            text: "Claims are clear and unambiguous"
                        },
                        {
                            value: "item3",
                            text: "Claims do not hide or omit important information"
                        },
                        {
                            value: "item4",
                            text: "Claims only makes fair and meaningful comparisons"
                        },
                        {
                            value: "item5",
                            text: "Claims consider the full life cycle of the product when making claims"
                        },
                        {
                            value: "item6",
                            text: "Claims are backed with robust and credible evidence"
                        },
                        {
                            value: "item7",
                            text: "I don't know/I'm not sure"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "People_Comms_Educate_2",
                    title: "Do you educate customers on sustainability?   ",
                    description: "Educating consumers on sustainability might include instructions on the benefits of washing clothes at lower temperatures or providing information about garment recycling.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "comment",
                    name: "People_Comms_Educate_link",
                    visibleIf: "{People_Comms_Educate_2} = 1",
                    indent: 5,
                    title: "Please provide example links",
                    hideNumber: true,
                    isRequired: true,
                    rows: 2
                },
                {
                    type: "text",
                    name: "People_Comms_DEImarcomms_3",
                    title: "Do you consider diversity, equality and inclusion in your own marketing and communications?  ",
                    description: "We are asking this question to understand how diversity, inclusion and equality (such as size inclusivity and model diversity) is embedded in brand touchpoints which are in your control - that could be your Instagram feed or your lookbook. Please describe in the box below.",
                    isRequired: true
                },
                {
                    type: "comment",
                    name: "People_Comms_DEImarcomms_link",
                    visibleIf: "{People_Comms_DEImarcomms_3} notempty",
                    indent: 3,
                    title: "Please provide example links",
                    hideNumber: true,
                    isRequired: true,
                    rows: 2
                },
                {
                    type: "comment",
                    name: "People_Comms_Other",
                    title: "Is there anything else that you would like to share about Communications and marketing?",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on whether you communicate or market anything to do with sustainability. This might be central to your brand or it could be support for certain sustainability topics."
        }
    ],
    showCompletedPage: false
}