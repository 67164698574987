import React, { useContext } from 'react'
import { Box, Button, Typography, TableContainer, TableHead, TableRow, TableBody, TableCell, Paper, Table } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { CurrentBrandContext } from '../../../assets/contexts/CurrentBrand';
import { Auth, API } from 'aws-amplify';
import { useQuery } from 'react-query';

const orderByStatusFirst = (a,b) => {
    if ( b.surveyStatus === 'None' ){
        return -1;
    }
    if ( b.surveyStatus === 'Complete' ){
        return 1;
    }
    else {
        return -1
    }
};

const orderByStatusSecond = (a,b) => {
    if ( b.surveyStatus === 'None' ){
        return -1;
    }
    if ( b.surveyStatus === 'In progress' ){
        return 1;
    }
    else {
        return 1
    }
}

const SurveyResults = () => {
    const history = useHistory();
    const {setCurrentBrand} = useContext(CurrentBrandContext);

    const {data, isLoading, isError} = useQuery('brands', async () => {
        const tokens = await Auth.currentSession();
        const token = tokens.idToken.jwtToken;

        const payLoad = {
            brandCode : null  // null to get All brands
        };

        const myInit = {
            headers: {
                Authorization : token,
            },
            response: true,
            queryStringParameters : payLoad
        };

        const result = await API.get('surveyapi', '/brands', myInit)
        return result.data
    });

    return (
        <Box p='5%'>
            {
                isLoading ? <Typography>Loading...</Typography> :
                    isError ? <Typography>Oops something went wrong...</Typography> : (
                        <TableContainer component={Paper} style={{maxHeight: '100%'}}>
                            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Brand name</TableCell>
                                        <TableCell align="left">Brand code</TableCell>
                                        <TableCell align="right">Survey status</TableCell>
                                        <TableCell align="right">Survey results</TableCell>
                                        <TableCell align="right">Updated on</TableCell>
                                        <TableCell align="right">Updated by</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.sort(orderByStatusFirst).sort(orderByStatusSecond).map((brand) => (
                                        <TableRow
                                            key={brand.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">{brand.name}</TableCell>
                                            <TableCell align="left">{brand.brandCode}</TableCell>
                                            <TableCell align="right">{brand.surveyStatus}</TableCell>
                                            <TableCell align="right">
                                                {
                                                    brand.surveyStatus !== 'None' && (
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => {
                                                                setCurrentBrand(brand);
                                                                history.push(`/survey`);
                                                            }}
                                                        >View survey</Button>
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell align="right">{brand.updatedOn ? new Date(brand.updatedOn).toLocaleDateString() : ""}</TableCell>
                                            <TableCell align="right">{brand.updatedBy || ""}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
            }
        </Box>
    )
}

export default SurveyResults