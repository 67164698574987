import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const SurveyCompletedContext = createContext({
    surveyCompleted: false,
    setSurveyCompleted: () => {},
});

const SurveyCompletedProvider = (props) => {
    const { children } = props;
    const [surveyCompleted, setSurveyCompleted] = useState(false);

    return (
        <SurveyCompletedContext.Provider value={{ surveyCompleted, setSurveyCompleted }}>
            {children}
        </SurveyCompletedContext.Provider>
    );
};

SurveyCompletedProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export { SurveyCompletedProvider, SurveyCompletedContext };