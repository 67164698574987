import React from 'react';
import SurveyPage from '../../../../UI/templates/SurveyPage';
import { goalJSON } from '../../../../assets/surveyJSON/yourGoals/goals';

export const Goals = () => {
    return (
        <SurveyPage
            section="Your goals"
            subsection=""
            description={goalJSON.pages[0].descriptionEdit}
            jsonFile={goalJSON}
            nextSection="/survey/company"
        />
    );
};