import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/ghg3.jpeg';

const GHG3 = () => {
    const refs = [
        "Key Aspects of the Paris Agreement, UNFCCC, no date: https://unfccc.int/most-requested/key-aspects-of-the-paris-agreement",
        "Greenhouse Gas Protocol, no date: https://ghgprotocol.org/",
        "Apparel and Footwear Science Based Targets Guidance, Science Based Targets, 2020: https://sciencebasedtargets.org/resources/files/SBT_App_Guide_final_0718.pdf",
        "Fashion Industry Not on Track to Achieve Scope 3 Emissions Goals, Environmental Leader, 2021: https://www.environmentalleader.com/2021/10/fashion-industry-not-on-track-to-achieve-scope-3-emissions-goals-says-new-report/",
        "The Biggest Problem With Carbon Offsetting Is That It Doesn’t Really Work, Greenpeace, 2020: https://www.greenpeace.org.uk/news/the-biggest-problem-with-carbon-offsetting-is-that-it-doesnt-really-work/"
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title="GHG emissions from your production (Scope 3)" isMain={true}/>
            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>

                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>

                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>
              The Intergovernmental Panel on Climate Change (IPCC) have collectively agreed that to limit the irreversible effects of climate change we need to keep global warming as close to, or below, 1.5 degrees celsius. The 1.5 degree pathway was laid out and ratified in the Paris Agreement in 2015 and was signed by 195 countries<sup>1</sup>.
                        </Typography>
                    </Box>
                </Box>

                <Typography style={{fontSize: "1.1rem"}}>
          To meet this pathway, the fashion industry would need to cut 1.1 billion metric tonnes of CO2 equivalent by 2030 and by 100% by 2050 (1) Though this may seem a far off goal, the actions discussed below could bring us steps closer to achieving this. 
                    <br /><br />
          This resource explores the GHG emissions from your production (scope 3). Please see the accompanying resource “GHG emissions from your company (scope 1 & 2)” for an overview of emissions related to your brand’s direct operations.
                    <br /><br />
          Let’s define what is meant by GHG emissions from your production (scope 3)<sup>2 3</sup>.
                    <br /><br />
                </Typography>

        
                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Scope 3 emissions</span> refers to all other indirect emissions from your supply chain from sources that your brand does not own or control. 
                    <br /><br />
          What this means simply is that an item of clothing will likely pass through many stages of processing and production before becoming a finished product. Therefore, the overwhelming majority of emissions for that product belong to the supply chain, rather than from the fashion brand’s own operations. 
                    <br /><br />
          Scope 3 can make up as much as 90% of emissions for a fashion brand<sup>4</sup>. 
                    <br /><br />
          These types of emissions linked to the supply chain are called <span style={{fontWeight: "600"}}>upstream emissions</span>. They include all the emissions generated in the creation of raw materials and the energy used in fabric processing and in supplier factories.
                    <br /><br />
          Scope 3 emissions also take into account consumer behaviour and what your consumers do with your products once they’ve bought them from you. 
                    <br /><br />
          These are called <span style={{fontWeight: "600"}}>downstream emissions</span>. They include how long your products are used for and how your products are disposed of at the end of their life. 
                </Typography>

                <ResourceTitle title="Calculating your scope 3 emissions" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
          Accurately calculating scope 3 emissions is undeniably harder than calculating the emissions from your own company (scope 1 and 2). 
                    <br /><br />
          This is because the majority of these emissions are from your supply chain and the manufacturing of your products and then how your consumers use your products - meaning from sources you do not own or control. 
                    <br /><br />
          However, scope 3 is where the vast majority of your emissions will be and as such, where the biggest opportunity for emissions reductions lie. 
                    <br /><br />
          There are different ways to calculate your scope 3 emissions - for example, you can calculate the scope of your organisation or you can calculate the footprint of a specific product. There are a number of carbon accounting software providers that can help you with this. <a href="https://www.carbontrust.com/resources/briefing-what-are-scope-3-emissions" target="_blank" rel="noopener noreferrer">The Carbon Trust</a> has good resources on measuring scope 3 emissions.
                    <br /><br />
          Once you’ve calculated your emissions, you can establish your carbon baseline. This means how many carbon emissions comprise your scope 3. The baseline is what you will measure your reductions against, and what you can use to track your future progress.  
                </Typography>

                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Offset or reduce?<sup>5</sup></Typography>

                <Typography style={{fontSize: "1.1rem"}}>
          Before moving on to the types of emissions reduction activities you could take, it is worth exploring this question.
                    <br /><br />
          You might have heard the phrase carbon offset. How is this different to carbon reduction?
                    <br /><br />
          Carbon offset is a way to compensate for your emissions. Offsetting generally means paying another organisation to reduce or absorb carbon emissions to compensate for your own. An example of a carbon offset activity might include planting trees or supporting a renewable energy project. 
                    <br /><br />
          Carbon offset projects can be good, however they do not necessarily result in the cutting of emissions. 
                    <br /><br />
          Therefore, a carbon reduction activity is preferable to a carbon offset activity. 
                    <br /><br />
          Ultimately, carbon offset activities will not result in the net reduction of carbon emissions we need to prevent the planet from continuing to heat up.
                </Typography>

                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Emissions reduction activities for your supply chain (scope 3 upstream emissions)</Typography>

                <Typography style={{fontSize: "1.1rem"}}>
          You might think that you have limited options to be able to shift the needle on your scope 3 emissions as an independent fashion brand, but there are a number of suggestions you could consider below. Change may not happen overnight but it is good to begin exploring these options with your partners. 
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Request your suppliers to commit to a 1.5°C ambition and include it in your sourcing criteria and your supplier code of conduct. Discuss as part of your negotiations when onboarding new suppliers.</li>
                    <li style={{margin: "10px 0"}}>Choose materials, products and transport suppliers based on their climate strategy. Ask them if they know about their own energy use before placing orders or whether they use any energy efficiency processes in manufacturing.</li>
                    <li style={{margin: "10px 0"}}>Use recycled materials in your products.</li>
                    <li style={{margin: "10px 0"}}>Use recycled materials in your packaging and ensure your packaging is plastic free.</li>
                    <li style={{margin: "10px 0"}}>Forecast accurately to reduce overproduction and deadstock.</li>
                    <li style={{margin: "10px 0"}}>Plan further ahead to make use of sea freight rather than flying products.</li>
                </ul>

                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Emissions reduction activities for your products (scope 3 downstream emissions)</Typography>
        
                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Speak to your consumers to understand how they use your products. Find out how your customers look after your products and how long they use them.</li>
                    <li style={{margin: "10px 0"}}>
            Think about your care labels. Remind customers to:
                        <ul style={{margin: "10px 0"}}>
                            <li>Wash on fuller loads</li>
                            <li>Wash at lower temperatures (30 degrees rather than 40)</li>
                            <li>Line dry rather than tumble dry</li>
                            <li>Wash certain items such as outerwear less frequently</li>
                        </ul>
            Have this information available on your website.
                    </li>
                    <li style={{margin: "10px 0"}}>Give your customers information on what to do at the end of your product’s life - such as recycling or garment return. RecycleNow provides a simple search directory that lists clothing and recycling centres in proximity to UK postcodes.</li>
                </ul>

                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Further emissions reduction activities for scope 3</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Employee commuting is also considered when calculating scope 3 GHG emissions. Reduce travel emissions by leveraging remote working. Set up a plan to enable the reduction of commuting travel emissions such as a cycle to work scheme.</li>
                    <li style={{margin: "10px 0"}}>Provide and/or promote the choice of plant-rich, healthy food whenever it is possible for you to influence.</li>
                    <li style={{margin: "10px 0"}}>Evaluate and reduce the footprint of your financial investments, including pension funds, to make sure they are in line with your 1.5°C commitment.</li>
                </ul>

                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>SME-specific resources for GHG reductions (all scopes 1, 2 & 3)</Typography>

                <Typography style={{fontSize: "1.1rem"}}>If you want to deepen your engagement with and understanding of GHG emissions and reductions, here are some additional resources with a focus on small and medium-sized enterprises.</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}><a href="https://sciencebasedtargets.org/resources/legacy/2020/04/SBT-SME-Target-Setting-Letter.pdf" target="_blank" rel="noopener noreferrer"> Science Based Targets SME call to action letter</a><br />Science Based Targets have produced a target setting letter that small and medium sized enterprises can fill out and sign that commits to applying science based targets to reduce GHG emissions.</li>
                    <li style={{margin: "10px 0"}}><a href="https://cdn.cdp.net/cdp-production/cms/guidance_docs/pdfs/000/002/852/original/SME-Climate-Framework.pdf?1637746697" target="_blank" rel="noopener noreferrer">CDP SME framework</a><br />CDP are a not-for-profit charity helping companies disclose and manage their environmental impacts and have produced guidance for how SMEs can report on their GHG emissions using the CDP climate framework.</li>
                    <li style={{margin: "10px 0"}}><a href="https://exponentialroadmap.org/wp-content/uploads/2020/11/1.5C-Business-Playbook-v1.1.1pdf.pdf" target="_blank" rel="noopener noreferrer">The 1.5 degree Business Playbook</a><br />As part of the SME Climate Hub, this playbook sets out how to fully embed a  climate strategy. In addition to measuring and reducing scope 1, 2 and 3 emissions, the playbook details how to integrate climate into business strategy, and then influence climate action in wider society. </li>
                </ul>

            </Box>
            <ResourcesReferences references={refs} />
        </Box>
    )
}

export default GHG3