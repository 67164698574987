import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import { Box } from '@material-ui/core';
import SurveyPage from '../../../../UI/templates/SurveyPage';
import { aniWelJSON } from '../../../../assets/surveyJSON/sourcing/animalWelfare';
import { jewelleryJSON } from '../../../../assets/surveyJSON/sourcing/jewellery';
import { transparencyTraceJSON } from '../../../../assets/surveyJSON/sourcing/transparencyTraceability';
import { matJSON } from '../../../../assets/surveyJSON/sourcing/materials';

const SourcingSection = () => {
    const { path } = useRouteMatch();

    return (
        <Box width={1} height={1}>
            <Switch>

                <Route path={`${path}/transparency&traceability`}>
                    <SurveyPage
                        section="Sourcing"
                        subsection={`Transparency & traceability`}
                        description={transparencyTraceJSON.pages[0].descriptionEdit}
                        jsonFile={transparencyTraceJSON}
                        nextSection={`${path}/materials`}
                    />
                </Route>

                <Route path={`${path}/materials`}>
                    <SurveyPage
                        section="Sourcing"
                        subsection="Materials"
                        description={matJSON.pages[0].descriptionEdit}
                        jsonFile={matJSON}
                        nextSection={`${path}/animalwelfare`}
                    />
                </Route>

                <Route path={`${path}/animalwelfare`}>
                    <SurveyPage
                        section="Sourcing"
                        subsection={"Animal welfare"}
                        description={aniWelJSON.pages[0].descriptionEdit}
                        jsonFile={aniWelJSON}
                        nextSection={`${path}/jewellery`}
                    />
                </Route>

                <Route path={`${path}/jewellery`}>
                    <SurveyPage
                        section="Sourcing"
                        subsection="Jewellery"
                        description={jewelleryJSON.pages[0].descriptionEdit}
                        jsonFile={jewelleryJSON}
                        nextSection={`/survey/yourgoals`}
                    />
                </Route>
            </Switch>
        </Box>
    )
}

export default SourcingSection
