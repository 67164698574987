export const wasCircJSON = {
    "logoPosition": "right",
    "pages": [
        {
            "name": "Page 1",
            "elements": [
                {
                    "type": "checkbox",
                    "name": "Planet_Waste_Preconsumer_1",
                    "title": "Do you track or record your pre-consumer waste (i.e. waste generated in the production of your products)? ",
                    "description": "This question is asking about waste generated during manufacturing of your products - this might include production offcuts, selvedge, fabric excess, jhoot (small cutting scraps + waste yarn), etc. For example - your supplier might have asked what to do with old fabric rolls from previous collections that you're not using. This counts as the beginning of tracking your pre-consumer waste.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes - it is donated"
                        },
                        {
                            "value": "2",
                            "text": "Yes - it is incinerated"
                        },
                        {
                            "value": "3",
                            "text": "Yes - it is repurposed / upcycled / downcycled"
                        },
                        {
                            "value": "4",
                            "text": "Yes - it is sent to landfill"
                        },
                        {
                            "value": "5",
                            "text": "Other"
                        },
                        {
                            "value": "6",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "file",
                    "name": "Planet_Waste_Preconsumer_file",
                    "visibleIf": "{Planet_Waste_Preconsumer_1} anyof [1, 2, 3, 4, 5]",
                    "indent": 3,
                    "title": "Please upload supporting evidence",
                    "description": "Examples of evidence include any estimates carried out by suppliers documented in emails or reports. You will need to show this for each supplier.",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "Planet_Waste_Preconsumer_desc",
                    "visibleIf": "{Planet_Waste_Preconsumer_1} anyof [1, 2, 3, 4, 5]",
                    "indent": 3,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{Planet_Waste_Preconsumer_1} anyof [1, 2, 3, 4, 5] and {Planet_Waste_Preconsumer_file} empty"
                },
                {
                    "type": "checkbox",
                    "name": "Planet_Waste_Preconsumerreduce_1.1",
                    "visibleIf": "{Planet_Waste_Preconsumer_1} notempty",
                    "indent": 3,
                    "title": "1.1 Have you implemented any practices to reduce your pre-consumer waste?",
                    "description": "For example, perhaps you are specifically making use of repurposed fabrics in the creation of your products in order to reuse those materials and keep them from landfill or incineration.",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "item1",
                            "text": "Yes - we repurpose dead stock materials in production"
                        },
                        {
                            "value": "item2",
                            "text": "Yes - we recycle production waste (e.g. mechanical recycling into new yarn)"
                        },
                        {
                            "value": "item3",
                            "text": "Yes - other"
                        },
                        {
                            "value": "item4",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "file",
                    "name": "Planet_Waste_Preconsumerreduce_file",
                    "visibleIf": "{Planet_Waste_Preconsumerreduce_1.1} anyof ['item1', 'item2', 'item3']",
                    "indent": 4,
                    "title": "Please upload supporting evidence",
                    "description": "Examples of evidence include any estimates carried out by suppliers documented in emails or reports. You will need to show this for each supplier.",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "Planet_Waste_Preconsumerreduce_desc",
                    "visibleIf": "{Planet_Waste_Preconsumerreduce_1.1} anyof ['item1', 'item2', 'item3']",
                    "indent": 4,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{Planet_Waste_Preconsumerreduce_file} empty"
                },
                {
                    "type": "checkbox",
                    "name": "Planet_Waste_Circulardesign_1.2",
                    "visibleIf": "{Planet_Waste_Preconsumer_1} notempty",
                    "indent": 3,
                    "title": "1.2 When creating products do you design for circularity?",
                    "description": "Designing for circularity could include avoiding the use of blended fibres such a polyester-cotton mixes  - this is because it is more difficult to separate blends of fibres in recycling than monofibres. You might use zero-waste cutting patterns or design products that can be easily disassembled, etc.",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "item1",
                            "text": "Yes - we avoid using blended fibres"
                        },
                        {
                            "value": "item4",
                            "text": "Yes - we use zero-waste cutting patterns"
                        },
                        {
                            "value": "item5",
                            "text": "Yes - we design for disassembly"
                        },
                        {
                            "value": "item2",
                            "text": "Yes - other"
                        },
                        {
                            "value": "item3",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "file",
                    "name": "Planet_Waste_Circulardesign_file",
                    "visibleIf": "{Planet_Waste_Circulardesign_1.2} anyof ['item1', 'item4', 'item5', 'item2']",
                    "indent": 4,
                    "title": "Please upload supporting evidence",
                    "description": "Examples of evidence include any estimates carried out by suppliers documented in emails or reports. You will need to show this for each supplier.",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "Planet_Waste_Circulardesign_desc",
                    "visibleIf": "{Planet_Waste_Circulardesign_1.2} anyof ['item1', 'item4', 'item5', 'item2']",
                    "indent": 4,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{Planet_Waste_Circulardesign_file} empty"
                },
                {
                    "type": "checkbox",
                    "name": "Planet_Waste_Trackfinishedgoods_2",
                    "title": "Do you track or record your finished goods waste? ",
                    "description": "This question is asking about whether you know about the waste generated by your finished products. For example, this could be the items that are returned by your customers or that are unsold.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes - they are donated"
                        },
                        {
                            "value": "2",
                            "text": "Yes - they are incinerated"
                        },
                        {
                            "value": "3",
                            "text": "Yes - they are repurposed / upcycled / downcycled"
                        },
                        {
                            "value": "4",
                            "text": "Yes - they are sent to landfill"
                        },
                        {
                            "value": "5",
                            "text": "Other"
                        },
                        {
                            "value": "6",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "file",
                    "name": "Planet_Waste_Trackfinishedgoods_file",
                    "visibleIf": "{Planet_Waste_Trackfinishedgoods_2} anyof [1, 2, 3, 4, 5]",
                    "indent": 3,
                    "title": "Please upload supporting evidence",
                    "description": "Examples of evidence include any estimates carried out documented in emails or reports. ",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "Planet_Waste_Trackfinishedgoods_desc",
                    "visibleIf": "{Planet_Waste_Trackfinishedgoods_2} anyof [1, 2, 3, 4, 5]",
                    "indent": 3,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{Planet_Waste_Trackfinishedgoods_2} anyof [1, 2, 3, 4, 5] and {Planet_Waste_Trackfinishedgoods_file} empty"
                },
                {
                    "type": "checkbox",
                    "name": "Planet_Waste_Extendlife_2.1",
                    "visibleIf": "{Planet_Waste_Trackfinishedgoods_2} notempty",
                    "indent": 3,
                    "title": "2.1 Do you undertake any activities to extend the life of a product? ",
                    "description": "This question is asking whether you offer any assistance to your customers to extend the life of products they've purchased from you such as a repair service or upcycling scheme.",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "item1",
                            "text": "Yes - we offer a repair service"
                        },
                        {
                            "value": "item4",
                            "text": "Yes - we offer an upcycling scheme"
                        },
                        {
                            "value": "item2",
                            "text": "Yes - other"
                        },
                        {
                            "value": "item3",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "file",
                    "name": "Planet_Waste_Extendlife_file",
                    "visibleIf": "{Planet_Waste_Extendlife_2.1} anyof ['item1', 'item4', 'item2']",
                    "indent": 4,
                    "title": "Please upload supporting evidence",
                    "description": "Examples of evidence include any estimates carried out documented in emails or reports. ",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "Planet_Waste_Extendlife_desc",
                    "visibleIf": "{Planet_Waste_Extendlife_2.1} anyof ['item1', 'item4', 'item2']",
                    "indent": 4,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{Planet_Waste_Endoflife_file} empty"
                },
                {
                    "type": "checkbox",
                    "name": "Planet_Waste_Endoflife_2.2",
                    "visibleIf": "{Planet_Waste_Trackfinishedgoods_2} notempty",
                    "indent": 3,
                    "title": "2.2 Do you undertake any activities related to the end-of-life of your products? ",
                    "description": "This question seeks to understand how much you know about what happens to your products at the end of their life and whether you undertake any activities to extend  the life of your products. For example a donation partnership with a charity, a fibre-to-fibre recycling scheme, etc. Alternatively, you might offer a take-back scheme to allow customers to return products to you which you may then recycle, repurpose, etc.",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "item1",
                            "text": "Yes - we have a donation partnership"
                        },
                        {
                            "value": "item2",
                            "text": "Yes - we are involved in a recycle scheme"
                        },
                        {
                            "value": "item3",
                            "text": " Yes - we offer a take-back service"
                        },
                        {
                            "value": "item4",
                            "text": "Yes - other"
                        },
                        {
                            "value": "item5",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "file",
                    "name": "Planet_Waste_Endoflife_file",
                    "visibleIf": "{Planet_Waste_Endoflife_2.2} anyof ['item1', 'item2', 'item3', 'item4']",
                    "indent": 4,
                    "title": "Please upload supporting evidence",
                    "description": "Examples of evidence include any estimates carried out documented in emails or reports. ",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "Planet_Waste_Endoflife_desc",
                    "visibleIf": "{Planet_Waste_Endoflife_2.2} anyof ['item1', 'item2', 'item3', 'item4']",
                    "indent": 4,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{Planet_Waste_Endoflife_file} empty"
                },
                {
                    "type": "comment",
                    "name": "Planet_Waste_Other",
                    "title": "Is there anything else that you would like to share about Waste management and circularity?",
                    "hideNumber": true
                }
            ],
            "descriptionEdit": "This section focuses on waste, generated from the manufacture of your products as well as what happens to your products once they are sold. We ask questions about circularity, which means keeping materials and products in use for as long as possible and designing to minimise waste. Circularity covers all stages of a product's life, from how garments are desgined to what happens to them once they're no longer worn.  "
        }
    ],
    "showCompletedPage": false
}