import React from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types';

const MultiProgressBar = (props) => {
    const { input, height, width } = props;

    const total = input.reduce((total, current) => {
        return total + current.score;
    }, 0);

    return (
        <Box
            display="flex"
            flexDirection="column"
            position="relative"
        >
            <Box
                width={`${width}px`}
                height={`${height}px`}
                bgcolor="#F4F4FB"
                borderRadius={`${height/2}px`}
                overflow="hidden"
                display="flex"
            >
                {
                    input.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                width={`${(item.score/total)*width}px`}
                                height={`${height}px`}
                                bgcolor={item.colour}
                                // borderRadius={`${height/2}px 0px 0px ${height/2}px`}
                            ></Box>
                        )
                    })
                }
            </Box>

            {/* key */}
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                m="20px 0"
            >
                {
                    input.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <div style={{
                                    background: item.colour,
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    marginRight: "10px"
                                }} />
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    height={1}
                                    width={1}
                                    style={{borderBottom: index +1 !== input.length && "1px solid #E6E7E9"}}
                                >
                                    <p style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "#1A2259",
                                    }}>{item.name}</p>
                                    <p style={{
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        color: "#1A2259",
                                    }}>{item.score}</p>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
            <p style={{
                position: "absolute",
                top: "-2.5em",
                left: "0",
                fontWeight: "600",
                fontSize: "12px",
                color: "rgba(24, 24, 25, 0.42)"
            }}>0</p>

            <p style={{
                position: "absolute",
                top: "-2.5em",
                right: "0",
                fontWeight: "600",
                fontSize: "12px",
                color: "rgba(24, 24, 25, 0.42)"
            }}>{total}</p>
        </Box>
    )
}

MultiProgressBar.propTypes = {
    input: PropTypes.array,
    height: PropTypes.number,
    width : PropTypes.number
}

export default MultiProgressBar