export const transparencyTraceJSON = {
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "multipletext",
                    "name": "Sourcing_TandT_topfive_1",
                    "title": "Please list your top five sourcing countries for finished goods by volume. ",
                    "description": "If you purchase products from less than five countries, please list as many as are applicable. Please enter the country from which you purchase the most amount of products by volume first.",
                    "isRequired": true,
                    "items": [
                        {
                            "name": "1",
                            "isRequired": true
                        },
                        {
                            "name": "2"
                        },
                        {
                            "name": "3"
                        },
                        {
                            "name": "4"
                        },
                        {
                            "name": "5"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "Sourcing_TandT_200miles_2",
                    "title": "What percentage of your direct suppliers are located within 200 miles of your company?",
                    "description": "Please enter the percentage as a number from 0 - 100",
                    "isRequired": true,
                    "inputType": "number",
                    "min": "0",
                    "max": "100"
                },
                {
                    "type": "text",
                    "name": "Sourcing_TandT_volumeincrease_3",
                    "title": "Do you track your year-on-year production volume increase?",
                    "description": "Production volume refers to the total amount of products that your company sells. We are asking if you are aware of whether this is increasing year-on-year. Most likely this will be expressed in number of units. Please enter the percentage as a number from 0 - 100. If you are unsure please write 0.",
                    "isRequired": true,
                    "inputType": "number",
                    "min": "0",
                    "max": "100"
                },
                {
                    "type": "radiogroup",
                    "name": "Sourcing_TandT_paymentterms_4",
                    "title": "What are your standard payment terms with suppliers? ",
                    "description": "This question is seeking to understand how soon you pay your suppliers after receiving your order from them. This has become especially important in light of disruption to fashion supply chains resulting from the Covid-19 pandemic.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "item1",
                            "text": "On delivery"
                        },
                        {
                            "value": "item2",
                            "text": "30 days"
                        },
                        {
                            "value": "item3",
                            "text": "60 days"
                        },
                        {
                            "value": "item4",
                            "text": "90 days"
                        },
                        {
                            "value": "item5",
                            "text": "Other"
                        }
                    ],
                    "showOtherItem": true
                },
                {
                    "type": "text",
                    "name": "question1",
                    "visibleIf": "{Sourcing_TandT_Directsuppliers_5} = 1",
                    "indent": 3,
                    "title": "If you have selected \"other\", please provide the number or describe ",
                    "hideNumber": true,
                    "inputType": "number"
                },
                {
                    "type": "radiogroup",
                    "name": "Sourcing_TandT_Directsuppliers_5",
                    "title": "Do you know how many direct suppliers you have?",
                    "description": "This question is asking how many direct (tier one) suppliers you purchase finished products from.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "Sourcing_TandT_Directsuppliers_num",
                    "visibleIf": "{Sourcing_TandT_Directsuppliers_5} = 1",
                    "indent": 3,
                    "title": "Please provide the number",
                    "hideNumber": true,
                    "isRequired": true,
                    "inputType": "number"
                },
                {
                    "type": "radiogroup",
                    "name": "Sourcing_TandT_suppliernames_5.1",
                    "visibleIf": "{Sourcing_TandT_Directsuppliers_5} = 1",
                    "indent": 3,
                    "title": "5.1 Do you know the names and addresses of all your direct suppliers? ",
                    "description": "This question is asking whether you know the names and registered addresses of all your direct (tier one) suppliers. We are asking this as it is important for visibility into your supply chain and traceability.",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "Sourcing_TandT_publiclist_5.2",
                    "visibleIf": "{Sourcing_TandT_Directsuppliers_5} = 1",
                    "indent": 3,
                    "title": "5.2. Is your direct supplier list publicly available online? ",
                    "description": "We are asking this because publicly sharing supplier lists is an industry recognised way of demonstrating accountability and transparency. This question refers to the factories of your direct (tier one) suppliers.",
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes - we publish all of our direct suppliers online"
                        },
                        {
                            "value": "2",
                            "text": "Yes - we publish some of our direct suppliers online"
                        },
                        {
                            "value": "3",
                            "text": "No - we do not publish this information"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "Sourcing_TandT_publiclist_link",
                    "visibleIf": "{Sourcing_TandT_Directsuppliers_5} = 1 and {Sourcing_TandT_publiclist_5.2} <= 2",
                    "indent": 5,
                    "title": "Please provide the link",
                    "hideNumber": true,
                    "isRequired": true
                },
                {
                    "type": "file",
                    "name": "Sourcing_TandT_publiclist_file",
                    "visibleIf": "{Sourcing_TandT_Directsuppliers_5} = 1 and {Sourcing_TandT_publiclist_5.2} = 3",
                    "indent": 5,
                    "title": "Please upload to share internally",
                    "description": "Examples of evidence include documentation that shows the factory name and address of where production took place - this might be a production order or a contract.",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "Sourcing_TandT_publiclist_desc",
                    "visibleIf": "{Sourcing_TandT_Directsuppliers_5} = 1 and {Sourcing_TandT_publiclist_5.2} = 3",
                    "indent": 5,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{Sourcing_TandT_Directsuppliers_5} = 1 and {Sourcing_TandT_publiclist_5.2} = 3 and {Sourcing_TandT_publiclist_file} empty"
                },
                {
                    "type": "text",
                    "name": "Sourcing_TandT_OAR_5.3",
                    "visibleIf": "{Sourcing_TandT_Directsuppliers_5} = 1",
                    "indent": 3,
                    "title": "5.3 What percentage of your suppliers are listed in the Open Apparel Registry? ",
                    "description": "The Open Apparel Registry is an open source map and database of global apparel facilities that assigns a unique ID to each facility. Please write 0 if you are not sure.",
                    "hideNumber": true,
                    "isRequired": true,
                    "inputType": "number",
                    "min": "0",
                    "max": "100"
                },
                {
                    "type": "comment",
                    "name": "Sourcing_TandT_OAR_link",
                    "visibleIf": "{Sourcing_TandT_OAR_5.3} notempty",
                    "indent": 5,
                    "title": "Please provide the links of the facilities",
                    "hideNumber": true,
                    "isRequired": true,
                    "rows": 2
                },
                {
                    "type": "text",
                    "name": "Sourcing_TandT_visibilitypercent_6",
                    "title": "What percent of your supply chain, beyond your direct suppliers, are you able to track? ",
                    "description": "This question seeks to understand how much knowledge you have of the factories, farms, processing plants, etc. that provide products to your direct (tier one) suppliers. Please enter the number as a percentage from 0 - 100.",
                    "isRequired": true,
                    "inputType": "number",
                    "min": "0",
                    "max": "100"
                },
                {
                    "type": "file",
                    "name": "Sourcing_TandT_visibilitypercent_file",
                    "indent": 3,
                    "title": "Please upload supporting evidence",
                    "description": "Examples of evidence include documentation that shows the factory name and address of where production took place - this might be a production order or a contract.",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "Sourcing_TandT_visibilitypercent_desc",
                    "indent": 3,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true
                },
                {
                    "type": "comment",
                    "name": "Sourcing_transparencyOther",
                    "title": " Is there anything else that you would like to share about Transparency and traceability?",
                    "hideNumber": true
                }
            ],
            "descriptionEdit": "This section focuses on how much visibility you have of your supply chain."
        }
    ],
    "showCompletedPage": false
}