export const waterMgtJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "Planet_Water_Track_1",
                    title: "Do you monitor or track the water used in the manufacturing of your products?  ",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "checkbox",
                    name: "Planet_Water_Efficiency_2",
                    visibleIf: "{Planet_Water_Track_1} = 1",
                    title: "Are any water efficiency or water conservation processes used in the manufacturing of your products?  ",
                    description: "Producing clothes uses a colossal amount of water, with each of the different stages in production having a demand for water. Please select any of the techniques that your suppliers use to reduce or manage this demand.",
                    requiredIf: "{Planet_Water_Track_1} = 1",
                    choices: [
                        {
                            value: "1",
                            text: "Reduced water / waterless dying techniques"
                        },
                        {
                            value: "2",
                            text: "Reduced water manufacturing techniques"
                        },
                        {
                            value: "3",
                            text: "Utilising recylced water systems"
                        },
                        {
                            value: "4",
                            text: "Other"
                        },
                        {
                            value: "5",
                            text: "No"
                        },
                        {
                            value: "6",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Water_Efficiency_file",
                    visibleIf: "{Planet_Water_Track_1} = 1 and {Planet_Water_Efficiency_2} anyof [1, 2, 3, 4]",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include any information from your suppliers that document their processes. ",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Water_Efficiency_other",
                    visibleIf: "{Planet_Water_Track_1} = 1 and {Planet_Water_Efficiency_2} anyof [4, 1, 2, 3]",
                    indent: 3,
                    title: "Please provide details",
                    description: "Examples of evidence include onboarding documents or emails/attachments from suppliers referencing water efficiency processes. You will need to show this for each supplier.",
                    hideNumber: true,
                    isRequired: true,
                    requiredIf: "{Planet_Water_Efficiency_file} empty"
                },
                {
                    type: "text",
                    name: "Planet_Water_Compliance_3",
                    visibleIf: "{Planet_Water_Track_1} = 1",
                    title: "How are your suppliers in compliance with local legal or regulatory requirements for waste water discharge?  ",
                    description: "This refers to any policies that the facilities manufacturing your products may follow in disposing of waste water from production. Please describe their processes in the box below or upload supporting evidence..",
                    isRequired: true
                },
                {
                    type: "file",
                    name: "Planet_Water_Compliance_file",
                    visibleIf: "{Planet_Water_Track_1} = 1 and {Planet_Water_Compliance_3} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include any information from your suppliers that document their processes. ",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "checkbox",
                    name: "Planet_Water_Supplierreport_3.1",
                    visibleIf: "{Planet_Water_Track_1} = 1 and {Planet_Water_Compliance_3} notempty",
                    indent: 3,
                    title: "3.1 Do any of your suppliers disclose and report on wastewater standards? ",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "item7",
                            text: "Yes - Zero Discharge of Hazardous Chemicals (ZDHC) Programme Wastewater Guidelines"
                        },
                        {
                            value: "item2",
                            text: "Yes - BSR Sustainable Water Group Water Quality Guidelines"
                        },
                        {
                            value: "item3",
                            text: "Yes - The Institute of Public & Environmental Affairs (IPE) wastewater guidelines. "
                        },
                        {
                            value: "item4",
                            text: "Yes - Higg Factility Environmental Module (Section 4 - Water Use)"
                        },
                        {
                            value: "item5",
                            text: "Yes - other"
                        },
                        {
                            value: "item6",
                            text: "No"
                        }
                    ],
                    hasOther: true,
                    otherText: "I don't know"
                },
                {
                    type: "file",
                    name: "Planet_Water_Supplierreport_file",
                    visibleIf: "{Planet_Water_Track_1} = 1 and {Planet_Water_Supplierreport_3.1} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include any information from your suppliers that document their processes. ",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "radiogroup",
                    name: "Planet_Water_Analysis_4",
                    visibleIf: "{Planet_Water_Track_1} = 1",
                    title: "Have you ever carried out any analysis into the water footprint of your products and/or supply chain?  ",
                    description: "This refers to any analysis that quantifies the amount of water used to manufacture the products you sell.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Water_Analysis_file",
                    visibleIf: "{Planet_Water_Track_1} = 1 and {Planet_Water_Analysis_4} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Water_Analysis_desc",
                    visibleIf: "{Planet_Water_Track_1} = 1 and {Planet_Water_Analysis_4} = 1",
                    indent: 3,
                    title: "If you do not have something to upload, please describe the process.",
                    description: "This may include the metric litres of water used.",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "comment",
                    name: "Planet_Water_Other",
                    visibleIf: "{Planet_Water_Track_1} = 1",
                    title: "Is there anything else that you would like to share about Water management?",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on any water management practices used by your suppliers as they manufacture your products.  "
        }
    ],
    showCompletedPage: false
}