import React from 'react'
import { useHistory } from 'react-router-dom';
import {
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as CompletedIcon } from '../../../assets/svg/completedSmall.svg';
import { useStyles } from '../../../UI/styles/themes';
import PropTypes from 'prop-types';

const Panel = (props) => {
    const history = useHistory();
    const {
        heading,
        status,
        sections,
        url,
        subSectionStatus,
        pathName,
        expandedPanel,
        setExpandedPanel
    } = props;

    const sectionToUrl = (section) => {
        return section.toLowerCase().split(' ').join('');
    }

    const handleListItemClick = (event, index, section) => {
        history.push(`/survey/${url}/${sectionToUrl(section)}`);
    };

    const handleAccordionChange = (url) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? url : false);
    };

    // allow use of customer styles/classes from themes.jsx
    const classes = useStyles();

    return (
        <Accordion
            className={classes.accordianPanel}
            expanded={ expandedPanel === url}
            onChange={handleAccordionChange(url)}
        >
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon style={{ color: "#5184C0"}} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                style={pathName.includes(url) ? {color: '#5155F8'} : {}}
            >
                {props.children}
                <Box
                    ml="16px"
                    width={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Box>
                        <Typography className={classes.H300}>{heading}</Typography>
                    </Box>
                    {
                        status ?
                            <Box color="success.main">
                                <Typography variant='subtitle2'>Completed</Typography>
                            </Box>
                            :
                            <Box color="grey.main">
                                <Typography variant="subtitle2">Incomplete</Typography>
                            </Box>
                    }
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box width={1} display="flex" flexDirection='column'>
                    <ul style={{listStyle: 'disc'}}>
                        {
                            sections.map((section, index) => {
                                return (
                                    <li key={`${section}-${index}`}>
                                        <ListItem
                                            key={section}
                                            button
                                            disableRipple='false'
                                            selected={pathName === `/survey/${url}/${sectionToUrl(section)}`}
                                            onClick={(event) => handleListItemClick(event, index, section)}
                                        >
                                            <ListItemText primary={section} />
                                            <ListItemIcon >
                                                {
                                                    subSectionStatus[index] ?
                                                        <CompletedIcon
                                                            fill="#6FCF97"
                                                        /> :
                                                        <CompletedIcon
                                                            fill="#DFDFE2"
                                                        />
                                                }
                                            </ListItemIcon>
                                        </ListItem>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

Panel.propTypes = {
    heading: PropTypes.string,
    status: PropTypes.bool,
    sections: PropTypes.array,
    url: PropTypes.string,
    subSectionStatus: PropTypes.array,
    pathName: PropTypes.string,
    expandedPanel: PropTypes.string,
    setExpandedPanel: PropTypes.func,
    children: PropTypes.node,
}

export default Panel