import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/ghg12.jpeg';

const GHG12 = () => {
    const refs = [
        "Fashion on Climate, Global Fashion Agenda, no date: http://www2.globalfashionagenda.com/initiatives/fashion-on-climate/#/fashion-value-cycle",
        "Fast Fashion Needs to Slow Down for the Climate, Climate Council, 2021: https://www.climatecouncil.org.au/resources/fast-fashion-climate-change/",
        "Can Fashion Ever Be Sustainable?, BBC, 2020: https://www.bbc.com/future/article/20200310-sustainable-fashion-how-to-buy-clothes-good-for-the-climate",
        "Greenhouse Gas Protocol, no date: https://ghgprotocol.org/",
        "Apparel and Footwear Science Based Targets Guidance, Science Based Targets, 2020: https://sciencebasedtargets.org/resources/files/SBT_App_Guide_final_0718.pdf",
        "The Biggest Problem With Carbon Offsetting Is That It Doesn’t Really Work, Greenpeace, 2020: https://www.greenpeace.org.uk/news/the-biggest-problem-with-carbon-offsetting-is-that-it-doesnt-really-work/"
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title={`GHG emissions from your company (Scope 1 & 2)`} isMain={true}/>
            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" refs="1" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>
                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>
                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>
            It is estimated that the fashion industry accounts for some 1.2 billion tonnes of CO2 equivalent per year<sup>2</sup>. Put another way, that’s eight to ten percent of all greenhouse gas (GHG) emissions globally<sup>3</sup>.
                            <br /><br /> 
            The extracting and burning of fossil fuels such as coal and gas release numerous GHGs, including carbon dioxide, into the earth’s atmosphere. The accumulation of these GHGs is causing the earth to heat.            
                        </Typography>
                    </Box>
                </Box>

                <Typography style={{fontSize: "1.1rem"}}>
          We talk the most about carbon in relation to climate change and use carbon as a kind of catch-all for GHG as the unit for collectively measuring GHGs is CO2e, or carbon equivalent.
                    <br /><br /> 
          This resource explores the GHG emissions from your company (scope 1 & 2). Please see the accompanying resource “GHG emissions from your production” for an overview of scope 3 emissions - that is, those ghg emissions related to your supply chain and your products.
                    <br /><br /> 
          Let’s begin with some definitions<sup>4 5</sup>.
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Scope 1 emissions</span> refers to GHG emissions that result directly from your in-house operations.
                        <br /><br /> 
            This means those emissions that are controlled by your brand directly. Scope 1 emissions include emissions from any delivery vehicles that you fully own, emissions from any in-house manufacturing that you may do as a brand. 
                    </li>
                    <li style={{margin: "10px 0"}}>
                        <span style={{fontWeight: "600"}}>Scope 2 emissions</span> refers to the GHG emissions that result indirectly from the generation of energy your brand purchases.
                        <br /><br /> 
            As a concrete example, this includes the electricity you use to power the lights in your office which you purchase from the national electricity grid.
                    </li>
                </ul>

                <ResourceTitle title="Calculating your scope 1 & scope 2 GHG emissions" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
          The first action to take is to calculate your emissions scopes. This will give you an understanding of how many emissions your company is generating. Once you have an idea of how many emissions you have, you can begin actions to reduce them and measure the progress of these reductions over time.
                    <br /><br /> 
          When it comes to calculating emissions, scope 1 and scope 2 will generally be fairly straightforward. These are emissions that relate specifically to your company and your operations. There are a number of tools which are completely free to access and which can help you begin this process. 
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0", listStyleType: 'square'}}>The SME Climate Hub has created a free <a href="https://smeclimatehub.org/start-measuring/" target="_blank" rel="noopener noreferrer">Business Carbon Calculator</a> in collaboration with Google and the sustainability company Normative. Once you have created an account, you are able to add business data such as information on electricity use and business travel to calculate your corporate emissions.</li>

                    <li style={{margin: "10px 0", listStyleType: 'square'}}>Another resource is the UK Carbon Trust, which has developed a <a href="https://www.carbontrust.com/resources/sme-carbon-footprint-calculator" target="_blank" rel="noopener noreferrer">Carbon Footprint Calculator</a> designed to specifically help UK-based SMEs measure their scope 1 and scope 2 emissions.</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
          It is worth taking a look through the accompanying guidance these organisations share on their websites and to familiarise yourself with the types of data they are asking for.
                    <br /><br /> 
          Once you’ve calculated your emissions, you can establish your carbon baseline. This means how many carbon emissions comprise your scope 1 and scope 2. The baseline is what you will measure your reductions against, and what you can use to track your future progress.
                </Typography>

                <Typography style={{
                    fontSize: "1.2rem",
                    textDecoration: "underline",
                    fontWeight: "600",
                    margin: "20px 0px"
                }}>Offset or reduce?<sup>6</sup></Typography>
        
                <Typography style={{fontSize: "1.1rem"}}>
          Before moving on to the types of emissions reduction activities you could take, it is worth exploring this question.
                    <br /><br /> 
          You might have heard the phrase carbon offset. How is this different to carbon reduction?
                    <br /><br /> 
          Carbon offset is a way to compensate for your emissions. Offsetting generally means paying another organisation to reduce or absorb carbon emissions to compensate for your own. An example of a carbon offset activity might include planting trees or supporting a renewable energy project. 
                    <br /><br /> 
          Carbon offset projects can be good, however they do not necessarily result in the cutting of emissions. 
                    <br /><br /> 
          Therefore, a carbon reduction activity is preferable to a carbon offset activity. 
                    <br /><br /> 
          Ultimately, carbon offset activities will not result in the net reduction of carbon emissions we need to prevent the planet from continuing to heat up.
                </Typography>

                <Typography style={{
                    fontSize: "1.2rem",
                    textDecoration: "underline",
                    fontWeight: "600",
                    margin: "20px 0px"
                }}>Emissions reduction activities for your company (scope 1 and scope 2)</Typography>

                <Typography style={{fontSize: "1.1rem"}}>To start, examine what changes you can make with these “low hanging fruit”.</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Make energy efficiency swaps wherever possible - for example, switch to LED lighting in all your sites.</li>
                    <li style={{margin: "10px 0"}}>Make sure your electricity provider is supplying you with a 100% green tariff.</li>
                    <li style={{margin: "10px 0"}}>Ensure any transportation uses a very low emissions vehicle fleet. Implement a low emissions policy requirement for any owned or leased company vehicles.</li>
                    <li style={{margin: "10px 0"}}>Make sure the buildings you use are properly insulated and double glazed to improve energy efficiency.</li>
                    <li style={{margin: "10px 0"}}>Minimise returns by improving size and fit predictions. This could not only reduce emissions associated with customer returns but also save you money on postage.</li>
                    <li style={{margin: "10px 0"}}>In addition to their free Business Carbon Calculator (above), The <a href="https://smeclimatehub.org/tools/" target="_blank" rel="noopener noreferrer">SME Climate Hub</a> is also an excellent place for more resources and ideas for how to reduce your carbon footprint.</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>As the most significant part of your GHG emissions relate to your supply chain manufacturing and to your products, make sure to read through the accompanying resource “GHG emissions from your production” for an overview of scope 3 emissions.</Typography>
            </Box>  

            <ResourcesReferences references={refs} />
        </Box>
    )
}

export default GHG12