import { Box } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

export const Page = ({ children }) => {
    return (
        <Box
            display="flex"
            width="100%"
            height="100vh"
            bgcolor="#F4F6FB"
            py={1}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                marginBottom="28px"
            >
            </Box>
            <Box display="flex" justifyContent="center">
                {children}
            </Box>
        </Box>
    );
};

Page.propTypes = {
    children: PropTypes.node,
}