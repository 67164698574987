export const environmentalMgtJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "checkbox",
                    name: "Planet_Env_Policy_1",
                    title: "Does your company have any policy or procedures relating to managing and reducing the environmental impact of your operations?  ",
                    description: "You might call this something else - such as a goal or set of objectives. Please select the actions you have taken below.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "We have undertaken an assessment to understand our environmental impact"
                        },
                        {
                            value: "2",
                            text: "We have a written document covering pollution prevention, air emissions reduction and compliance with environmental regulation"
                        },
                        {
                            value: "3",
                            text: "We have worked with a third party to audit and/or certify our environmental reduction strategy"
                        },
                        {
                            value: "5",
                            text: "No"
                        }
                    ],
                    hasOther: true,
                    otherText: "Other"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Env_PolicyPublic_1.1",
                    visibleIf: "{Planet_Env_Policy_1} = [1]",
                    indent: 3,
                    title: "1.1 Are they public?",
                    description: "\nWe would like to know if you communicate your environmental objectives publicly - for example online - as this increases accountability.\n",
                    hideNumber: true,
                    isRequired: true,
                    requiredIf: "{Planet_Env_Policy_1} = 1",
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Planet_Env_PolicyPublic_link",
                    visibleIf: "{Planet_Env_Policy_1} = 1 and {Planet_Env_PolicyPublic_1.1} = 1",
                    indent: 4,
                    title: "Please provide the link",
                    hideNumber: true,
                    isRequired: true,
                    requiredIf: "{Planet_Env_Policy_1} = 1 and {Planet_Env_PolicyPublic_1.1} = 1",
                    inputType: "url"
                },
                {
                    type: "file",
                    name: "Planet_Env_PolicyPublic_file",
                    visibleIf: "{Planet_Env_Policy_1} = 1 and {Planet_Env_PolicyPublic_1.1} = 2",
                    indent: 4,
                    title: "Please upload to share internally",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false,
                    acceptedTypes: "*.pdf,*.docx"
                },
                {
                    type: "comment",
                    name: "Planet_Env_PolicyPublic_desc",
                    visibleIf: "{Planet_Env_Policy_1} = 1 and {Planet_Env_PolicyPublic_1.1} = 2",
                    indent: 4,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    placeHolder: "Environment Policy "
                },
                {
                    type: "text",
                    name: "Planet_Env_PolicySuppliers_2",
                    title: "What percentage of your suppliers have systems in place to manage and reduce the environmental impacts of their operations?",
                    description: "This is about any actions, steps, or goals your suppliers might have shared with you to reduce their environmental impacts. Please enter a number between 0 - 100. It's ok to estimate this number.",
                    isRequired: true,
                    inputType: "number",
                    min: "0",
                    max: "100",
                    step: 0
                },
                {
                    type: "file",
                    name: "Planet_Env_PolicySuppliers_file",
                    visibleIf: "{Planet_Env_PolicySuppliers_2} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false,
                    acceptedTypes: "*.pdf,*.docx"
                },
                {
                    type: "comment",
                    name: "Planet_Env_PolicySuppliers_desc",
                    visibleIf: "{Planet_Env_PolicySuppliers_2} notempty",
                    indent: 3,
                    title: "If you do not have something to upload, please describe their process",
                    hideNumber: true,
                    requiredIf: "{Planet_Env_PolicySuppliers_file} empty"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Env_OrgMember_3",
                    title: "Are you or any of your suppliers a member of an independently accredited environmental organisation, initiative or body?",
                    description: "This refers to any organisation, initiative, or trade body aimed at reducing the environmental impact of the fashion industry - such as the Sustainable Apparel Coalition/Higg Index or B Corp.",
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        },
                        {
                            value: "3",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Planet_Env_OrgMember_desc",
                    visibleIf: "{Planet_Env_OrgMember_3} = 1",
                    indent: 3,
                    title: "Please enter the name",
                    hideNumber: true,
                    requiredIf: "{Planet_Env_OrgMember_3} = 1"
                },
                {
                    type: "file",
                    name: "Planet_Env_OrgMember_file",
                    visibleIf: "{Planet_Env_OrgMember_3} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false,
                    acceptedTypes: "*.pdf,*.docx"
                },
                {
                    type: "radiogroup",
                    name: "Planet_Env_Suppliersreport_4",
                    title: "Do any of your suppliers disclose and report their environmental performance?",
                    description: "One of your suppliers may be reporting their environmental performance to the Higg Index. Please indicate this here, entering the name of the reporting type below.",
                    choices: [
                        {
                            value: "item1",
                            text: "Yes "
                        },
                        {
                            value: "item2",
                            text: "No"
                        }
                    ],
                    hasNone: true,
                    noneText: "I don't know",
                    otherText: "No"
                },
                {
                    type: "text",
                    name: "Planet_Env_Suppliersreport_desc",
                    visibleIf: "{Planet_Env_Suppliersreport_4} = 'item1'",
                    indent: 3,
                    title: "Please enter the name of the reporting type below (e.g. Higg Facility Environmental Module).",
                    hideNumber: true,
                    isRequired: true,
                    requiredIf: "{Planet_Env_Suppliersreport_4} = 'item1'"
                },
                {
                    type: "comment",
                    name: "Planet_Env_Other",
                    title: "Is there anything else that you would like to share about Environmental policies?",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on any steps you might have taken to measure the environmental impacts of your company and production."
        }
    ],
    showCompletedPage: false
}