export const communityJSON = {
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "checkbox",
                    "name": "People_tradeOrgMember",
                    "title": "Are you or any of your suppliers a member of an ethical trade organisation, initiative or body? (multiple selection possible)",
                    "description": "These are organisations, initiatives, or trade bodies that work to create better social and labour standards in the fashion industry. If you or one of your suppliers is are part of an organisation, initiative, or trade body that is not on this list, please select \"Other\" to enter it.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Better Work"
                        },
                        {
                            "value": "2",
                            "text": "Ethical Trading Initiative ETI"
                        },
                        {
                            "value": "3",
                            "text": "Fair Labor Association FLA"
                        },
                        {
                            "value": "4",
                            "text": "Fair Wear Foundation FWF"
                        },
                        {
                            "value": "5",
                            "text": "Sedex"
                        },
                        {
                            "value": "6",
                            "text": "Social Accountability International SAI"
                        },
                        {
                            "value": "7",
                            "text": "Other"
                        },
                        {
                            "value": "8",
                            "text": "No"
                        },
                        {
                            "value": "9",
                            "text": "I don't know"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "People_tradeOrgMemberOther",
                    "visibleIf": "{People_tradeOrgMember} contains 7",
                    "indent": 3,
                    "title": "Please enter the name below",
                    "hideNumber": true,
                    "isRequired": true
                },
                {
                    "type": "file",
                    "name": "People_tradeOrgMember_file",
                    "visibleIf": "{People_tradeOrgMember} anyof [1, 2, 3, 4, 5, 6, 7]",
                    "indent": 3,
                    "title": "Please upload supporting evidence",
                    "hideNumber": true,
                    "isRequired": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "checkbox",
                    "name": "People_workerEdu",
                    "title": "Have you ever provided any worker education or community development initiatives to improve worker well-being beyond local legal requirements? (multiple selection possible)",
                    "description": "These could be any type of education or community development initiatives that improve the well-being and conditions of workers in your supply chain. It could be something your company has undertaken or be part of a third-party initiative.  ",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Care International"
                        },
                        {
                            "value": "2",
                            "text": "HERproject"
                        },
                        {
                            "value": "3",
                            "text": "Women's Empowerment Principles"
                        },
                        {
                            "value": "4",
                            "text": "Other"
                        },
                        {
                            "value": "6",
                            "text": "Change Associates"
                        },
                        {
                            "value": "8",
                            "text": "Fast Forward"
                        },
                        {
                            "value": "7",
                            "text": "Freedom Business Alliance"
                        },
                        {
                            "value": "5",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "People_workerEduOther",
                    "visibleIf": "{People_workerEdu} contains 4",
                    "indent": 3,
                    "title": "Please enter the name below",
                    "hideNumber": true,
                    "isRequired": true
                },
                {
                    "type": "file",
                    "name": "People_workerEdu_file",
                    "visibleIf": "{People_workerEdu} anyof [1, 2, 3, 4]",
                    "indent": 3,
                    "title": "Please upload supporting evidence",
                    "hideNumber": true,
                    "isRequired": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "radiogroup",
                    "name": "People_wageAnalysisSC",
                    "title": "Have you ever carried out a wage analysis of any of your supply chain?  ",
                    "description": "This question aims to understand whether you have ever carried out any analysis into the wages received by the workers of your suppliers - for example, to understand how the wages they receive compare to the local cost of living.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "file",
                    "name": "People_wageAnalysisSC_file",
                    "visibleIf": "{People_wageAnalysisSC} = 1",
                    "indent": 3,
                    "title": "Please upload supporting evidence",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "People_wageAnalysisSC_desc",
                    "visibleIf": "{People_wageAnalysisSC} = 1",
                    "indent": 3,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{People_wageAnalysisSC} = 1 and {People_wageAnalysisSC_file} empty"
                },
                {
                    "type": "radiogroup",
                    "name": "People_livingWageSC",
                    "title": "Have you taken any action to ensure any of your supply chain is paid a living wage? ",
                    "description": "A living wage is defined as the minimum income necessary for somebody to meet their basic needs. This is not the same as a minimum wage, which is generally implied as a legal requirement. A living wage varies according to where a supplier is based, but should allow somebody to pay for food, housing and utilities in addition to other essentials such as education, medicine and savings.",
                    "isRequired": true,
                    "choices": [
                        {
                            "value": "1",
                            "text": "Yes"
                        },
                        {
                            "value": "2",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "file",
                    "name": "People_livingWageSC_file",
                    "visibleIf": "{People_livingWageSC} = 1",
                    "indent": 3,
                    "title": "Please upload supporting evidence",
                    "description": "Examples of evidence include a report from your supplier or anonymised wage information such as anonymised payslips. You will need this for each supplier.",
                    "hideNumber": true,
                    "allowMultiple": true,
                    "allowImagesPreview": false
                },
                {
                    "type": "comment",
                    "name": "People_livingWageSC_desc",
                    "visibleIf": "{People_livingWageSC} = 1",
                    "indent": 3,
                    "title": "If you do not have something to upload, please describe your process",
                    "hideNumber": true,
                    "requiredIf": "{People_livingWageSC} = 1 and {People_livingWageSC_file} empty"
                },
                {
                    "type": "comment",
                    "name": "People_communityOther",
                    "title": "Is there anything else that you would like to share about People in your supply chain: Community? ",
                    "hideNumber": true
                }
            ],
            "descriptionEdit": "This section focuses on the community aspect of worker well-being, from membership bodies to fair pay."
        }
    ],
    "showCompletedPage": false
}