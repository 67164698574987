export const chemManJSON = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "Planet_chem_1",
                    title: "Do you monitor, track or report the chemicals used in the manufacturing of your products?  ",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "Planet_Chemicals_Compliance_2",
                    visibleIf: "{Planet_chem_1} = 1",
                    title: "How do you ensure compliance with chemical regulations in the jurisdictions in which you sell?  ",
                    description: "This refers to REACH guidelines if your products are sold in the UK/EU. If they are sold elsewhere, they should comply with local guidelines (e.g. Prop 65 in California). Please describe your process in the box below or upload supporting evidence.",
                    isRequired: true
                },
                {
                    type: "file",
                    name: "Planet_Chemicals_Compliance_file",
                    visibleIf: "{Planet_chem_1} = 1 and {Planet_Chemicals_Compliance_2} notempty",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include any information from your suppliers that confirm compliance.",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "radiogroup",
                    name: "Planet_Chemicals_Trackhazardous_3",
                    visibleIf: "{Planet_chem_1} = 1",
                    title: "Do you you track or manage the levels of hazardous chemicals used in your products? ",
                    description: "Chemicals will be used processes such as dyeing or finishing the fabrics which make your products. This question is asking whether your company set limits beyond government regulation as to the level of chemicals that are acceptable to be found in the products you sell. Some companies refer to this as a Restricted Substance List (RSL). This doesn't need to be a formal document.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Yes"
                        },
                        {
                            value: "2",
                            text: "No"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Chemicals_Trackhazardous_file",
                    visibleIf: "{Planet_chem_1} = 1 and {Planet_Chemicals_Trackhazardous_3} = 1",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    hideNumber: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Chemicals_Trackhazardous_desc",
                    visibleIf: "{Planet_chem_1} = 1 and {Planet_Chemicals_Trackhazardous_3} = 1",
                    indent: 3,
                    title: "If you do not have something to upload, please describe your process",
                    hideNumber: true,
                    requiredIf: "{Planet_chem_1} = 1 and {Planet_Chemicals_Trackhazardous_3} = 1 and {Planet_Chemicals_Trackhazardous_file} empty"
                },
                {
                    type: "checkbox",
                    name: "Planet_Chemicals_Certifications_4",
                    visibleIf: "{Planet_chem_1} = 1",
                    title: "Are any third-party certifications used to verify the chemical content of your products? ",
                    description: "Several third-party certifications, initiatives, and processes exist to limit the amount of chemical exposure to workers as well as reduce chemical waste. Select any initiatives that your company's suppliers are part of or select \"Other\" to describe any practices that are not listed.",
                    isRequired: true,
                    choices: [
                        {
                            value: "1",
                            text: "Bluesign"
                        },
                        {
                            value: "2",
                            text: "OEKO-TEX Made In Green"
                        },
                        {
                            value: "3",
                            text: "OEKO-TEX Passport"
                        },
                        {
                            value: "4",
                            text: "OEKO-TEX STeP"
                        },
                        {
                            value: "5",
                            text: "ToxFMD"
                        },
                        {
                            value: "6",
                            text: "ZDHC"
                        },
                        {
                            value: "10",
                            text: "OEKO-TEX Standard 100 Certification"
                        },
                        {
                            value: "11",
                            text: "Eco-Passport"
                        },
                        {
                            value: "12",
                            text: "Intertek Eco-Certification"
                        },
                        {
                            value: "7",
                            text: "Other"
                        },
                        {
                            value: "8",
                            text: "No"
                        },
                        {
                            value: "9",
                            text: "I don't know"
                        }
                    ]
                },
                {
                    type: "file",
                    name: "Planet_Chemicals_Certifications_file",
                    visibleIf: "{Planet_chem_1} = 1 and {Planet_Chemicals_Certifications_4} anyof [1, 2, 3, 4, 5, 6, 7, 10, 11, 12]",
                    indent: 3,
                    title: "Please upload supporting evidence",
                    description: "Examples of evidence include any certifications linked to specific supplier processes in emails, documents or reports from production tracking software.",
                    hideNumber: true,
                    isRequired: true,
                    allowMultiple: true,
                    allowImagesPreview: false
                },
                {
                    type: "comment",
                    name: "Planet_Chemicals_Certifications_other",
                    visibleIf: "{Planet_chem_1} = 1 and {Planet_Chemicals_Certifications_4} contains 7",
                    indent: 3,
                    title: "Please provide details",
                    hideNumber: true,
                    isRequired: true
                },
                {
                    type: "comment",
                    name: "Planet_Chemicals_Other",
                    visibleIf: "{Planet_chem_1} = 1",
                    title: "Is there anything else that you would like to share about Chemical management?",
                    hideNumber: true
                }
            ],
            descriptionEdit: "This section focuses on any chemical management practices used by your company and your suppliers in manufacturing your products."
        }
    ],
    showCompletedPage: false
}