import React from 'react'
import HomePage from '../../../UI/templates/HomePage';
import NavbarSurvey from '../../../components/NavbarSurvey';
import { Box, Typography } from '@material-ui/core';
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import { Company } from '../SurveyPages/Company';
import { Goals } from '../SurveyPages/Goals';
import PeopleSection from '../SurveyPages/PeopleSection/PeopleSection';
import SourcingSection from '../SurveyPages/SourcingSection';
import PlanetSection from '../SurveyPages/PlanetSection';
import { useStyles } from '../../../UI/styles/themes';

const Survey = () => {
    const { path } = useRouteMatch();
    const classes = useStyles();

    return (
        <HomePage>
            <Box height="100vh" display="flex" flexDirection='row' bgcolor="white.main" borderRight="1px solid #E2E5ED">
                <Box minWidth="335px">
                    <Box width={1} color="grey.main" px="39px" pt="31px">
                        <Box m='0px' p='0px'>
                            <Typography className={classes.H200}>Survey</Typography>
                        </Box>
                        <NavbarSurvey />
                    </Box>
                </Box>
                <Box display="flex" justifyContent='center' width={1} className={classes.overScroll}>
                    <Switch>
                        {/* Company */}
                        <Route path={`${path}/company`}>
                            <Company />
                        </Route>
                        {/* People */}
                        <Route path={`${path}/people`}>
                            <PeopleSection />
                        </Route>
                        {/* Planet */}
                        <Route path={`${path}/planet`}>
                            <PlanetSection />
                        </Route>
                        {/* Sourcing */}
                        <Route path={`${path}/sourcing`}>
                            <SourcingSection />
                        </Route>
                        <Route path={`${path}/yourgoals`}>
                            <Goals />
                        </Route>
                        <Redirect from={path} to={`${path}/company`} />
                    </Switch>
                </Box>
            </Box>
        </HomePage>
    )
}

export default Survey
