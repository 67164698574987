export const matJSON = {
    title: "V3_Sourcing_Materials",
    logoPosition: "right",
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "Sourcing_Materials_Stage_1",
                    title: "At which stage does your company mostly source? ",
                    description: "An example of a finished good is a t-shirt; an example of a finished fabric is a dyed roll of cotton fabric; an example of a raw material is cotton that can be spun and woven.",
                    isRequired: true,
                    choices: [
                        {
                            value: "item1",
                            text: "Finished goods"
                        },
                        {
                            value: "item2",
                            text: "Finished fabrics"
                        },
                        {
                            value: "item3",
                            text: "Raw materials"
                        }
                    ],
                    hasOther: true
                },
                {
                    type: "multipletext",
                    name: "Sourcing_Materials_Split_2",
                    title: "What is the split of synthetics to natural fibres used in your products?  ",
                    description: "The materials that make the products you sell will comprise some of your largest sustainability impacts. For this reason we want to know how many of your products are made from synthetic materials (such as polyester or nylon) compared to natural fibres (such as cotton or wool). It is ok to estimate. If you are not sure please add 0 for both.",
                    isRequired: true,
                    items: [
                        {
                            name: "text1",
                            title: "Synthetic fibres (%)"
                        },
                        {
                            name: "text2",
                            title: "Natural fibres (%)"
                        }
                    ]
                },
                {
                    type: "checkbox",
                    name: "Sourcing_Materials_Use_3",
                    title: "What fibres and fabrics do you use most commonly in your products?  (select all that apply)",
                    description: "Please select any materials that your company uses or select other if a significant portion of your products are made from a material not listed, and enter the material in the box below.",
                    isRequired: true,
                    choices: [
                        "Cotton",
                        "Elastane (including Spandex and Lycra)",
                        "Nylon",
                        "Polyester (including synthetic silks and chiffons)",
                        "Rayon (including Viscose, Modal and Lyocell)",
                        "Wools (including cashmere)"
                    ],
                    hasOther: true
                },
                {
                    type: "text",
                    name: "Sourcing_Materials_Sus_4",
                    title: "What percent of your materials come from more sustainable sources?",
                    isRequired: true,
                    inputType: "number",
                    min: 0,
                    max: 100,
                    step: 1
                },
                {
                    type: "comment",
                    name: "Sourcing_Materials_What_5",
                    title: "What are the more sustainable sources that apply?",
                    isRequired: true
                },
                {
                    type: "file",
                    name: "Sourcing_Material_What_5_file",
                    title: "Please upload supporting evidence",
                    allowMultiple: true,
                    waitForUpload: true
                }
            ],
            description: "This section focuses on how you source the raw materials that create the products you sell."
        }
    ],
    showTitle: false,
    showCompletedPage: false
}