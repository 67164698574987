import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Typography } from "@material-ui/core";
import { Survey, Model } from "survey-react";
import "survey-react/survey.css";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from '../styles/themes';
import '../styles/surveyjs.css';
import { removeUnderscores, checkSessionValid, uploadFile } from '../../assets/helpers';
import { Auth, API } from 'aws-amplify';

import { CurrentBrandContext } from '../../assets/contexts/CurrentBrand';
import { SurveyDataContext} from '../../assets/contexts/SurveyData';

import { useUserStore } from '../../assets/state';
import AdminFileList from '../../components/AdminFileList';
import MyToast from '../../components/MyToast';
import AuthState from '../../authStates';
import PropTypes from 'prop-types';
import roles from '../../userTypes';

const TOTAL_NUM_SURVEY = 19

const makeSurveyName = (section, subsection) => `${section.split(' ').join('')}_${subsection.split(' ').join('')}`

const SurveyPage = (props) => {
    const {
        section,
        subsection,
        description,
        jsonFile,
        nextSection,
    } = props;
    const [sectionCompleted, completeSection] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const userType = useUserStore((state) => state.userType);

    const [localSurvey, setLocalSurvey] = useState(Object.create(null));
    const {surveyData, setSurveyData} = useContext(SurveyDataContext);

    const currentBrand = useContext(CurrentBrandContext);
    const userBrand = useUserStore((state) => state.userBrand);
    const setUserBrand = useUserStore((state) => state.setUserBrand);
    const setAuthState = useUserStore((state) => state.setAuthState);
    const [openToast, setOpenToast] = useState(false);
    const [openErrorToast, setOpenErrorToast] = useState(false);
    const surveyName = makeSurveyName(section, subsection);
    const isAdmin = userType === roles.Admin;
    const readOnlyMode = isAdmin || userType === roles.AccountViewer

    useEffect(() => {
        checkSessionValid().then((valid) => {
            if (!valid) {
                setOpenToast(true);
            }
        })
    }, [])

    // fetch local data on url (location hook) change for surveys
    useEffect(() => {
        const getData = async() => {
            const session = await Auth.currentSession();
            const token = session.idToken.jwtToken;
            let brandCode = '';
            if (isAdmin) {
                brandCode = currentBrand.brandCode;
            } else {
                brandCode = session.getIdToken().payload['custom:brandCode'];
            }

            if(!brandCode) {
                console.error('No brand code associated with the current user')
                return;
            }

            const payload = {
                brandCode : brandCode,
                surveyName: makeSurveyName(section, subsection)
            }

            const myInit = {
                headers: {
                    Authorization : token,
                },
                response: true,
                queryStringParameters : payload
            };

            // get data from survey API on component initialization
            try {
                const response = await API.get('surveyapi', '/survey-results', myInit)
                // if data exists
                if (Array.isArray(response.data)) {
                    const brandHasResults = response.data.length !== 0
                    completeSection(brandHasResults);
                    if (brandHasResults) {
                        setLocalSurvey(response.data[0].surveyData);
                    }
                }
            }
            catch (err) {
                console.error('error getting survey data', err);
            }
        }
        if (userType === roles.Brand && currentBrand !== null) {
            getData();
        }
    }, [location.pathname, currentBrand, userType, section, subsection]);

    const survey = new Model(jsonFile);

    // update a brands surveyStatus field after submitting a survey
    const updateBrandStatus = (newStatus, token, brandCode, email) => {
        let payLoad = Object.create(null);
        payLoad.brandCode = brandCode;  // Mandatory
        payLoad.surveyStatus = newStatus;  // Optional
        payLoad.username = email;  // Mandatory

        const myInit = {
            headers: {
                Authorization : token,
            },
            body: payLoad
        };

        API.put('surveyapi', '/brands', myInit)
            .then(data => {
                setUserBrand({...userBrand, surveyStatus: newStatus});    // update users brand info locally
                console.warn('updated brand status successful', data);
            })
            .catch(err => console.error(err))
    }

    // function to post survey data. res = survey object
    async function updateSurvey(survey) {
        try {
            const session = await Auth.currentSession();
            const userBrandCode = session.getIdToken().payload['custom:brandCode'];
            const email = session.getIdToken().payload['email'];
            const token = session.idToken.jwtToken;
            let lres = survey;
            const questionKeys = Object.keys(survey.data);   /* get object keys for each question */

            questionKeys.forEach((key) => {
                // for each key, check wether a '_file' and that file exists
                if (key.includes('_file') && survey.data[key] !== undefined) {
                    // for each file, run uploadFile function
                    survey.data[key].forEach((file, index) => {
                        lres.data[key][index] = uploadFile(file, userBrandCode, `${section.split(' ').join('')}/${subsection.split(' ').join('')}_${removeUnderscores(key)}_${file.name}`);    /* return an object, not [] */
                    })
                }
            })

            // create new payload object
            let payLoad = Object.create(null);
            payLoad.brandCode = userBrandCode;
            payLoad.surveyName = makeSurveyName(section, subsection);
            payLoad.completed = true;
            payLoad.surveyData = Object.assign(Object.create(null),lres.data);
            payLoad.username = email;

            const myInit = {
                method: 'POST',
                headers: {
                    Authorization: token,
                },
                body: payLoad
            };

            if(!userBrandCode) {
                console.error('No brand code associated with the current user')
                return <Box>Configuration incomplete, no brand code associated with user {email}</Box>;
            }

            const payLoadGet = {
                brandCode : userBrandCode,
                surveyName: ''    /* all surveys for user */
            };

            const postRespBody = await API.post('surveyapi', '/survey-results', myInit);
            if (postRespBody.status && postRespBody.status === "Fail") {
                throw (postRespBody.message);
            }

            completeSection(true);

            if (userBrand.surveyStatus === 'None') {
                alert('you just completed first survey');
                updateBrandStatus('In progress', token, userBrandCode, email);
            }

            // update survey data state after each submission

            try {
                const {data} = await API.get('surveyapi', '/survey-results', {
                    headers: {
                        Authorization : token,
                    },
                    response: true,
                    queryStringParameters : payLoadGet
                });
                setSurveyData(data);
                if (data.length === TOTAL_NUM_SURVEY) {
                    updateBrandStatus('Complete', token, userBrandCode, email);
                    history.push('/home');
                } else {
                    history.push(nextSection);
                }
            } catch (err) {
                console.error("error getting survey data", err);
                if (err.code === "NotAuthorizedException") {
                    setOpenToast(true);
                }
            }
        }
        catch(err) {
            console.error("error saving survey data", err);
            if (err.code === "NotAuthorizedException") {
                setOpenToast(true);
            }
            else {
                setOpenErrorToast(true);
            }
        }
    }

    return (
        <Box bgcolor="grey.light" width={1} height={1} display="flex" justifyContent="center"  pt="67px">
            <Box display="flex" flexDirection="column" width="885px" mb="39px" >
                <Typography className={classes.surveySectionTitle}>{ isAdmin && currentBrand !== null ? currentBrand.name : section}</Typography>
                {
                    isAdmin && <Box display='flex' width={0.5} justifyContent='space-between'>
                        <Button
                            color="primary"
                            className={classes.buttonText}
                            style={{justifyContent: 'flex-start'}}
                            onClick={() => {history.push('/surveyresults')}}
                        >{`< Back to all brands`}</Button>
                    </Box>
                }
                {
                    isAdmin && <AdminFileList surveyData={surveyData} currentBrand={currentBrand} surveyName={surveyName} />
                }
                <Box color="primary.main" mt="20px">
                    <Typography className={classes.H400} >{subsection}</Typography>
                </Box>
                <Box color="grey.main">
                    <Typography className={classes.bodyMedium}>{sectionCompleted ? 'You have completed this section - you may review your answers but you can not edit them.' : description}</Typography>
                </Box>
                <Box width={1} mt="30px" className={localSurvey && 'classes.disableLink'}>
                    <Survey
                        model={survey}
                        onComplete={updateSurvey}
                        mode={readOnlyMode ? 'display': ''}
                        data={localSurvey}     /* pre populate with answers */
                    />
                </Box>
            </Box>
            {/* modal pop-over for exporting window */}
            <MyToast
                message="Session timed out"
                isAction={true}
                state={openToast}
                primaryAction={() => setAuthState(AuthState.SignedOut)}
                closeAction={() => setAuthState(AuthState.SignedOut)}
            />
            <MyToast
                message="Error saving survey"
                isAction={false}
                state={openErrorToast}
                primaryAction={() => console.error('error saving survey')}
            />
        </Box>
    )
}

SurveyPage.propTypes = {
    section: PropTypes.string.isRequired,
    subsection: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    jsonFile: PropTypes.string.isRequired,
    nextSection: PropTypes.string.isRequired,
}

export default SurveyPage
