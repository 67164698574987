import React from 'react'
import { Box } from '@material-ui/core';

const Footer = () => {
    return (
        <Box
            height="70px"
            width="100%"
            bgcolor="#FFFFFF"
            color="#1A2259"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            padding="0px 80px"
        >
        </Box>
    )
}

export default Footer