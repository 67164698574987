import React from 'react'
import { Box, Typography, TableContainer, TableHead, TableRow, TableBody, TableCell, Table } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/chemicals.jpeg';

const Chemicals = () => {
    const refs = [
        "The Issues: Chemicals, Common Objective, 2021:  https://www.commonobjective.co/article/the-issues-chemicals",
        "How Much Do Our Wardrobes Cost to the Environment?, World Bank, 2019:  https://www.worldbank.org/en/news/feature/2019/09/23/costo-moda-medio-ambiente",
        "Parvin, F., Islam, S., Akm, S.I., Urmy, Z. and Ahmed, S., 2020. A Study on the Solutions of Environment Pollutions and Workers Health Problems Caused by Textile Manufacturing Operations. Biomedical Journal of Scientific & Technical Research, 28(4), pp.21831-2184",
        "Toxic Threads: The Big Fashion Stitch-Up, Greenpeace, 2012 https://www.greenpeace.org/international/publication/6889/toxic-threads-the-big-fashion-stitchp/",
        "Toxic Fashion: What Chemicals Are Used In Clothing?, Compare Ethics, 2021: https://compareethics.com/chemicals-in-clothing/",
        "Toxic Threads: The Big Fashion Stitch-Up, Greenpeace, 2012: https://www.greenpeace.org/international/publication/6889/toxic-threads-the-big-fashion-stitch-up"
    ];
  
    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title="Chemicals" isMain={true}/>
            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>
                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>
                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>
              Chemicals are used at every stage of producing textiles. When raw materials are grown, pesticides and insecticides may be used to protect crops from infestations. Dyes, bleaches and other chemical treatments are used to finish textiles before they are turned into the garments we wear.
                            <br /><br />
              It is estimated that 8,000 synthetic (human-made) chemicals are used in the fashion industry, and a significant amount of these chemicals end up in wastewater streams<sup>1</sup>.
                        </Typography>
                    </Box>
                </Box>
                <Typography style={{fontSize: "1.1rem"}}>
          Chemical pollution largely occurs during the wet processing stage (dying, bleaching, printing, coating, finishing etc.) because a percentage of the chemical solutions used do not bind to fabric during these processes. As a result, a significant amount fresh water pollution is linked to the dyeing and treatments of textiles during wet processing<sup>2</sup>.
                    <br/><br/>
          At the raw material stage where crops (such as cotton) are grown, the pesticides, insecticides and fertilisers used in conventional farming can negatively impact soil and water quality, local environments, as well as the health of workers and nearby populations due to their toxicity<sup>3</sup>. 
                    <br/><br/>
          Chemicals, pollutants and waste water are not always monitored by the farms, processors and factories that produce textiles as many do not have the expertise and resources. Additionally, international regulations for chemical use are not unified. 
                </Typography>

                <ResourceTitle title="Why is this important?" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
          Many of the chemicals used in the fashion industry are polluting and have negative effects on local ecosystems and communities. Some of these are so-called “forever chemicals”, named because they do not naturally break down in the environment.
                    <br/><br/>
          They can render water undrinkable, destroy marine wildlife, impact soil quality and contribute towards crop resistance. This disproportionately affects the livelihoods of people in poorer countries. 
                    <br/><br/>
          The types of chemicals used can be hazardous to the people who work with them, especially in workplaces where regulation is more lax and where workers do not have access to personal protective equipment. 
                    <br/><br/>
          Chemicals used in clothing can pose a risk to consumers too. In 2012 <a href="https://www.greenpeace.org/international/publication/6889/toxic-threads-the-big-fashion-stitchp/" target="_blank" rel="noopener noreferrer">Greenpeace</a> began their Fashion Detox campaign, found hazardous chemicals in more than 100 garments from 20 major fashion brands. Campaigning by Greenpeace and other organisations over the past decade has contributed to significant efforts by the industry to reduce the use of hazardous chemicals, especially during the wet processing stage of textiles production<sup>4</sup>.
                    <br/><br/>
          If you are interested in learning more about how chemicals used in fashion adversely impact the environment and people, this <a href="https://www.youtube.com/watch?v=kaHmxZhea3M" target="_blank" rel="noopener noreferrer">informative video</a> examines these impacts in India’s textile capital Tiruppur.
                </Typography>
        
                <ResourceTitle title="What does good look like?" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
          There is a lack of agreed international regulatory standards regarding what chemical substances are deemed to be universally ‘safe’ or ‘unsafe’. This is a problem for the fashion industry as its supply chain is globalised, meaning the production stages occur in different countries and regions with different chemical regulations.
                    <br/><br/>
          As a result, the majority of companies who you source from will likely follow different chemical usage and pollution standards, and this can make carrying out supply chain due diligence complex. 
                    <br/><br/>
          Despite this issue, it is important to be aware of a number of key regulations and standards that inform chemical use in the textiles and fashion industry: 
                </Typography>

                <Box p="40px">
                    <Typography style={{margin: "15px 0", fontSize: "1.1rem"}}>
                        <a href="https://echa.europa.eu/regulations/reach/understanding-reach" rel="noopener noreferrer">EU REACH (Registration, Evaluation, Authorisation and Restriction of Chemicals, 2006).</a><br />
            EU REACH requires EU based companies to demonstrate how they are managing the risks associated with the chemicals used in the products they manufacture and sell in the European Union, the European Economic Area (Norway, Iceland and Liechtenstein), and Switzerland who is a member of the European Single Market.
                    </Typography>

                    <Typography style={{margin: "10px 0", fontSize: "1.1rem"}}>
                        <a href="https://www.gov.uk/guidance/how-to-comply-with-reach-chemical-regulations" rel="noopener noreferrer">UK REACH (Registration, Evaluation, Authorisation and Restriction of Chemicals, 2021).</a><br />
            UK REACH is a replica of the EU REACH regulations. As of 1 January 2021, the UK REACH and EU REACH regulations operate independently from each other due to the UK leaving the European Union. UK REACH has aimed to mirror EU REACH as closely as possible to make the transition simple for business, however, as they are two separate regulations they require independent registrations, paperwork and associated costs. 
                    </Typography>

                    <Typography style={{margin: "10px 0", fontSize: "1.1rem"}}>
                        <a href="https://www.roadmaptozero.com/post/the-importance-of-a-single-unified-mrsl" rel="noopener noreferrer">Restricted Substance List (RSL).</a><br />
            An RSL is a list of chemicals that must not be present in a finished product above certain specified levels. This criteria can be informed by legislation such as REACH, environmental certifications, as well as brands and retailers that specify appropriate chemical levels beyond compliance with legal requirements.  
                    </Typography>

                    <Typography style={{margin: "10px 0", fontSize: "1.1rem"}}>
                        <a href="https://www.chem-map.com/chemical_news/manufacturing-restricted-substances-list-mrsl-faqs/" rel="noopener noreferrer">Manufacturing Restricted Substance List (MRSL).</a><br />
            An MRSL is similar to a RSL and includes similar chemical substances, but goes beyond an RSL because it restricts the usage of these substances across the whole production process, not simply in the finished pre-consumer product. This means the MRSL aims to tackle hazardous substances that workers may be exposed to at different stages of the supply chain and those that may potentially pollute the environment.
                    </Typography>
                </Box>

                <ResourceTitle title="Potential next steps" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}><span style={{fontWeight: "600"}}>
          Just getting started?</span> The below table is an overview of the main types of hazardous chemicals used in the fashion industry, which are either banned or subject to restrictions as stipulated by legislation such as REACH.
                <br /><br />
          By understanding what and where these chemicals are used, you can begin taking steps to better understand chemical usage in your production processes.  
                </Typography>
        
                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>8 main priority hazardous chemical groups and examples of use in the textiles and clothing Industry<sup>5 6</sup></Typography>
        
                <TableContainer style={{maxHeight: '100%', margin: "20px 0"}}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow style={{fontWeight: "500"}}>
                                <TableCell align="center">Chemical name</TableCell>
                                <TableCell align="center">Example of use</TableCell>
                                <TableCell align="center">Impact</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow>
                                <TableCell component="th" scope="row">
                AZO dyes with clevable carcinogenic amies 
                                </TableCell>
                                <TableCell align="left">
                Dyeing and colouring textiles 
                                </TableCell>
                                <TableCell align="left">
                Cancerous and banned by the EU in textiles that come into contact with human skin.
                Waste water with dyes can contaminate fresh water and harm marine life
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                PFCs (Perflurocarbon)
                                </TableCell>
                                <TableCell align="left">
                Used in stain resistant and waterproof clothing
                                </TableCell>
                                <TableCell align="left">
                They are a flame retardant, and linked to health risks including infertility and cancer
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                Formaldehyde
                                </TableCell>
                                <TableCell align="left">
                Used to make clothing wrinkle resistant
                                </TableCell>
                                <TableCell align="left">
                Linked to respiratory issues and skin irritation
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                Solvents
                                </TableCell>
                                <TableCell align="left">
                Used to dissolve substances like pigments in dyes
                                </TableCell>
                                <TableCell align="left">
                Overexposure can impact the body’s central nervous system and organ function
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                Surfactants
                                </TableCell>
                                <TableCell align="left">
                Used for scouring, dyeing and finishing
                                </TableCell>
                                <TableCell align="left">
                Can be harmful to marine wildlife
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                Phthalates 
                                </TableCell>
                                <TableCell align="left">
                Plastic softener used in activewear and for printing logos and images onto clothing
                                </TableCell>
                                <TableCell align="left">
                Certain types are found to be cancerous and cause hormone disruptions. Many are defined as ‘high concern’ by REACH guidelines
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                Chromium 
                                </TableCell>
                                <TableCell align="left">
                Salts used in leather and tanning processes
                                </TableCell>
                                <TableCell align="left">
                The run off from chromium is often not disposed of correctly and this can impact food crops as well as cause rashes and respiratory problems for workers
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                Heavy metals: cadmium, lead, mercury and chromium 
                                </TableCell>
                                <TableCell align="left">
                Dyes and colourants; additives in some plastic coatings 
                                </TableCell>
                                <TableCell align="left">
                Some can bioaccumulate in the body over time and are highly toxic, with irreversible effects including damage to the nervous system (lead and mercury) or the kidneys (cadmium); Cadmium is also known to cause cancer
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography style={{fontSize: "1.1rem"}}>
        As a first step, deepen your relationship with your Tier 1 suppliers to discuss issues related to chemical use in their manufacturing processes. Questions you could ask to find out more include:
                </Typography>

                <ol style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Are you familiar with the legal safety requirements for exporting apparel to [country/region name]?</li>
                    <li style={{margin: "10px 0"}}>Do you ensure workers have access to appropriate protective equipment if they are working with hazardous chemicals? If yes, how so?</li>
                    <li style={{margin: "10px 0"}}>What testing do you conduct to ensure the products you manufacture comply with the legal safety requirements of the country you are exporting to?</li>
                    <li style={{margin: "10px 0"}}>Can you provide evidence that proves how you ensure hazardous chemicals are not used in your manufacturing operations?</li>
                    <li style={{margin: "10px 0"}}>Do you take any precautions to reduce chemical pollution in your manufacturing operations?</li>
                    <li style={{margin: "10px 0"}}>Do you have the contact information of whom you source your textiles from? Have you ever asked similar questions of them?</li>
                    <li style={{margin: "10px 0"}}>Do you know what chemicals are used by your textiles wet-processing suppliers?</li>
                </ol>

                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Already done those suggestions?</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>To fully understand your supply chain and how chemicals are used, map your <span style={{fontWeight: "600"}}>Tier 2 and Tier 3 suppliers</span> - the stages where fabrics are produced, dyed and finished.</li>
                </ul>

                <Typography style={{
                    fontSize: "1.2rem",
                    textDecoration: "underline",
                    fontWeight: "600",
                    margin: "20px 0px"
                }}>How to map your supply chain</Typography>

                <TableContainer style={{maxHeight: '100%', margin: "20px 0"}}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow style={{fontWeight: "500"}}>
                                <TableCell align="left">Step 1: Speak to your Tier 1 Suppliers</TableCell>
                                <TableCell align="left">Step 2: Beyond your Tier 1 Suppliers</TableCell>
                                <TableCell align="left">Step 3: Mapping</TableCell>
                                <TableCell align="left">Step 4: Layering data to identify risks and build resilience</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                Invite your Tier 1 (direct suppliers) to participate in the mapping process. 
                                    <br/>
                Send out a supplier assessment covering essential criteria. This could include: 
                                    <br/>
                                    <ol>
                                        <li>Who are you?</li>
                                        <li>Where are you?</li>
                                        <li>What do you sell?</li>
                                        <li>Who are your own suppliers?</li>
                                    </ol>          
                                </TableCell>
                                <TableCell align="left">
                Once a good line of communication is established with your Tier 1 suppliers, look beyond to your Tier 2 and Tier 3 suppliers. 
                                    <br />
                Ask your tier 1 supplier to send the same basic assessment to their tier 2 suppliers. In turn, ask them what information they can provide on their own suppliers. This will help you start to see how different facilities are connected.
                                </TableCell>
                                <TableCell align="left">
              As you learn new information in the discovery phase, fill out a map. 
                                    <br />
              There are numerous ways this could be built out, such as inputting information into a database, using Excel to manually create linkages between suppliers, or using something more visual like a Miro board. There are also companies that provide specialised supply chain mapping software.
                                </TableCell>
                                <TableCell align="left">
                Now you have a map you can update and amend when necessary. 
                                    <br />
                You can better identify risks - e.g. a factory that has been fined for environmental non-compliance, such as polluting a local river.
                                    <br />
                Look for third-party information on potential risks too.
                                    <br />
                Adding more data and learning about your risks means you can build resilience against future shocks. 
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Explore clothes production coloured with natural rather than synthetic dyes. Natural dyes are derived from plants, seeds, fruits, barks and lichens, and are much less polluting than synthetic dyes. Note that some natural dyes are only suitable for small-scale production and may have different associated costs than conventional dyes.</li>
                    <li style={{margin: "10px 0"}}>
          Seek out materials, dyes, fabrics and chemicals that comply with third-party certification standards such as:
                        <ol style={{margin: "10px 0"}}>
                            <li style={{margin: "10px 0"}}>
                                <a href="https://www.oeko-tex.com/en/our-standards/standard-100-by-oeko-tex" target="_blank" rel="noopener noreferrer">Oeko-Tex</a><br />Oeko-Tex is a certification for conventional, organic and synthetic fibres. It is a four-tier system that assesses the toxicity of chemicals present in clothing.
                                <ul>
                                    <li>Class I is intended for babies and is the most restrictive and has the lowest allowable limits of toxins.</li>
                                    <li>Class 2 is for items with direct contact with the skin.</li>
                                    <li>Class 3 is for items with no direct contact for skin like coats.</li>
                                    <li>Class 4 for decorative items such as buttons or pins and is the least restrictive.</li>
                                </ul>
                            </li>
                            <li style={{margin: "10px 0"}}>
                                <a href="https://global-standard.org/" target="_blank" rel="noopener noreferrer">Global Organic Textile Standard (GOTS)</a><br />The standard is awarded to textiles with a minimum 95 percent of organic fibres. It is considered the gold standard in organic certifications because it applies to the whole production cycle of an item of clothing, and it requires that the use of dyes, auxiliaries and process chemicals must be evaluated for toxicity and biodegradability. Additionally, toxic heavy metals, formaldehyde, aromatic solvents, functional nano-particles and genetically modified organisms (GMOs) are prohibited.
                            </li>
                            <li style={{margin: "10px 0"}}>
                                <a href="https://www.bluesign.com/en" target="_blank" rel="noopener noreferrer">Bluesign</a><br />A Bluesign certification is a broad certification that encompasses people, environment and resources. It requires that manufacturers consider the impact of chemical usage on the environment and its level of toxicity; however, there is not a threshold for chemical usage unlike the above certifications.
                            </li>
                        </ol>
                    </li>
                    <li style={{margin: "10px 0"}}>
          Complete the <a href="https://www.chem-map.com/chem-map-brands-retailers/" target="_blank" rel="noopener noreferrer">Chem-Map Programme</a> which supports brands to implement responsible chemical management in their supply chains for leather, textiles and synthetic products. It is a <span style={{fontWeight: "600"}}>Zero Discharge of Hazardous Chemicals (ZDHC)</span> approved verification system that analyses and authenticates supplied chemical formulations for textiles, leather and synthetic materials in line with the <span style={{fontWeight: "600"}}>ZHDC Manufacturing Restricted Substances List (MRSL)</span>.
                        <br /><br />
          Benefits of completing the Chem-Map Programme include:
                        <ul style={{margin: "10px 0"}}>
                            <li>An independent technical review of current chemical management systems.</li>
                            <li>Help with developing a responsible chemical management programme and implementing this in the materials supply chain.</li>
                            <li>Benchmarking and gap analysis against industry leaders.</li>
                            <li>An action plan for improvement.</li>
                        </ul>          
                    </li>
                    <li style={{margin: "10px 0"}}>
        Participate in the <a href="https://www.roadmaptozero.com/about" target="_blank" rel="noopener noreferrer">Zero Discharge of Hazardous Chemicals (ZDHC) Roadmap to Zero Programme</a> for the textiles and footwear industry – a global multi-stakeholder initiative of more than 160 contributors within the fashion industry, aiming to protect the environment by reducing the industry chemical footprint. The roadmap to zero programme expects brands who sign up to implement the various ZHDC guidelines and solutions such as the MRSL throughout their supply chain. Brands who participate will receive an annual evaluation report on their implementation efforts and details on areas where they need to improve. 
                        <br /><br />
        The RoadMap to Zero Programme:
                        <ul style={{margin: "10px 0"}}>
                            <li>Reduces audit duplication and supply chain complexity via a single common implementation approach.</li>
                            <li>Strengthens and enhances supplier engagement.</li>
                            <li>Supports brands to communicate to their community their efforts to phase out hazardous chemicals in the supply chains; e.g, assessment results can be used as part of the brand’s external sustainability report.</li>
                        </ul>
                    </li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}><span style={{fontWeight: "600"}}>
        Just getting started?</span>
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>If up until now you haven’t shared any information publicly about your production processes, think about what information you have, what information you would like to disclose, and how.</li>
                    <li style={{margin: "10px 0"}}>If you are in the early stages of your sustainability journey, this may look like publishing basic sourcing details such as where you produce and the year a supplier began producing for you. Recent research has found that consumers are willing to pay more for products from companies that provide greater supply chain transparency<sup>6</sup>. Talking about where the items you sell come from creates greater trust between your brand and your consumers.</li>
                </ul>

                <Typography style={{
                    fontSize: "1.1rem", 
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Already done those suggestions?</Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Join the <a href="https://transparencypledge.org/" target="_blank" rel="noopener noreferrer">
          Apparel and Footwear Transparency Pledge</a>. This pledge was developed by multiple human rights and labour rights organisations. It calls on fashion brands committed to transparency to publish their supplier details on their websites and any other communication platforms. The pledge includes:
                    <ol style={{margin: "10px 0"}}>
                        <li>The full name of all authorised production units and processing facilities</li>
                        <li>The site addresses</li>
                        <li>The parent company of the business site</li>
                        <li>Types of products made</li>
                        <li>Worker numbers at each site</li>
                    </ol>
                    </li>
                    <li style={{margin: "10px 0"}}>A step up from publishing your supplier details on your website is to join the <a href="https://info.openapparel.org/" target="_blank" rel="noopener noreferrer">Open Apparel Registry Website (OAR)</a>. The OAR is a free open source, interactive map of garment facilities across the world. It shows which organisations and companies are affiliated with each facility. This is a gamechanger in increasing transparency in the fashion industry. As of summer 2022 there are more than 97,000 production facilities listed worldwide.</li>
                    <li style={{margin: "10px 0"}}>Once you start collating supplier information, a next step could be to publish more in-depth information on your supply chains. This could include information on the percentage of migrant workers in a factory’s workforce, the presence of unions, or the types of energy sources a factory uses<sup>7</sup>.</li>
                    <li style={{margin: "10px 0"}}>The ultimate end goal for supply chain visibility is to trace and disclose information on all stages of production - where raw materials come from, how yarns are spun, fabrics are dyed and fabrics produced. This of course is not an easy task and involves a long-term sustainability dialogue that prioritises both traceability and transparency to achieve.</li>
                </ul>

            </Box>
            <Box>
                <ResourcesReferences references={refs} />
            </Box>
        </Box>
    )
}

export default Chemicals