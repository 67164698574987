import React from 'react'
import { Box, Typography } from '@material-ui/core';
import ResourceTitle from '../../../../components/ResourcesTitle';
import ResourcesReferences from '../../../../components/ResourcesReferences/ResourcesReferences';
import mainImg from '../../../../assets/resourcesImages/waste-circularity.jpeg';

const WasteCircularity = () => {
    const refs = [
        " Fashion and the Circular Economy, Ellen MacArthur Foundation, no date: https://archive.ellenmacarthurfoundation.org/explore/fashion-and-the-circular-economy",
        "House of Commons Environmental Audit Committee: Fixing Fashion Clothing Consumption and Sustainability, 2019: https://publications.parliament.uk/pa/cm201719/cmselect/cmenvaud/1952/1952.pdf",
        " Redesigning the Future of Fashion, Ellen MacArthur Foundation, no date:  https://ellenmacarthurfoundation.org/topics/fashion/overview",
        " Recycling and the Circular Economy What’s the Difference, Ellen MacArthur Foundation, no date: https://ellenmacarthurfoundation.org/articles/recycling-and-the-circular-economy-whats-the-difference",
        "What is Circular Fashion, Common Objective, 2019: https://www.commonobjective.co/article/what-is-circular-fashion",
        " A More Circular Fashion Industry Will Require A Collaborative Effort, Business of Fashion, 2021: https://www.businessoffashion.com/opinions/sustainability/a-more-circular-fashion-industry-will-require-a-collective-effort",
        "Circular Fashion Partnership, Global Fashion Agenda, no date: https://globalfashionagenda.org/circular-fashion-partnership/",
        "Sustainable Fashion and Textiles, WRAP, no date: https://wrap.org.uk/taking-action/textiles",
        "Why Are Clothes So Hard To Recycle, BBC, 2020: https://www.bbc.com/future/article/20200710-why-clothes-are-so-hard-to-recycle",
        "Fashion and the Circular Economy, Ellen MacArthur Foundation, no date: https://archive.ellenmacarthurfoundation.org/explore/fashion-and-the-circular-economy"
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" style={{
            color: "#6d6d6d",
            fontSize: "1.1rem",
            overflowY: "scroll"
        }}>
            <ResourceTitle title="Waste & circularity" isMain={true}/>
            <Box display="flex" p="20px 40px" flexDirection="column" bgcolor="#FFFFFF">
                <ResourceTitle title="What's the issue?" isMain={false}/>
                <Box style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    margin: "20px 0"
                }}>

                    <img src={mainImg} alt="textiles manufacture" style={{
                        borderRadius: "20px 20px 0px 20px"
                    }}/>

                    <Box p="0 0 0 20px">
                        <Typography style={{fontSize: "1.1rem"}}>
            Over the last 15 years clothing production has doubled -  a rubbish truck load of clothes is burnt or buried in landfill every second<sup>1</sup>.
                            <br /><br />
            Our current system for producing, distributing and using clothing is linear. This means large amounts of non-renewable resources are extracted to produce clothing, which are used for a short period of time and then thrown away, ending up in landfill or incineration. This approach to clothing production and consumption is defined as a ‘take-make-waste’ model and contributes significantly to environmental challenges including global heating, waste and pollution.
                        </Typography>
                    </Box>
                </Box>

                <ResourceTitle title={`Why is it important?`} isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>Waste produced by the fashion industry from manufacturing and selling clothes falls into two categories:</Typography>

                <ol style={{margin: "10px 0"}}>
                    <li style={{margin: "20px 0"}}>
                        <span style={{fontWeight: '600'}}>Pre-consumer waste</span><br />
            This means waste generated whilst clothing is produced and includes:
                        <ul>
                            <li>Waste from fibre production - such as the waste created from the processing of yarns</li>
                            <li>Waste from pattern cutting created when patterns are cut from rolls of fabric. This accounts for as much as 15% of all waste in the fashion industry<sup>2</sup>.</li>
                            <li>End-of-roll waste - the surplus of leftover fabrics once patterns are cut.</li>
                            <li>Cut-and-sew waste - remnants of fabric such as scraps that are discarded during production.</li>
                        </ul>
                    </li>

                    <li style={{margin: "20px 0"}}>
                        <span style={{fontWeight: '600'}}>Post-consumer waste</span><br />
          This means waste generated from clothes after they are produced and what happens to finished products and includes:
                        <ul>
                            <li>Waste from customer returns - these are products returned by customers that cannot be resold.</li>
                            <li>Overstock waste - this is excess inventory which is unused or unsold.</li>
                            <li>End-of-life waste - produced when consumers throw products away once they are no longer used or needed.</li>
                        </ul>
                    </li>
                </ol>

                <Typography style={{fontSize: "1.1rem"}}>The take-make-waste approach to fashion production and consumption has meant historically that the majority of industry waste has ended up in landfill or has been incinerated<sup>3</sup>.
                    <br /><br />
        Incinerating fashion waste can release harmful chemicals and toxins into the atmosphere as well as contributing to the release of greenhouse gas emissions. Clothing waste that is landfilled will likely take years to decompose and contribute to local water and atmospheric pollution. Synthetic fabric waste can cause microplastic fibres to enter waterways, posing health risks to both aquatic animals and birds, with increasing evidence of human consumption.
                </Typography>

                <ResourceTitle title="What does good look like?" isMain={false} />

                <Typography style={{fontSize: "1.1rem"}}>
          A circular fashion industry supports environmental regeneration whilst creating better products for consumers.
                    <br /><br />
          Circularity is more than simply recycling as the latter only addresses the waste stage of a product’s life and already exists in linear systems. Circularity is distinct because it attempts to go right back to the beginning of a product’s conception: to prevent waste and pollution from being created in the first place. Aiming to make more durable products that will circulate for multiple life cycles is called closed loop<sup>4</sup>.
                    <br /><br />
          It is worth stating that whilst circularity is now a common term within fashion, a truly circular industry is still some way off.
                    <br /><br />
          Waste reduction, reuse and circularity are evolving areas. Numerous brands, multi-stakeholder organisations and initiatives are working on different solutions globally. Presently even the most responsible companies are not fully circular. It is a work in progress, that through enhanced understanding, innovation and collective action we can transition towards. Government is also considering regulating this area.
                    <br /><br />
          The UK Government is considering legislation that would mean clothing companies are financially and/or physically responsible for the products they sell. The legislation is called Extended Producer Responsibility (EPR).
                    <br /><br />
          In the UK, EPR schemes already exist for electrical goods, batteries, vehicles, and packaging. The aim of the EPR scheme is to generate a fund to support the infrastructure needed for recycling textiles. The UK government is expected to consult on these plans in 2022 so keep an eye out for how this develops over the next few years. The European Union also has plans to introduce a similar scheme.
                </Typography>

                <ResourceTitle title="Potential next steps?" refs="5 6 7" isMain={false} />

                <Typography style={{
                    fontSize: "1.2rem",
                    textDecoration: "underline",
                    fontWeight: "600",
                    margin: "20px 0px"
                }}>Just getting started?</Typography>

                <Typography style={{fontSize: "1.1rem"}}><span style={{fontWeight: "600"}}>
          The design stage</span>
                </Typography>

                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>
            The vast majority of a product’s environmental impacts are determined at the design stage. Consider at the design stage:
                        <ul>
                            <li>How long will a product be used for?</li>
                            <li>How will the product be disposed of?</li>
                            <li>How easy will it be to disassemble?</li>
                        </ul>
                    </li>

                    <li style={{margin: "10px 0"}}>
            Suggestions for how you might incorporate circular thinking into your product design include:
                        <ul>
                            <li>Using single fibre materials rather than blends of materials as these are easier to recycle at the end of a product’s life.</li>
                            <li>Ensuring hardware and trims are easily removable and can be recovered for reuse<sup>8</sup>.</li>
                            <li>Encouraging product repairability - for example, by including spare buttons.</li>
                            <li>Using metal zippers over plastic ones as they generally last longer.</li>
                            <li>Using materials that are less likely to pile or shrink in the wash to extend product use.</li>
                        </ul>
                    </li>
                    <li style={{margin: "10px 0"}}>There are numerous organisations working in this area. For more in-depth explanations and resources check-out the <a href="https://ellenmacarthurfoundation.org/topics/fashion/overview" target="_blank" rel="noopener noreferrer">Ellen MacArthur Foundation</a>,  <a href="https://www.circulardesignguide.com/" target="_blank" rel="noopener noreferrer">IDEO</a> and  <a href="https://wrap.org.uk/resources/report/design-extending-clothing-life" target="_blank" rel="noopener noreferrer">WRAP</a>.</li>
                    <li style={{margin: "10px 0"}}>Engage in dialogue with your suppliers to ask about what happens to production waste. For example, can you design and produce using zero or reduced waste patterns?</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Using production leftovers</span>
                </Typography>
                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>At the production stage, over 25% of leftover scrap fabric is larger than half a metre. These scraps can be resold or repurposed without recycling. Remanufacturing is cheaper than recycling and uses far less energy. This can be done invisibly (using production leftovers in the pockets, cuffs or fly facings), visibly (small details on the outside of the garment), integrated into the design, or even used to make smaller items. Think of the pivots to producing facemasks during the pandemic.</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Product recyclability</span>
                </Typography>
                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>The majority of collection, reprocessing and recycling facilities fall short of what would be needed to keep all existing clothing in circulation, whether that is through resale, upcycling, recycling or downcycling. This is because the majority of clothing is made from a combination of fibres, fixtures and accessories that are extremely complex and labour intensive to dissemble, break down and recycle<sup>9</sup>.
                        <br /><br />
          As an industry, numerous initiatives and technology providers are building out the business case for textile-to-textile recycling with the aim of decreasing textile waste and increasing use of recycled fibres. Scalability of these solutions is expected to rapidly increase in the coming years.</li>
                </ul>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Communicating circularity to your consumers</span>
                </Typography>
                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Include product end-of-life in your brand communications. For example, you could signpost customers to their nearest clothing and textiles recycling centre in a product description. <a href="https://www.recyclenow.com/what-to-do-with/clothing-textiles-0" target="_blank" rel="noopener noreferrer">RecycleNow</a> provides a simple search directory that lists clothing and recycling centres in proximity to UK postcodes.</li>
                    <li style={{margin: "10px 0"}}>Communicate clothing durability to your consumers - for example how many times an item is intended to be worn. You could even communicate this information as part of a product description. Clothing which is intended to be durable rather than quickly disposed of will provide added functionality to your customers whilst increasing perception of an item’s value.</li>
                    <li style={{margin: "10px 0"}}>Provide consumers with tailored information about how to care for items to ensure product longevity, such as washing at lower temperatures.</li>
                </ul>

                <Typography style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    margin: "20px 0"
                }}>Already done those?</Typography>

                <Typography style={{fontSize: "1.1rem"}}>
                    <span style={{fontWeight: "600"}}>Exploring circular business models</span>
                </Typography>
                <ul style={{margin: "10px 0"}}>
                    <li style={{margin: "10px 0"}}>Many brands are now starting to think about integrating alternative customer services and design processes into their business model, such as repairs, resale, sharing, rental models and reworking existing products to add value through upcycling<sup>10</sup>.</li>
                    <li style={{margin: "10px 0"}}>Look into developing support services for your customers to maintain their clothing for longer, which will help keep clothing purchases at their highest perceived and actual value. You could provide repair services and/or form partnerships with repair and restyle providers based in local communities or online.</li>
                    <li style={{margin: "10px 0"}}>Think about establishing a customer clothing return business model. This could look like  allowing customers to return used garments for credits or other types of incentives, which once returned can be reused through repair, rework and recycling.</li>
                    <li style={{margin: "10px 0"}}>Make resales and rental options available to customers. This works in combination with increasing the quality and durability of products, which ensures that garments will be maintained to a high enough standard that they can be either re-sold or rented several times over.</li>
                </ul>
            </Box>
            <Box>
                <ResourcesReferences references={refs} />
            </Box>
        </Box>
    )
}

export default WasteCircularity