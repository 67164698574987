import React, { useContext, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom';
import Survey from '../../Pages/Brands/Survey';
import { Home } from '../../Pages/Brands/Home';
import AdminHome from '../../Pages/Admin/AdminHome';
import ResetPassword from '../../Pages/ResetPassword';
import ResourcesPage from '../../Pages/Brands/ResourcesPage';
import PrivateRoute from '../PrivateRoute';
import SFHome from '../../Pages/SF/Home';
import SurveyPages from '../../Pages/Admin/SurveyPages';
import { useUserStore } from '../../assets/state';
import { Auth, API } from 'aws-amplify';

import { SurveyCompletedProvider } from '../../assets/contexts/SurveyCompleted';
import { SurveyDataContext, SurveyDataProvider } from '../../assets/contexts/SurveyData';
import { CurrentBrandProvider } from '../../assets/contexts/CurrentBrand';

import roles from '../../userTypes';

const AppWithAuth = () => {
    const userType = useUserStore((state) => state.userType);
    const { setSurveyData } = useContext(SurveyDataContext);

    useEffect(() => {
        async function getData() {
            const session = await Auth.currentSession();
            // user -> receive brand specific data

            const brandCode = session.getIdToken().payload['custom:brandCode'] || '';

            const token = session.idToken.jwtToken

            const payload = {
                brandCode : brandCode,
                surveyName: ''    /* all surveys for user */
            }

            const myInit = {
                headers: {
                    Authorization : token,
                },
                response: true,
                queryStringParameters : payload
            };

            if(!brandCode) {
                console.error('No brand code associated with the current user 42')
                return;
            }

            // get data from survey API on component initialisation
            try {
                const {data} = await API.get('surveyapi', '/survey-results', myInit);
                setSurveyData(data);
            } catch(err) {
                console.error('Could not get data', err)
            }
        }

        if (userType === roles.Brand) {
            getData();
        }
    }, [userType]);

    // path should always be '/' when this component is rendered
    return (
        <Switch>
            <SurveyCompletedProvider>
                <SurveyDataProvider>
                    <CurrentBrandProvider>
                        {(() => {
                            switch (userType) {
                            case roles.Admin:
                                return <PrivateRoute path='/' component={AdminHome} />
                            case roles.AccountViewer:
                                return <>
                                    <Route path="/home" component={SFHome} />
                                    <Route path="/survey" component={SurveyPages} />
                                </>
                            case roles.Brand:
                            default:
                                return <>
                                    <Route path="/survey" component={Survey} />
                                    <Route path="/home" component={Home} />
                                    <Route path="/resources" component={ResourcesPage} />
                                    <Route path="/resetpassword" component={ResetPassword} />
                                </>
                            }
                        })()}
                    </CurrentBrandProvider>
                </SurveyDataProvider>
            </SurveyCompletedProvider>
        </Switch>
    )
}

export default AppWithAuth